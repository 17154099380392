import React, { FC } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export type CheckoutCardProps = {
  className: string,
  testId: string,
  title: string,
  titleSpacing?: number,
  justifyContent?: string,
};

export const CheckoutCard: FC<CheckoutCardProps> = ({
  children,
  className,
  testId,
  title,
  titleSpacing = 5,
  justifyContent = 'normal',
 }) => {

  const display: string = justifyContent === 'normal' ? 'block' : 'flex';

  return (
    <Card data-testid={testId} className={className} sx={{ pb: 5 }}>
      <CardContent sx={{ p: 5, display, justifyContent }}>
        <div className="c-card-content">
          <Typography variant="h4" mb={titleSpacing}>{title}</Typography>
          {children}
        </div>
      </CardContent>
    </Card>
  );
};
