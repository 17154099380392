import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

import { QuoteData } from 'pages/Quote/QuoteData';
import { formatDollars } from 'shared/numbers';


export type SummaryProps = {
  quote: QuoteData,
};

export const Summary: FC<SummaryProps> = (props) => {
  const { quote } = props;

  return (
    <>
      <hr style={{ margin: '8px 0 16px' }}/>
      <Typography variant="h1" data-testid="poCheckout-summary-price">{formatDollars(quote.price/100)}</Typography>
    </>
  );
};
