import { QuoteData } from 'pages/Quote/QuoteData';

/*
The first function, dummy() states the base dummy data.
In subsequent functions like noDiscounts(base: QuoteData), and for the purpose of testing,
we will pass in the dummy(), and alter the data as needed.
*/

// Add flexible variables for created and expires dates so tests won't break
const createdDate = new Date(Date.now());
const expiresDate = new Date(Date.now() + 3542400000);
const invoicedDate = new Date(Date.now() + 1296000000);

export const quoteDataFixture = {
  dummy: (): QuoteData => {
    return {
      quote_num: 50000,
      public_id: '12345',
      price: 599900,
      quote_type: 'district',
      status: 'open',
      created_at: createdDate,
      expired_at: expiresDate,
      invoiced_at: null,
      district: {
        pid: 12345,
        institution_name: 'Whitesboro Central School District',
        address: '65 Oriskany Blvd. Suite 1',
        city: 'Whitesboro',
        state: 'NY',
        zipcode: '13492',
        zip4: '1234',
        country: 'United States',
        enrollment: 1500,
        institution_type: 'District',
      },
      quote_summary_items: [
        {
          prorated: true,
          amount: 1625,
          start: new Date('2022-01-01T03:59:59.000Z'),
          end: new Date('2022-07-01T03:59:59.000Z'),
        },
        {
          prorated: false,
          amount: 3250,
          start: new Date('2022-09-01T03:59:59.000Z'),
          end: new Date('2023-07-01T03:59:59.000Z'),
        },
      ],
      discounts: [
        {
          name: 'Early Bird Discount',
          amount: 1500,
          description: 'Spring Promotional Discount.',
          expiration: 1651363200000,
        },
        {
          name: 'Example Second Discount',
          amount: 400,
          description: 'Example discount.',
          expiration: 1651363200000,
        },
      ],
      schools: [
        {
          pid: 12346,
          institution_name: 'Marcy Elementary School',
          address: '9479 Maynard Dr.',
          city: 'Marcy',
          state: 'NY',
          zipcode: '13403',
          zip4: '1234',
          country: 'United States',
          enrollment: 500,
          institution_type: 'School',
        },
        {
          pid: 12347,
          institution_name: 'Parkway Middle School',
          address: '75 Oriskany Blvd.',
          city: 'Whitesboro',
          state: 'NY',
          zipcode: '13492',
          zip4: '1234',
          country: 'United States',
          enrollment: 500,
          institution_type: 'School',
        },
        {
          pid: 12348,
          institution_name: 'Whitesboro Middle School',
          address: '75 Oriskany Blvd.',
          city: 'Whitesboro',
          state: 'NY',
          zipcode: '13492',
          zip4: '1234',
          country: 'United States',
          enrollment: 500,
          institution_type: 'School',
        },
        {
          pid: 12349,
          institution_name: 'Whitesboro High School',
          address: '6000 Route 291',
          city: 'Marcy',
          state: 'NY',
          zipcode: '13403',
          zip4: '1234',
          country: 'United States',
          enrollment: 500,
          institution_type: 'School',
        },
      ],
      license_start: new Date('2021-09-01T04:00:00.000Z'),
      license_end: new Date('2025-07-01T03:59:59.000Z'),
      students_num: 1500,
      num_extension: 0,
      max_extension: 3,
    };
  },

  // Mimics a quote with no discounts applied. Used for testing
  noDiscounts: (base: QuoteData): QuoteData => {

    return {
      ...base,
      discounts: [],
    };
  },

  // Mimics a quote with no district present. Used for testing
  noDistrict: (base: QuoteData): QuoteData => {

    return {
      ...base,
      district: null,
    };
  },

  // Mimics an invoiced quote. Used for testing
  invoiced: (base: QuoteData): QuoteData => {

    return {
      ...base,
      invoiced_at: invoicedDate,
      status: 'invoiced',
    };
  },

  // Mimics a canceled quote. Used for testing
  canceled: (base: QuoteData): QuoteData => {

    return {
      ...base,
      status: 'canceled',
    };
  },

  // Mimics an expired quote. Used for testing
  expired: (base: QuoteData): QuoteData => {

    return {
      ...base,
      quote_num: 509876,
      public_id: '09876',
      status: 'expired',
    };
  },

  // Mimics an expired quote. Used for testing
  fakeRenewal: (base: QuoteData): QuoteData => {

    return {
      ...base,
      quote_num: 503456,
      public_id: 'fake-renewal',
    };
  },

  // Mimics a quote past expiration date. Used for testing
  expiredDate: (base: QuoteData): QuoteData => {

    return {
      ...base,
      created_at: new Date(1616371140000),
      expired_at: new Date (1619913540000),
    };
  },

  // Mimics a quote with one current extension. Used for testing
  oneExtension: (base: QuoteData): QuoteData => {

    return {
      ...base,
      num_extension: 1,
    };
  },

  // Mimics a quote with two current extensions. Used for testing
  twoExtensions: (base: QuoteData): QuoteData => {

    return {
      ...base,
      num_extension: 2,
    };
  },

  // Mimics a quote with three (max) current extensions. Used for testing
  maxExtensions: (base: QuoteData): QuoteData => {

    return {
      ...base,
      num_extension: 3,
    };
  },

  // Mimics a single school quote. Used for testing
  schoolQuote: (base: QuoteData): QuoteData => {

    return {
      ...base,
      quote_num: 50001,
      public_id: '54321',
      quote_type: 'school',
      schools: [
        {
          pid: 12346,
          institution_name: 'Marcy Elementary School',
          address: '9479 Maynard Dr.',
          city: 'Marcy',
          state: 'NY',
          zipcode: '13403',
          zip4: '1234',
          country: 'United States',
          enrollment: 500,
          institution_type: 'School',
        },
      ],
      quote_summary_items: [
        {
          prorated: true,
          amount: 299900,
          start: new Date('2022-01-01 05:00:00'),
          end: new Date('2022-06-30 04:59:59'),
        },
        {
          prorated: false,
          amount: 300000,
          start: new Date('2022-06-30 05:00:00'),
          end: new Date('2023-06-30 04:59:59'),
        },
      ],
      discounts: [],
      license_start: new Date('2022-01-01 05:00:00'),
      license_end: new Date('2023-06-30 04:59:59'),
    };
  },
};
