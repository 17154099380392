import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import { EmailChips } from 'components/EmailChips/EmailChips';
import { validators } from 'pages/Wizard/Steps/Validators';


export interface InputWithEmailChipsProps {
  fieldError?: boolean,
  isChipFieldRequired?: boolean;
  label: string;
  placeholder?: string;
  setFieldError?: Dispatch<SetStateAction<boolean>>;
  testId: string;
  testIdLocation: string;
  updateList: (value: string[]) => void;
}

export const InputWithEmailChips: FC<InputWithEmailChipsProps> = ({
  fieldError,
  isChipFieldRequired,
  label,
  placeholder,
  setFieldError,
  testId,
  testIdLocation,
  updateList,
}) => {

  //Input Field State
  const [inputEmail, setInputEmail] = useState<string>('');

  //Email List State
  const [emails, setEmails] = useState<string[]>([]);

  //Validation State
  const [isDuplicateEmail, setIsDuplicateEmail] = useState<boolean>(false);
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  /*Conditional State Updates*/

  //When the field is required, and an error occurs, update the parent form state
  const setErrorStateIfRequired = (errorText: string) => {
      if (setFieldError) {
        setFieldError(true);
        setErrorText(errorText);
      }
  };

  const setErrorState = (errorText: string) => {
    setErrorText(errorText);
    setFieldError?.(true);
  };

  //As soon as a user types, clear any present Validation errors.
  const resetField = () => {
    setEmailInvalid(false);
    setIsDuplicateEmail(false);
    setFieldError?.(false);
    setErrorText('');
  };

  /*Event Handlers*/

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetField();

    const { value } = event.target;
    if (value !== ' ' && value !== ',') {
      setInputEmail(value);
      onSpaceOrComma(value);
    }
  };

  // OnKeyDown
  const handleEnter = (event: React.KeyboardEvent) => {
    const fieldHasValue = validators.isValueSet(inputEmail);

    if (event.key === 'Enter') {
      if(fieldHasValue) {
        processInput(inputEmail);
      }
    }
  };

  //OnBlur - run validation if field required
  const handleInputBlur = (event: React.FocusEvent) => {
    const errorText = 'Enter at least one email of a school official that you would like to share this quote with.';
    const fieldHasValue = validators.isValueSet(inputEmail);

    if (!fieldHasValue && isChipFieldRequired !== undefined) {
      if (emails.length === 0) {
          setErrorStateIfRequired(errorText);
      }
    } else if (fieldHasValue) {
      processInput(inputEmail);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    resetField();
    event.preventDefault();
    const pastedString = event.clipboardData.getData('Text');
    const spaceOrComma = new RegExp(/[, ]+/);
    const splitStringArray = pastedString.split(spaceOrComma);

    const emailsValid = validatePastedEmailList(splitStringArray);

    if (emailsValid) {
      const updatedSelectedEmails = [...emails];
      splitStringArray.forEach((email) => {
        const lowerCasedEmail = email.toLowerCase();
        updatedSelectedEmails.push(lowerCasedEmail);
      });
      processEmailList(updatedSelectedEmails);
    }
  };


  /* Helper Methods */

  //Called by input handler - This function listens for when a comma or space
  //is pressed at the end of an email. It removes either extra character.
  const onSpaceOrComma = (value: string) => {
      if (value.slice(-1) === ' ' || value.slice(-1) === ',') {
        const emailWithoutSpaceOrComma = value.slice(0, -1);
        processInput(emailWithoutSpaceOrComma);
      }
  };

  const processInput = (input: string) => {
    const updatedSelectedEmails = [...emails];
    const lowerCasedEmail = input.toLowerCase();

    if (isEmailValid(lowerCasedEmail) && isEmailDuplicate(lowerCasedEmail)){
      updatedSelectedEmails.push(lowerCasedEmail);
      processEmailList(updatedSelectedEmails);
      setInputEmail('');
    }
  };

  const isEmailValid = (email: string, isPaste?: boolean) => {
    const errorText = isPaste ? `${email} is not a valid email, adjust the list you copied and please try again.` :'Please enter a valid email address, or clear this field.';
    //returns true if Valid
    const isValid = validators.isEmailValid(email);

    if (isValid) {
      setEmailInvalid(false);
      return true;
    } else if (isChipFieldRequired) {
      setErrorStateIfRequired(errorText);
      return false;
    } else {
      setErrorState(errorText);
      setEmailInvalid(true);
      return false;
    }
  };

  const isEmailDuplicate = (email: string, isPaste?: boolean) => {
    if (emails.includes(email)) {
      const errorText = isPaste ? `${email} has already been entered, adjust the list you copied and please try again.`: 'That email has already been entered!';
      setErrorState(errorText);
      setIsDuplicateEmail(true);
      return false;
    }
    return true;
  };

  const removeEmail = (email: string) => {
    const updatedSelectedEmails = [...emails];
    const emailIndex = updatedSelectedEmails.findIndex((x) => x === email);

    updatedSelectedEmails.splice(emailIndex, 1);
    processEmailList(updatedSelectedEmails);
  };

  const processEmailList = (value: string[]) => {
    setEmails(value);
    updateList(value);
  };

  const validatePastedEmailList = (emailList: string[]): boolean => {
    return emailList.every((email) => {
      const lowerCasedEmail = email.toLowerCase();
      return isEmailValid(lowerCasedEmail, true) && isEmailDuplicate(lowerCasedEmail, true);
    });
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        style={{
          alignSelf: 'flex-start',
          fontWeight: 'normal',
          marginTop: '24px',
        }}
        data-testid='inputWithChips-typography-label'
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={inputEmail}
        error={isDuplicateEmail || emailInvalid || fieldError}
        onChange={handleInputChange}
        onKeyDown={handleEnter}
        onBlur={handleInputBlur}
        onPaste={handlePaste}
        inputProps={{
          'data-testid': `${testId}`,
        }}
        helperText={errorText}
      />
      <EmailChips
        onDelete={removeEmail}
        emails={emails}
        testIdLocation={testIdLocation}
      />
    </>
  );
};
