import { FC, useState } from 'react';

import { colors } from '@classkick/pkg-constants';

import { cleanClamp, decimalToIntPercent } from 'shared/numbers';
import { useAnimationFrame } from 'shared/useAnimationFrame/useAnimationFrame';

import 'components/Progress/Progress.scss';


export type ProgressProps = {
  completion: number, // percent complete expressed as a value between 0 and 1
  trackColor?: string, // the background color of the dial (always completely filled in)
  fillColor?: string, // the foreground color of the fill (shows partial progress filled)
};

export const Progress: FC<ProgressProps> = ({
  completion,
  trackColor = colors.lightGray,
  fillColor = colors.brandGreen,
}) => {
  const size = 188.19;
  const [display, setDisplay] = useState<number>(0);

  useAnimationFrame(() => {
    setDisplay(prevDisplay => {
      const nextDisplay = prevDisplay + ((completion - prevDisplay) * 0.1);
      // return a steady value once we're close enough that the number no longer changes
      if (Math.abs(completion - nextDisplay) < 0.01) { return completion; }
      return nextDisplay;
    });
  });

  /*
   * SVG uses stroke-dasharray and stroke-dashoffset to animate the progress line
   * https://jakearchibald.com/2013/animated-line-drawing-svg/
   */

  return (
    <div className="progress">
      <svg viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg">
        <circle cx="38" cy="38" r="30" fill="none" stroke={trackColor} strokeWidth="16"/>
        <circle
          className="dash-path"
          cx="38"
          cy="38"
          r="30"
          fill="none"
          stroke={fillColor}
          strokeDasharray={size}
          style={{ strokeDashoffset: cleanClamp((1-completion)*size, 0, size) }}
          strokeLinecap="round"
          strokeWidth="16"
        />
      </svg>
      <span className="number" data-testid="progress-text-completion">{decimalToIntPercent(display)}%</span>
      <span className="label">complete</span>
    </div>
  );
};
