import React, { FC, useState } from 'react';

import { Box, Button } from '@mui/material';

import { send } from 'api/email/send';
import { InputWithEmailChips } from 'components/InputWithEmailChips/InputWithEmailChips';
import { Kickput } from 'components/Kickput/Kickput';
import { QuoteData } from 'pages/Quote/QuoteData';
import { validators } from 'pages/Wizard/Steps/Validators';
import 'pages/Quote/QContactUsForm/QContactUsForm.scss';


export interface ContactInput {
  cc: string[];
  senderEmail: string;
  message: string;
  subject: string;
}

export type QContactUsFormProps = {
  quoteData: QuoteData
};

export const QContactUsForm: FC<QContactUsFormProps> = (props) => {

  const [contactInput, setContactInput] = useState<ContactInput>({
    cc: [],
    senderEmail: '',
    message: '',
    subject: `Question about Quote #${props.quoteData.quote_num}`,
  });

  const [subjectInvalid, setSubjectInvalid] = useState<boolean>(false);
  const [senderEmailInvalid, setSenderEmailInvalid] = useState<boolean>(false);
  const [messageInvalid, setMessageInvalid] = useState<boolean>(false);
  const [ccInvalid, setCcInvalid] = useState<boolean>(false);

  const resetFieldOnUserInput = (dataKey: keyof ContactInput) => {
    switch (dataKey){
      case 'subject':
        setSubjectInvalid(false);
        break;
      case 'senderEmail':
        setSenderEmailInvalid(false);
        break;
      case 'message':
        setMessageInvalid(false);
        break;
    }
  };

  const updateData = (value: string, dataKey: keyof ContactInput) => {
    resetFieldOnUserInput(dataKey);
    setContactInput({
      ...contactInput,
      [dataKey]: value,
    });
  };

  const updateCC = (updatedEmailArray: string[]) => {
    setContactInput({ ...contactInput, cc: updatedEmailArray });
  };


  const validateSubject = () => {
    const isSubjectValid = validators.isValueSet(contactInput.subject);
    setSubjectInvalid(!isSubjectValid);
  };

  const validateSenderEmail = () => {
    const isEmailValid =
      validators.isValueSet(contactInput.senderEmail) &&
      validators.isEmailValid(contactInput.senderEmail);
    setSenderEmailInvalid(!isEmailValid);
  };

  const validateMessage = () => {
    const isMessageValid =
      validators.isValueSet(contactInput.message);
    setMessageInvalid(!isMessageValid);
  };

  const isButtonDisabled = () => {
    const hasSubject = contactInput.subject.length > 0;
    const hasMessage = contactInput.message.length > 0;
    const hasEmail = contactInput.senderEmail.length > 0;

    if (hasSubject && hasEmail && hasMessage) {
      if (!subjectInvalid && !senderEmailInvalid && !messageInvalid && !ccInvalid) {
        return false;
      }
    }
    return true;
  };

  const sendEmail = () => {
    send(
      contactInput.subject,
      contactInput.message,
      contactInput.senderEmail,
      contactInput.cc,
    );
  };

  return (
    <div data-testid="quote-contactUs-modal" className='quote-contactus-form'>
      <div
        className='quote-contactus-form'
        data-testid="quote-modal-contactUsForm"
      >
        <Kickput<ContactInput>
          dataKey='subject'
          error={subjectInvalid}
          helperText={subjectInvalid ? 'Please enter a subject.' : undefined }
          label='Subject *'
          onBlur={validateSubject}
          placeholder={`Question about Quote #${props.quoteData.quote_num}`}
          testId='contactUsForm-input-subject'
          updateData={updateData}
          wholeData={contactInput}
        />
        <Kickput<ContactInput>
          dataKey="senderEmail"
          error={senderEmailInvalid}
          helperText={senderEmailInvalid ? 'Please enter a valid email address.' : undefined}
          label="Your Email *"
          onBlur={validateSenderEmail}
          placeholder="you@school.edu."
          testId='contactUsForm-input-email'
          updateData={updateData}
          wholeData={contactInput}
        />
        <InputWithEmailChips
          label="CC"
          placeholder="Separate emails with a space or comma, or by pressing the Enter key."
          testId='contactUsForm-input-inputWithEmailChips'
          updateList={updateCC}
          testIdLocation='contactUsForm'
          setFieldError={setCcInvalid}
        />
        <Kickput<ContactInput>
          dataKey="message"
          error={messageInvalid}
          helperText={messageInvalid ? 'Please enter a message.' : undefined}
          label="Message *"
          minRows={6}
          multiline={true}
          onBlur={validateMessage}
          placeholder="Leave a detailed message."
          testId='contactUsForm-input-message'
          updateData={updateData}
          wholeData={contactInput}
        />
        <Box
          className='contactus-form-sendbtn'
          component='div'
        >
          <Button
            data-testid='contactUsForm-button-send'
            disabled={isButtonDisabled()}
            onClick={sendEmail}
          >
            Send
          </Button>
        </Box>
      </div>
    </div>
  );
};
