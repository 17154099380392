import React, { FC } from 'react';

import { RadioGroup, Stack } from '@mui/material';

import { colors, svgIn } from '@classkick/pkg-constants';


import { Choice } from 'components/Choice/Choice';
import { NumberOfSchools, SubscriptionType } from 'pages/Wizard/QuoteInput';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { useFlag } from 'shared/useFlag/useFlag';


export const SubscriptionTypeStep: FC<BaseStepProps> = (props) => {
  const { quoteInput, setQuoteInput } = props;
  const isTeacherActive = useFlag('sb_wizard_type_teacher');
  const isOtherActive = useFlag('sb_wizard_type_other');

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const subscriptionType = (event.target as HTMLInputElement).value as SubscriptionType;
    setValue(subscriptionType);
  };

  const setValue = (subscriptionType: SubscriptionType): void => {
    let numberOfSchools: NumberOfSchools = '';
    if (subscriptionType === 'school') {
      numberOfSchools = '1';
    }
    setQuoteInput({
      ...quoteInput,
      subscriptionType,
      numberOfSchools,
    });
  };

  return <>
      <RadioGroup
        style={{ paddingTop: 40 }}
        aria-label="subscription-type"
        name="subscription-type"
        data-testid='subscriptionTypeStep-radio-group'
        value={quoteInput.subscriptionType}
        onChange={handleSelection}>
        <Stack
          spacing={8.5}
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          {
            isTeacherActive ?
              <Choice
                url={svgIn.apple.url}
                value='teacher'
                setValue={setValue}
                label={'Teacher'}
                color={colors.lightGray}
                disabled={true}
              /> : null
          }
          <Choice
            url={svgIn.school.url}
            value='school'
            setValue={setValue}
            label={'School'}
            color={colors.brandGreen}
          />
          <Choice
            url={svgIn.district.url}
            value='district'
            setValue={setValue}
            label={'District'}
            color={colors.brandGreen}
          />
          {
            isOtherActive ?
              <Choice
                url={svgIn.book.url}
                value='other'
                setValue={setValue}
                label={'Other'}
                color={colors.lightGray}
                disabled={true}
              /> : null
          }
        </Stack>
      </RadioGroup>
    </>;
};
