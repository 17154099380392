import { useNavigate, useParams } from 'react-router-dom';

import useAxios from 'axios-hooks';

import { buildEndpoint } from 'api/endpoints';
import { APIResponse } from 'api/response';
import { quoteDataFixture } from 'fixtures/quoteData';
import { QuoteData } from 'pages/Quote/QuoteData';


export const translateQuoteData = (quoteData: QuoteData): void => {
  quoteData.created_at = new Date(quoteData.created_at as unknown as string);
  quoteData.expired_at = new Date(quoteData.expired_at as unknown as string);
  quoteData.license_start = new Date(quoteData.license_start as unknown as string);
  quoteData.license_end = new Date(quoteData.license_end as unknown as string);
  quoteData.discounts = quoteData.discounts || [];
  quoteData.quote_summary_items = quoteData.quote_summary_items.map((item) => {
    item.start = new Date(item.start as unknown as string);
    item.end = new Date(item.end as unknown as string);
    return item;
  });

  if (quoteData.invoiced_at) {
    quoteData.invoiced_at = new Date(quoteData.invoiced_at as unknown as string);
  }
};

export const useQuote = (): { quoteData: QuoteData | undefined, reload: () => void } => {
  const navigate = useNavigate();
  const { quote_id } = useParams<'quote_id'>();


  // TODO: change the below 'let' to const after endpoint is implemented.
  // eslint-disable-next-line prefer-const
  let [{ data: quoteResponse, error }, execute] =  useAxios<APIResponse<QuoteData>>(buildEndpoint.quoteGetById(quote_id));

  const quoteData: QuoteData | undefined = quoteResponse?.data;

  if (quoteData) {
    translateQuoteData(quoteData);
  }

  const reload = () => {
    execute();
  };

  /*
  TODO: '/v2/salesbuddy/quote/quoteId' works, but...
      We are faking the response for two specific quote_ids until we resolve our e2e testing strategy.
  */
  if (error) {
    if (quote_id === '12345') {
      quoteResponse = { success: true, message: '', data: quoteDataFixture.dummy() };
    } else if (quote_id === '54321') {
      quoteResponse = { success: true, message: '', data: quoteDataFixture.schoolQuote(quoteDataFixture.dummy()) };
    } else if (quote_id === '09876') {
      quoteResponse = { success: true, message: '', data: quoteDataFixture.expired(quoteDataFixture.dummy()) };
    } else if (quote_id === 'fake-renewal') {
      quoteResponse = { success: true, message: '', data: quoteDataFixture.fakeRenewal(quoteDataFixture.dummy()) };
    } else {
      navigate('/');
    }
  }

  return { quoteData: quoteResponse?.data, reload };
};
