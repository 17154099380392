import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import posthog from 'posthog-js';

import { StepName } from 'pages/Wizard/Steps/Steps';

import { PubSub } from 'shared/pubSub/PubSub';


export const RELOAD_INTERVAL_MS = 60000;

export interface PostHogPubSubArgObj {
  flags: string[];
  variants: Record<string, string | boolean>;
}

export const postHogPubSub: PubSub<PostHogPubSubArgObj> = new PubSub<PostHogPubSubArgObj>();

export type EventName = 'quotePage:quote_viewed' |
  'landingPage:heroButton_clicked' |
  `${StepName}:wizardStep_completed`;

export const captureEvent = (eventName: EventName, metadata: Record<string, unknown>): void => {
  posthog.capture(eventName, metadata);
};

export const PostHog: FC = (props) => {
  const location = useLocation();

  useEffect(() => {
    // autocapture true is too noisy; sending every click and input on any element
    // (see https://posthog.com/docs/integrate/client/js#autocapture#autocapture )
    posthog.init(
      process.env.REACT_APP_POSTHOG_TOKEN || '',
      {
        api_host:process.env.REACT_APP_POSTHOG_HOST || '',
        autocapture: false,
      });

    if (process.env.REACT_APP_POSTHOG_WINDOW === 'true') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as unknown as any).posthog = posthog;
    }

    // https://posthog.com/docs/integrate/client/js#super-properties
    posthog.register({
      environment: process.env.REACT_APP_POSTHOG_ENV || 'unknown',
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      posthog.reloadFeatureFlags();
    }, RELOAD_INTERVAL_MS);
  }, []);

  useEffect(() => {
    posthog.onFeatureFlags((flags: string[], variants: Record<string, string | boolean>) => {
      postHogPubSub.callAllHandlers({
        flags,
        variants,
      });
    });
  }, []);

  useEffect(() => {
    posthog.capture(
      '$pageview',
      { $current_url: location.pathname },
    );
  }, [location]);

  return null;
};
