import axios, { AxiosResponse, CancelToken } from 'axios';

import { APIResponse } from 'api/response';


export type LoadState = 'idle' | 'loading' | 'error';

export const performTypeAhead = <T>(
  endpoint: string,
  params: Record<string, unknown>,
  cancelToken: CancelToken,
  setResults: (data: T[]) => void,
  setLoadState: (loadState: LoadState) => void,
): void => {
  setLoadState('loading');
  axios.get<APIResponse<T[]>>(endpoint, {
    cancelToken,
    params,
  }).then((response: AxiosResponse<APIResponse<T[]>>) => {
    let results: T[] = [];

    if (response.data.success && response.data.data) {
      results = [...response.data.data];
    }
    setLoadState('idle');
    setResults(results);
  }, (error) => {
    setLoadState('error');
  });
};
