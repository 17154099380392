import React from 'react';

import Link from '@mui/material/Link';


export const buildEmailLink = (email: string, testId: string): React.ReactNode => {
  return (
    <Link
      data-testid={testId}
      href={`mailto:${email}`}
      target="_blank"
      rel="noopener"
      underline="hover">
      {email}
    </Link>
  );
};

export const buildLink = (url: string, testId: string): React.ReactNode => {
  return (
    <Link
      data-testid={testId}
      href={url}
      target="_blank"
      rel="noopener"
      underline="hover">{url}</Link>
  );
};

export const buildLinkWithText = (url: string, text: string, testId: string): React.ReactNode => {
  return (
    <Link
      data-testid={testId}
      href={url}
      target="_blank"
      rel="noopener"
      underline="hover">{text}</Link>
  );
};
