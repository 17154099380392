import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AxiosResponse } from 'axios';

import { order } from 'api/order';
import { OrderCreated } from 'api/order/create';
import { APIResponse } from 'api/response';
import { POCheckoutData } from 'pages/Checkout/Checkout';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';

import 'pages/Quote/Quote.scss';


export interface PurchaseOrderProps {
  checkoutData: POCheckoutData;
  publicId: string;
  quoteType: SubscriptionType;
}

export const PurchaseOrderError: FC<PurchaseOrderProps> = (props) => {
  const { checkoutData, publicId, quoteType } = props;
  const navigate = useNavigate();
  const [inFlight, setInFlight] = useState<boolean>(false);

  const submitPurchaseOrder = () => {
    setInFlight(true);
    order.create(quoteType, publicId, checkoutData).then(
      (response: AxiosResponse<APIResponse<OrderCreated>>) => {
        navigate(`/quote/${publicId}/congratulations`);
    }, (response) => {
      navigate(`/quote/${publicId}/error`);
    });
  };

  return (
    <div className="quote-modal-content">
      <Typography variant="body1" mb={4} sx={{ maxWidth: '615px' }} data-testid="submit-po-error-message">
        We're sorry, we encountered a problem submitting your purchase order. Please try again.
      </Typography>
      <Button
        disabled={inFlight}
        className="quote-modal-button"
        data-testid='submit-po-button'
        onClick={() => submitPurchaseOrder()}
      >
        { inFlight ? 'Processing' : 'Submit' }
      </Button>
    </div>
  );
};
