import React, { ReactElement } from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Toolkick } from 'components/Toolkick/Toolkick';


export interface KickputProps<T> {
  // required
  dataKey: keyof T;
  label: string;
  placeholder: string;
  updateData: (value: string, dataKey: keyof T) => void;
  wholeData: T;
  // optional
  description?: string;
  error?: boolean;
  helperText?: string;
  maxLength?: number;
  minRows?: string | number;
  multiline?: boolean;
  onBlur?: (value: string) => void;
  testId?: string;
  toolKick?: string;
}

export function Kickput<T>({
  // required
  dataKey,
  label,
  placeholder,
  updateData,
  wholeData,
  // optional
  description,
  error,
  helperText,
  maxLength,
  minRows,
  multiline,
  onBlur,
  testId,
  toolKick,
}: KickputProps<T>): ReactElement | null {
  return (
    <>
      <Typography
        variant="subtitle2"
        style={{
          alignSelf: 'flex-start',
          fontWeight: 'normal',
          marginTop: '24px',
        }}
        data-testid={`${testId}-label`}
      >
        {label}{toolKick && <Toolkick text={toolKick}/>}
      </Typography>
      <Typography variant="sbFormDescription" data-testid={`${testId}-description`}>{description}</Typography>
      <TextField
        placeholder={placeholder}
        multiline={multiline}
        minRows={minRows}
        fullWidth
        error={error}
        helperText={helperText}
        FormHelperTextProps={
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { 'data-testid': `${testId}-helper` } as unknown as any
        }
        onChange={(evt) => {
          updateData(evt.target.value, dataKey);
        }}
        value={wholeData[dataKey]}
        inputProps={{
          maxLength,
          'data-testid': testId,
          'label': label,
        }}
        onBlur={(evt) => {
          if (onBlur) { onBlur(evt.target.value); }
        }}
      />
    </>
  );
}
