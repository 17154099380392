import { SubscriptionType } from 'pages/Wizard/QuoteInput';


export type EndpointName =
    'emailSend'
  | 'orderValidate'
  | 'organizationSearch'
  | 'pricingCurrent'
  | 'quoteCreateDistrict'
  | 'quoteCreateSchool';

export type ParameterizedEndpointName =
    'schoolsOfDistrict'
  | 'cancelQuote'
  | 'createOrder'
  | 'extendQuote'
  | 'paymentIntent'
  | 'quoteGetById';

export const endpoints: Record<EndpointName, string> = {
  emailSend: '/v2/salesbuddy/email/send',
  orderValidate: '/v2/salesbuddy/order/validate',
  organizationSearch: '/v2/salesbuddy/organizations/search',
  pricingCurrent: '/v2/salesbuddy/pricing',
  quoteCreateDistrict: '/v2/salesbuddy/district/quote',
  quoteCreateSchool: '/v2/salesbuddy/school/quote',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildEndpoint: Record<ParameterizedEndpointName, (...args: any[]) => string> = {
  cancelQuote: (quote_type: SubscriptionType, public_id: string) => { return `/v2/salesbuddy/${quote_type}/quote/${public_id}/cancel`; },
  createOrder: (quote_type: SubscriptionType, public_id: string) => { return `/v2/salesbuddy/${quote_type}/quote/${public_id}/po/order`; },
  extendQuote: (quote_type: SubscriptionType, public_id: string) => { return `/v2/salesbuddy/${quote_type}/quote/${public_id}/extend`; },
  paymentIntent: (quote_type: SubscriptionType, public_id: string) => { return `/v2/salesbuddy/${quote_type}/quote/${public_id}/cc/payment`; },
  quoteGetById: (public_id: string) => { return `/v2/salesbuddy/quote/${public_id}`; },
  schoolsOfDistrict: (districtPID: string) => { return `/v2/salesbuddy/organizations/${districtPID}/children`; },
};
