import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import { colors } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import 'pages/Quote/Payment/PaymentOption.scss';


export type PaymentOptionProps = {
  iconURL: string,
  paymentURL: string,
  testId: string,
  text: string,
};

export const PaymentOption: FC<PaymentOptionProps> = (props) => {
  const { iconURL, testId, text, paymentURL } = props;

  return (
    <Button
      component={Link}
      to={paymentURL}
      sx={{
        backgroundColor: colors.white,
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 2px rgba(64, 64, 65, 0.05)',
        color: colors.brandGreen,
        fontSize: '16px',
        fontWeight: 500,
        justifyContent: 'flex-start',
        lineHeight: '16px',
        margin: '0 15px',
        padding: '40px 32px',
        textTransform: 'none',
        width: '310px',
        '&:hover': {
          backgroundColor: colors.hoverGray,
        },
      }}
      className="payment-option"
      data-testid={testId}
      disableElevation={false}
      startIcon={<SVGIn url={iconURL} />}
      variant="contained"
    >
      {text}
    </Button>
  );
};
