import { FC } from 'react';

import { SVGIn } from '@classkick/pkg-svgin';

import { BackgroundCircle } from 'components/BackgroundCircle/BackgroundCircle';

import 'components/IconInCircle/IconInCircle.scss';


export interface IconInCircleProps {
  url: string,
  clickFn?: () => void,
  bgColor?: string,
  color?: string,
  size?: number,
  testId?: string,
}

export const IconInCircle: FC<IconInCircleProps> = ({
  url,
  clickFn = undefined,
  bgColor,
  color,
  size = 125,
  testId,
}) => {

  return <>
  <div
    className='icon-in-circle'
    data-testid={testId}
    onClick={clickFn}
    style={{ cursor: clickFn ? 'pointer' : 'auto' }}
  >
    <BackgroundCircle size={size} bgColor={bgColor}>
      <SVGIn url={url} color={color}/>
    </BackgroundCircle>
  </div>
  </>;
};
