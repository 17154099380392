import { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { colors, contact, link } from '@classkick/pkg-constants';

import 'pages/Lander/Footer/Footer.scss';


export interface LinkData {
  href: string;
  testDescriptor: string;
  text: string;
  target: string;
}

export interface LinkColumn {
  title: string;
  testDescriptor: string;
  links: LinkData[];
}

const classkickLinks: LinkData[] = [
  {
    href: link.aboutUs,
    testDescriptor: 'aboutUs',
    text: 'About Us',
    target: '_blank',
  },
  {
    href: link.jobs,
    testDescriptor: 'jobs',
    text: 'Jobs',
    target: '_blank',
  },
  {
    href: link.blog,
    testDescriptor: 'blog',
    text: 'Blog',
    target: '_blank',
  },
  {
    href: link.orderPro,
    testDescriptor: 'orderPro',
    text: 'Order Pro',
    target: '_blank',
  },
  {
    href: link.proFeatures,
    testDescriptor: 'proFeatures',
    text: 'Pro Features',
    target: '_blank',
  },
];

const resourcesLinks: LinkData[] = [
  {
    href: link.teacherResources,
    testDescriptor: 'teacherResources',
    text: 'Teacher Resources',
    target: '_blank',
  },
  {
    href: link.assignmentLibrary,
    testDescriptor: 'assignmentLibrary',
    text: 'Assignment Library',
    target: '_blank',
  },
  {
    href: link.professionalDev,
    testDescriptor: 'pd',
    text: 'Professional Development',
    target: '_blank',
  },
  {
    href: link.faqs,
    testDescriptor: 'faqs',
    text: 'FAQs',
    target: '_blank',
  },
];

const supportLinks: LinkData[] = [
  {
    href: link.itSupport,
    testDescriptor: 'it',
    text: 'IT Support',
    target: '_blank',
  },
  {
    href: link.termsOfService,
    testDescriptor: 'tos',
    text: 'Terms of Service',
    target: '_blank',
  },
  {
    href: link.coppaPrivacy,
    testDescriptor: 'coppa',
    text: 'COPPA Privacy',
    target: '_blank',
  },
  {
    href: link.privacyPolicy,
    testDescriptor: 'privacy',
    text: 'Privacy Policy',
    target: '_blank',
  },
  {
    href: `mailto:${contact.supportEmail}`,
    testDescriptor: 'contactUs',
    text: 'Contact Us',
    target: '_blank',
  },
];

const communityLinks: LinkData[] = [
  {
    href: link.classroomSpotlight,
    testDescriptor: 'spotlight',
    text: 'Classroom Spotlight',
    target: '_blank',
  },
  {
    href: link.classkickChampions,
    testDescriptor: 'champions',
    text: 'Classkick Champions',
    target: '_blank',
  },
  {
    href: link.instagram,
    testDescriptor: 'instagram',
    text: 'Instagram',
    target: '_blank',
  },
  {
    href: link.facebook,
    testDescriptor: 'facebook',
    text: 'Facebook',
    target: '_blank',
  },
  {
    href: link.twitter,
    testDescriptor: 'twitter',
    text: 'Twitter',
    target: '_blank',
  },
  {
    href: link.youtube,
    testDescriptor: 'youtube',
    text: 'YouTube',
    target: '_blank',
  },
];

export const linkColumns: LinkColumn[] = [
  {
    title: 'Classkick',
    testDescriptor: 'footer1',
    links: classkickLinks,
  },
  {
    title: 'Resources',
    testDescriptor: 'footer2',
    links: resourcesLinks,
  },
  {
    title: 'Support',
    testDescriptor: 'footer3',
    links: supportLinks,
  },
  {
    title: 'Community',
    testDescriptor: 'footer4',
    links: communityLinks,
  },
];

export const Footer: FC = () => {

  return (
    <Box
      component="footer"
      sx={{ px: 15, pt: 2, pb: 9 }}
      data-testid="landing-block-footer"
    >
      <Grid
        container
        columns={{ xs: 3, sm: 6, lg: 12 }}
        direction='row'
        justifyContent="space-evenly"
      >

        {
          linkColumns.map((column, i) => {
            return (
              <Grid
                item
                xs={3}
                key={`col-${i}`}
                mt={7}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  data-testid={`landing-title-${column.testDescriptor}`}
                  textTransform="uppercase"
                  color={colors.mediumGray}
                  mb={3}
                >{column.title}</Typography>
                <ul className="footer-list">
                  {
                    column.links.map((link, j) => {
                      return (
                        <li className="footer-item" key={`link-${i}-${j}`}><a
                          href={link.href}
                          data-testid={`landing-link-${link.testDescriptor}`}
                          target={link.target}
                        >{link.text}</a></li>
                      );
                    })
                  }
                </ul>
              </Grid>
            );
          })
        }

      </Grid>
    </Box>
  );
};
