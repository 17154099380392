/* istanbul ignore file */
// react-pdf is expecting to run in a browser, and sends errors when run through Jest.
// The way to correct those errors is to mock react-pdf so far that unit tests lose value.
// This file/flow will need coverage either e2e or manually.
import { FC } from 'react';

import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { colors, contact, link, png } from '@classkick/pkg-constants';

import { QuoteData } from 'pages/Quote/QuoteData';
import { formatDateSlashes } from 'shared/dates';
import { formatDollars } from 'shared/numbers';


export type QuotePDFProps = {
  quote: QuoteData,
};

export const QuotePDF: FC<QuotePDFProps> = (props) => {
  const { quote } = props;
  const url = process.env.REACT_APP_BASE_URL;

  Font.register({
    family: 'Gotham Rounded Bold',
    fontStyle: 'normal',
    src: 'https://assets.classkick.com/fonts/gotham-rounded/GothamRounded-Bold.otf',
  });

  const styles = StyleSheet.create({
    // Line height of 1.78 get us close to the 16px listed on the mocks (in relation to the font size).
    blockMargin: { marginTop: 8 },
    body: { color: colors.charcoalGray, fontFamily: 'Helvetica', fontSize: 9, lineHeight: 1.78 },
    costColumn: { textAlign: 'right', width: 37 },
    descriptionColumn: { marginRight: 20, width: 150 },
    darkBorder: { borderBottomColor: colors.charcoalGray, borderBottomWidth: 1, marginBottom: 12 },
    // Margin top is taking the line height of the above text into account so it equals the 12px margin on the mocks.
    darkBorderTopMargin: { marginTop: 4.98 },
    header: { alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 52 },
    itemColumn: { marginRight: 20, width: 150 },
    lightBorder: { borderBottomColor: colors.lightGray, borderBottomWidth: 1, marginBottom: 12, marginTop: 4.98 },
    lineMargin: { marginTop: 16 },
    link: { color: colors.brandGreen, fontFamily: 'Helvetica-Bold', fontSize: 9, lineHeight: 1.78 },
    logo: { width: 128 },
    page: { padding: 40 },
    unitPriceColumn: { marginRight: 35, textAlign: 'right', width: 43 },
    quantityColumn: { marginRight: 35, textAlign: 'right', width: 37 },
    quoteInfo: { width: 156 },
    quoteInfoKey: { marginRight: 24, width: 34 },
    quoteSummary: { width: 284 },
    quoteSummaryKey: { marginRight: 24, width: 57 },
    quoteTitle: { color: colors.mediumGray, fontFamily: 'Gotham Rounded Bold' , fontSize: 28 },
    // Setting height to hold 10 schools (lineHeight * 10) so the quote breakdown below displays in the same place regardless of # of schools.
    schoolsList: { height: 160.2 },
    summary: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 60 },
    summaryBlock: { flexDirection: 'row' },
    tableRow: { alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' },
    tableTotal: { color: colors.charcoalGray, fontFamily: 'Helvetica-Bold', fontSize: 12, lineHeight: 1.78 },
    terms: { marginTop: 40 },
    termsBlock: { marginBottom: 20 },
    termsCopy: { flexDirection: 'row' },
    title: { color: colors.charcoalGray, fontFamily: 'Helvetica-Bold', fontSize: 9, lineHeight: 1.78 },
  });

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.header}>
          <Image src={png.fullbrandlogo.url} style={styles.logo} />
          <Text style={styles.quoteTitle}>Quote</Text>
        </View>
        <View style={styles.summary}>
          <View style={styles.quoteSummary}>
            <View style={styles.darkBorder} />
            <View style={styles.summaryBlock}>
              <Text style={[styles.title, styles.quoteSummaryKey]}>From</Text>
              <View>
                <Text style={styles.body}>Classwork Co DBA Classkick</Text>
                <Text style={styles.body}>PO Box 772728</Text>
                <Text style={styles.body}>Area Number {quote.quote_num}</Text>
                <Text style={styles.body}>Detroit, MI 48277</Text>
              </View>
            </View>
            <View style={styles.lightBorder} />
            <View style={styles.summaryBlock}>
              <Text style={[styles.title, styles.quoteSummaryKey]}>Prepared For</Text>
              <View style={styles.schoolsList}>
                {
                  quote.schools.map((school) => {
                    return (
                      <Text key={school.pid} style={styles.body}>{school.institution_name}</Text>
                    );
                  })
                }
              </View>
            </View>
          </View>
          <View style={styles.quoteInfo}>
            <View style={styles.darkBorder} />
            <View style={styles.summaryBlock}>
              <Text style={[styles.title, styles.quoteInfoKey]}>Quote #</Text>
              <Text style={styles.body}>{quote.quote_num}</Text>
            </View>
            <View style={styles.lightBorder} />
            <View style={styles.summaryBlock}>
              <Text style={[styles.title, styles.quoteInfoKey]}>Created</Text>
              <Text style={styles.body}>{formatDateSlashes(quote.created_at)}</Text>
            </View>
            <View style={styles.lightBorder} />
            <View style={styles.summaryBlock}>
              <Text style={[styles.title, styles.quoteInfoKey]}>Expires</Text>
              <Text style={styles.body}>{formatDateSlashes(quote.expired_at)}</Text>
            </View>
            <View style={styles.lightBorder} />
            <Link src={`${url}/quote/${quote.public_id}`} style={styles.link}>View Quote on Web</Link>
          </View>
        </View>
        <View>
          <View style={styles.darkBorder} />
          <View style={styles.tableRow}>
            <Text style={[styles.title, styles.itemColumn]}>Item</Text>
            <Text style={[styles.title, styles.descriptionColumn]}>Description</Text>
            <Text style={[styles.title, styles.quantityColumn]}>Quantity</Text>
            <Text style={[styles.title, styles.unitPriceColumn]}>Unit Price</Text>
            <Text style={[styles.title, styles.costColumn]}>Cost</Text>
          </View>
          <View style={[styles.darkBorder, styles.darkBorderTopMargin]} />
          {
            quote.quote_summary_items.map((lineItem, index) => {
              //get start and end years
              const startYear = lineItem.start.getFullYear();
              const endYear = lineItem.end.getFullYear();
              const startMonth = lineItem.start.toLocaleString('default', { month: 'short' });
              const endMonth = 'June';
              return (
                <View key={index}>
                  <View style={styles.tableRow}>
                    <Text style={[styles.body, styles.itemColumn]}>{`${startYear}-${endYear} School Subscription`}</Text>
                    <Text style={[styles.body, styles.descriptionColumn]}>
                      { lineItem.prorated ? `Prorated ${startMonth} - ${endMonth}` : `${startMonth} ${startYear} - ${endMonth} ${endYear}` }
                    </Text>
                    <Text style={[styles.body, styles.quantityColumn]}>{quote.schools.length}</Text>
                    <Text style={[styles.body, styles.unitPriceColumn]}>{formatDollars(lineItem.amount / quote.schools.length / 100)}</Text>
                    <Text style={[styles.body, styles.costColumn]}>{formatDollars(lineItem.amount / 100)}</Text>
                  </View>
                  { index !== quote.quote_summary_items.length - 1 && <View style={styles.lightBorder} /> }
                </View>
              );
            })
          }
          <View style={[styles.darkBorder, styles.darkBorderTopMargin]} />
          <View style={styles.tableRow}>
            <Text style={styles.tableTotal}>Total</Text>
            <Text style={styles.tableTotal}>{formatDollars(quote.price / 100)}</Text>
          </View>
        </View>
      </Page>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.darkBorder} />
        <View style={styles.termsBlock}>
          <Text style={styles.title}>To Pay by Card</Text>
          <View style={styles.blockMargin}>
            <Link src={`${url}/quote/${quote.public_id}/credit-card`} style={styles.link}>{`${url}/quote/${quote.public_id}/credit-card`}</Link>
          </View>
        </View>
        <View style={styles.termsBlock}>
          <Text style={styles.title}>To Pay by Purchase Order</Text>
          <View style={styles.blockMargin}>
            <Text style={styles.body}>Email your purchase order to:</Text>
            <Text style={styles.body}>{contact.purchaseOrdersEmail}</Text>
            <Text style={[styles.body, styles.lineMargin]}>Mail your check to:</Text>
            <Text style={styles.body}>Classwork Co DBA Classkick</Text>
            <Text style={styles.body}>PO Box 772728</Text>
            <Text style={styles.body}>Area Number: {quote.quote_num}</Text>
            <Text style={styles.body}>Detroit, MI 48277</Text>
          </View>
        </View>
        <View style={[styles.darkBorder, styles.terms]} />
        <View style={styles.termsBlock}>
          <Text style={styles.title}>Terms</Text>
          <Text style={[styles.blockMargin, styles.termsCopy]}>
            <Text style={styles.body}>Our W-9 can be found here: </Text>
            <Link src={link.w9view} style={styles.link}>classkick.com/w9</Link>
          </Text>
          <Text style={[styles.lineMargin, styles.termsCopy]}>
            <Text style={styles.body}>By submitting a payment or purchase order, you are agreeing to the Classkick Terms of Service found at </Text>
            <Link src={link.termsOfService} style={styles.link}>classkick.com/terms</Link>
            <Text style={styles.body}>, the terms and conditions of which are hereby expressly incorporated herein by reference.</Text>
          </Text>
        </View>
      </Page>
    </Document>
  );
};
