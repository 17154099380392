export const getDefaultSubscriptionStartDate = (): Date => {
  const currentDate: Date = new Date(Date.now());
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let defaultStartDate = new Date(currentYear, currentMonth);
  // If current month is between March and June, the default start date should be July of current year
  if (currentMonth >= 2 && currentMonth <= 5) {
    defaultStartDate = new Date(currentYear, 6);
  }
  return defaultStartDate;
};

export const getSubscriptionEndDates = (startDate = new Date()):Date[] => {
  const results:Date[] = [];
  const currentYear = startDate.getFullYear();
  let startYear = currentYear;
  if(startDate.getMonth() >= 6) {
    startYear = currentYear + 1;
  }
  for(let i = 0; i < 4; i++){
    const year = startYear + i;
    const endDate = new Date(year, 5, 30);
    results.push(endDate);
  }
  return results;
};

export const formatTimestampSlashes = (timestamp: number): string => {
  const date = new Date(timestamp);
  return formatDateSlashes(date);
};

export const formatDateSlashes = (date: Date): string => {
  const month = date.getMonth()+1;
  const day = date.getDate();
  const year = date.getFullYear() - 2000;
  return `${month}/${day}/${year}`;
};

export const timestampToMDY = (timestamp: number): string => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const firstOfThisMonth = (): Date => {
  const now: Date = new Date(Date.now());
  const firstOfMonth = new Date(now.getFullYear(), now.getMonth());
  return firstOfMonth;
};

export const MDYtoTimedISO = (mdy: string, h: number, m: number, s: number): string => {
  const date = new Date(mdy);
  date.setHours(h, m, s, 0);
  return date.toISOString();
};

export interface ItemDate {
  start: Date;
  end: Date;
}

export const subdivideSubscriptions =
  (start: Date, end: Date): ItemDate[] => {
    const boundaryMonth = 5;
    const boundaryDay = 30;
    const result: ItemDate[] = [];

    let currentStart: Date = start;

    const endThisYear = new Date(currentStart.getFullYear(), boundaryMonth, boundaryDay);
    const endNextYear = new Date(currentStart.getFullYear() + 1, boundaryMonth, boundaryDay);
    let currentEnd: Date = currentStart.getMonth() > boundaryMonth ? endNextYear : endThisYear;

    while (currentEnd.getTime() <= end.getTime()) {
      result.push({ start: currentStart, end: currentEnd });
      currentStart = currentEnd;
      currentEnd = new Date(currentStart.getFullYear() + 1, boundaryMonth, boundaryDay);
    }

    return result;
};
