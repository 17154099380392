import React, { FC } from 'react';

import { LogoType } from 'components/LogoType/LogoType';

import 'components/Header/Header.scss';

import { TwoUp } from 'components/TwoUp/TwoUp';


export type HeaderProps = {
  rightSlot?: FC,
};

export const Header: FC<HeaderProps> = (props) => {

  return <div
    className="header"
  >
    <TwoUp
      left={<LogoType size={44}/>}
      right={props.rightSlot ? <props.rightSlot/> : null}
    />
  </div>;
};
