import { FC } from 'react';

import Tooltip from '@mui/material/Tooltip';

import { svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import 'components/Toolkick/Toolkick.scss';


export type ToolkickProps = {
  text: string,
  iconURL?: string,
};

export const Toolkick: FC<ToolkickProps> = ({
  iconURL = svgIn.info.url,
  text,
}) => {

  return (
    <Tooltip title={text}>
      <span className="toolkick"><SVGIn url={iconURL} /></span>
    </Tooltip>
  );
};
