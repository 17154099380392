import React, { FC, FormEvent, useState } from 'react';

import { Button, Typography } from '@mui/material';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { QuoteData } from 'pages/Quote/QuoteData';


export interface CheckoutFormProps {
  quoteData: QuoteData;
}

export const CheckoutForm: FC<CheckoutFormProps> = (props) => {
  const { quoteData } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Ignoring for istanbul - if stripe/element is not defined, the button is disabled.
    // (the if is still necessary for TypeScript to be happy with possibly undefined stripe)
    /* istanbul ignore if */
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    setMessage(null);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:  `${process.env.REACT_APP_BASE_URL}/quote/${quoteData.public_id}/congratulations`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment (ie, malformed zipcode, insufficient funds, etc.)
    // ( see https://stripe.com/docs/testing )
    // Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || 'An error occured.');
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      {
        message &&
        <Typography
          component="p"
          variant="sbFinePrint"
          data-testid='ccCheckout-text-error'
          sx={{ textAlign: 'right', mt: 2 }}
        >{message}</Typography>
      }

      <Button
        type='submit'
        data-testid='ccCheckout-button-submit'
        disabled={isLoading || !stripe || !elements}
        sx={{ mt: 4, float: 'right' }}
      >{
        isLoading ?
          <div style={{ marginTop: '-18px' }}>
            <LoadingSpinner testLocation="ccCheckout" />
          </div>
          : 'Submit'
      }</Button>
    </form>
  );
};
