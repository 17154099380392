import axios, { AxiosResponse } from 'axios';

import { endpoints } from 'api/endpoints';
import { APIResponse } from 'api/response';


export interface EmailPayload {
  cc?: string[];
  message: string;
  recipients?: string[]
  senderEmail?: string;
  subject: string;
}

export const send = (subject: string, message: string, senderEmail?: string, ccEmail?: string[], recipients?: string[]): Promise<AxiosResponse<APIResponse<undefined>>> => {
  const payload: EmailPayload = {
    cc: ccEmail,
    message: message,
    recipients: recipients,
    senderEmail: senderEmail,
    subject: subject,
  };
  return axios.post<APIResponse<undefined>>(endpoints.emailSend, payload);
};
