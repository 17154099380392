import { FC } from 'react';

import Typography from '@mui/material/Typography';

import { Organization } from 'types/Organization';


export type DistrictDetailProps = {
  organization: Organization;
};

export const DistrictDetail: FC<DistrictDetailProps> = (props) => {
  const { organization } = props;
  return (
    <>
      <Typography variant="subtitle2" data-testid='quote-districtCard-contentTitle'>District Details</Typography>
      <Typography variant="body1">
        {organization.institution_name}<br/>
        {organization.city}, {organization.state} {organization.zipcode}<br/>
        {organization.country}
      </Typography>
    </>
  );
};
