import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { Button, Typography } from '@mui/material';

import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { CardInfo } from 'pages/Checkout/CardInfo/CardInfo';
import { CheckoutData } from 'pages/Checkout/Checkout';
import { CheckoutCard } from 'pages/Checkout/CheckoutCard/CheckoutCard';
import { Purchaser } from 'pages/Checkout/Purchaser/Purchaser';
import { QuoteStatus } from 'pages/Checkout/QuoteStatus/QuoteStatus';
import { ShoppingCart } from 'pages/Checkout/ShoppingCart/ShoppingCart';
import { Summary } from 'pages/Checkout/Summary/Summary';
import { useFlag } from 'shared/useFlag/useFlag';
import { useQuote } from 'shared/useQuote/useQuote';


export type CCCheckoutPhase = 'purchaser' | 'card';

export const CreditCard: FC = () => {
  const { quoteData, reload } = useQuote();
  const [ phase, setPhase ] = useState<CCCheckoutPhase>('purchaser');
  const [ isFormReady, setIsFormReady ] = useState<boolean>(false);
  const [ checkoutData, setCheckoutData ] = useState<CheckoutData>({
    purchaser: {
      agentName: '',
      agentEmail: '',
      adminEmail: '',
    },
  });
  const isCCActive = useFlag('sb_checkout_cc');
  const isFlagsLoaded = useFlag('sb_flags_loaded');
  const navigate = useNavigate();

  useEffect(() => {
    if (isFlagsLoaded && isCCActive === false) {
      navigate('/');
    }
  }, [isCCActive, isFlagsLoaded, navigate]);

  const notifyPurchaserValidity = (valid: boolean): void => {
    setIsFormReady(valid);
  };

  const goToCard = () => {
    setPhase('card');
  };

  const handleBadPaymentIntent = () => {
    setPhase('purchaser');
    reload();
  };

  if (quoteData) {
    if (quoteData.status === 'open') {
      return(
        <div className="sticky-page">

        <div className="sticky-content">
          <Typography
            variant="h2"
            mb={4}
            mt={3.375}
            data-testid="cc-order-content">
              {'Checkout'}
          </Typography>

          <CheckoutCard
            className="sticky-sticky"
            title="Order Summary"
            titleSpacing={0}
            testId="ccCheckout-card-summary">
              <Summary quote={quoteData} />
          </CheckoutCard>

          <CheckoutCard
            className="sticky-card"
            title="Shopping Cart"
            testId="ccCheckout-card-shopping">
            <ShoppingCart quote={quoteData} />
          </CheckoutCard>

          <CheckoutCard
            className="sticky-card"
            title="Purchaser"
            testId="ccCheckout-card-purchaser">
              {
                phase === 'purchaser' ?
                  <Purchaser<CheckoutData>
                    checkoutData={checkoutData}
                    setCheckoutData={setCheckoutData}
                    notifyValid={notifyPurchaserValidity}
                  />
                  :
                  <>
                    <Typography
                      data-testid="ccCheckout-text-nameLabel"
                      variant="body2"
                      mt={3}
                    >Purchasing Agent Name</Typography>
                    <Typography
                      data-testid="ccCheckout-text-nameValue"
                      variant="body1"
                    >{checkoutData.purchaser.agentName}</Typography>

                    <Typography
                      data-testid="ccCheckout-text-emailLabel"
                      variant="body2"
                      mt={3}
                    >Purchasing Agent Email</Typography>
                    <Typography
                      data-testid="ccCheckout-text-emailValue"
                      variant="body1"
                    >{checkoutData.purchaser.agentEmail}</Typography>

                    <Typography
                      data-testid="ccCheckout-text-adminLabel"
                      variant="body2"
                      mt={3}
                    >Classkick Admin Email</Typography>
                    <Typography
                      data-testid="ccCheckout-text-adminValue"
                      variant="body1"
                    >{checkoutData.purchaser.adminEmail}</Typography>
                  </>
              }
          </CheckoutCard>

          { phase !== 'purchaser' &&
            <CheckoutCard
              className="sticky-card"
              title="Card Information"
              testId="ccCheckout-card-cardInfo">
              <CardInfo
                quoteData={quoteData}
                checkoutData={checkoutData}
                onPaymentIntentError={handleBadPaymentIntent}
              />
            </CheckoutCard>
          }

          { phase === 'purchaser' &&
            <CheckoutCard
              className="sticky-card"
              title=""
              testId="ccCheckout-card-submit"
              titleSpacing={0}
              justifyContent="flex-end"
              >
              <Button
                  disabled={!isFormReady}
                  data-testid="ccCheckout-button-next"
                  onClick={goToCard}
                >Next</Button>
            </CheckoutCard>
          }

        </div>
      </div>
      );
    } else {
      return <QuoteStatus quoteData={quoteData} />;
    }
  }
  return(
    <LoadingSpinner testLocation="cc" />
  );
};
