import { FC } from 'react';

import { contact } from '@classkick/pkg-constants';

import 'pages/Lander/ContactUs/ContactUs.scss';


export const ContactUs: FC = () => {

  return (
    <div className='contact-modal-content'>
      <p className='contact-modal-details'>We can also create plans for organizations other than districts, schools, or teachers!</p>
      <p className='contact-modal-details'>
        Contact us at
        <a
          className='contact-modal-email'
          data-testid='landing-contact-email'
          href={`mailto:${contact.proEmail}`}
          target='_blank'
          rel='noreferrer'>
          <strong> {contact.proEmail} </strong>
        </a>
      </p>
    </div>
  );
};
