import React, { FC } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Organization } from 'types/Organization';


export type SchoolDetailProps = {
  schools: Organization[];
};

export const SchoolDetail: FC<SchoolDetailProps> = (props) => {
  const { schools } = props;

  const schoolElements = schools.map((school) => {
    return <Grid item key={school.pid} xs={6}>
      <Typography variant="subtitle2" data-testid='quote-schoolsCard-contentTitle'>School Details</Typography>
      <Typography variant="body1">
        {school.institution_name}<br/>
        {school.city}, {school.state} {school.zipcode}<br/>
        {school.country}
      </Typography>
    </Grid>;
  });

  return (
    <Grid container spacing={4}>
      {schoolElements}
    </Grid>
  );
};
