import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { colors, sticker } from '@classkick/pkg-constants';

import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { ContactUs } from 'pages/Lander/ContactUs/ContactUs';

import { useModal } from 'shared/useModal/useModal';

import 'pages/Lander/Hero/HeroTip/HeroTip.scss';


export const HeroTip: FC = () => {

  const { isDialogOpen, openDialog, closeDialog } = useModal();

  return (
    <Box
      data-testid='landing-card-tip'
      sx={{
        alignItems: 'center',
        backgroundColor: { lg: colors.hoverGray },
        borderRadius: '20px',
        display: 'flex',
        height: '128px',
        justifyContent: 'center',
        margin: { xs: '48px auto', lg: '0px auto 100px' },
        maxWidth: { xs: '280px', sm: '552px', md: '681px', lg: '848px' },
        padding: { lg: '30px 55px 30px 30px' },
      }}
    >
      <img
        data-testid='landing-sticker-magnifyingGlass'
        className='magnifying-glass'
        src={sticker.magnifyingGlass.url}
        height={75}
        width={75}
        alt=""
      />
      <div>
        <Typography data-testid='landing-text-tipHint' sx={{ marginLeft: { lg: '15px' } }}>
          We also can create plans for organizations other than districts, schools, or teachers!
        </Typography>
        <Button
          data-testid='landing-text-tipLink'
          onClick={openDialog}
          variant='greenLink'
          sx={{ display: 'block', marginLeft: { lg: '15px' } }}>
            Contact us →
        </Button>
      </div>
      <CustomDialog
        open={isDialogOpen}
        dialogContent={<ContactUs />}
        onClose={closeDialog}
        testId='landing-dialog-contactUs'
        title='Contact Us for a Quote'
      />
    </Box>
  );
};
