import React, { FC } from 'react';

import { Kickput } from 'components/Kickput/Kickput';
import { Creator } from 'pages/Wizard/QuoteInput';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { validators } from 'pages/Wizard/Steps/Validators';


export interface CreatorStepProps extends BaseStepProps {
  setCreatorValid: (valid: boolean) => void;
}

export const CreatorStep: FC<CreatorStepProps> = (props) => {
  const { quoteInput, setCreatorValid, setQuoteInput } = props;

  const updateData = (value: string, dataKey: keyof Creator) => {
    const creator: Creator = {
      ...quoteInput.creator,
      [dataKey]: value,
    };

    setQuoteInput({
      ...quoteInput,
      creator,
    });

    const isValid = validators.isValueSet(creator.name) && validators.isEmailValid(creator.email);
    setCreatorValid(isValid);
  };

  return (
    <div style={{ width: 420 }}>
      <Kickput<Creator>
        dataKey="name"
        label="Name *"
        placeholder="Jane Smith"
        updateData={updateData}
        wholeData={quoteInput.creator}
        testId="creatorStep-input-name"
      />
      <Kickput<Creator>
        dataKey="title"
        label="Title"
        placeholder="Technology Coordinator"
        updateData={updateData}
        wholeData={quoteInput.creator}
        testId="creatorStep-input-title"
      />
      <Kickput<Creator>
        dataKey="phone"
        label="Phone"
        placeholder="800-123-4567"
        updateData={updateData}
        wholeData={quoteInput.creator}
        testId="creatorStep-input-phone"
      />
      <Kickput<Creator>
        dataKey="email"
        label="Email *"
        placeholder="name@school.edu"
        updateData={updateData}
        wholeData={quoteInput.creator}
        testId="creatorStep-input-email"
      />
    </div>
  );
};
