import { FC } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { QuoteData } from 'pages/Quote/QuoteData';

import 'pages/Quote/Quote.scss';


export type NewQuoteProps = {
  quote: QuoteData,
};

export const GenerateNewQuote: FC<NewQuoteProps> = (props) => {
  // const { quote } = props;

  // TODO: finish create new quote request when API is ready
  // const createNewQuote = () => {
  //   const c = console;
  //   c.log('submitting create new quote to endpoint');
  // };

  return (
    <div className="quote-modal-content">
      <Typography variant="body1" mb={4} sx={{ maxWidth: '615px' }}>
        Do you want to create a new active quote with the same information?
      </Typography>
      <Button
        className="quote-modal-button"
        data-testid='create-new-quote-button'
        // onClick={() => createNewQuote()}
      >
        Create
      </Button>
    </div>
  );
};
