import React, { FC } from 'react';

import Button from '@mui/material/Button';

import smoothscroll from 'smoothscroll-polyfill';

import 'pages/Quote/HeaderLinks/HeaderLinks.scss';
import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { EmailQuoteForm } from 'components/EmailQuoteForm/EmailQuoteForm';
import { QuoteData } from 'pages/Quote/QuoteData';
import { useFlag } from 'shared/useFlag/useFlag';
import { useModal } from 'shared/useModal/useModal';


export interface HeaderLinksProps{
  quoteData?: QuoteData,
}

export const HeaderLinks:FC<HeaderLinksProps> = (props) => {

  const { isDialogOpen, openDialog, closeDialog } = useModal();
  const shouldShowEmailButton = useFlag('sb_contact_us');
  const { quoteData } = props;

  const jumpToFAQs = () => {
    const faqs = document.getElementsByClassName('q-faq')[0];
    smoothscroll.polyfill(); //allows smooth scrolling to function as usual in safari
    faqs.scrollIntoView({ behavior: 'smooth' });
  };

  const copyQuote = () => {
    const quoteUrl = window.location.href;
    navigator.clipboard.writeText(quoteUrl);
  };

  return(
    <div className='header-links'>
      <Button
        data-testid='headerLinks-button-faq'
        onClick={jumpToFAQs}
        variant='link'>
          FAQs
      </Button>
      <Button
        data-testid='headerLinks-button-copyQuote'
        onClick={copyQuote}
        variant='link'>
          Copy Quote Link
      </Button>
      {
        quoteData && shouldShowEmailButton ?
        <>
          <Button
            data-testid='headerLinks-button-emailQuote'
            onClick={openDialog}
            variant='link'>
              Email Quote
          </Button>
          <CustomDialog
            open={isDialogOpen}
            dialogContent={<EmailQuoteForm quoteData={quoteData} quoteUrl={window.location.href}/>}
            onClose={closeDialog}
            testId='headerLinks-dialog-emailQuote'
            title='Send Email to Admin'
          />
        </> : null }
    </div>
  );
};
