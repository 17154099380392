/* istanbul ignore file */
// react-pdf is expecting to run in a browser, and sends errors when run through Jest.
// The way to correct those errors is to mock react-pdf so far that unit tests lose value.
// This file/flow will need coverage either e2e or manually.
import React, { FC } from 'react';

import { Button } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';


export type DownloadPDFButtonProps = {
  buttonText: string,
  document: React.ReactElement,
  filename: string,
  testId: string,
};

export const DownloadPDFButton: FC<DownloadPDFButtonProps> = (props) => {
  const { buttonText, document, filename, testId } = props;

  return (
    <PDFDownloadLink document={document} fileName={filename}>
      {({ blob, url, loading, error }) => (loading ? '...' : <Button
        data-testid={testId}
        variant="action"
        startIcon={<SVGIn url={svgIn.download.url} />}
      >{buttonText}</Button>)}
    </PDFDownloadLink>
  );
};
