import React, { FC, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { validators } from 'pages/Wizard/Steps/Validators';
import { getSubscriptionEndDates, timestampToMDY } from 'shared/dates';


export interface SubscriptionPeriodStepProps extends BaseStepProps {
  submitQuoteInfo: () => void;
}

export const SubscriptionPeriodStep: FC<SubscriptionPeriodStepProps> = (props) => {

  //get options for end dates based on current year
  const { quoteInput, setQuoteInput, submitQuoteInfo } = props;
  const [loading, setLoading] = useState(false);

  //get options for end dates based on start date
  const startDate = quoteInput.startSubscription;
  const endDateOptions = getSubscriptionEndDates(startDate);
  const defaultDate = timestampToMDY(endDateOptions[0].getTime());
  const lastEndDateOption = timestampToMDY(endDateOptions[3].getTime());

  //set state value for user selected end date
  const [selectedEndDate, setSelectedEndDate] = useState(quoteInput.subscriptionPeriod || defaultDate);

  useEffect(() => {
    if (quoteInput.subscriptionPeriod === '') {
      setQuoteInput({
        ...quoteInput,
        subscriptionPeriod: defaultDate,
      });
    }
  });

  useEffect(() => {
    if(selectedEndDate < defaultDate){
      setSelectedEndDate(defaultDate);
      setQuoteInput({
        ...quoteInput,
        subscriptionPeriod: defaultDate,
      });
    }
  }, [defaultDate, selectedEndDate, quoteInput, setQuoteInput]);

  useEffect(() => {
    if(selectedEndDate > lastEndDateOption){
       setSelectedEndDate(lastEndDateOption);
       setQuoteInput({
        ...quoteInput,
        subscriptionPeriod: lastEndDateOption,
      });
     }
  }, [lastEndDateOption, selectedEndDate, quoteInput, setQuoteInput]);

  //generate the menu items from the dates array
  const menuItems = endDateOptions.map((endDate, index) => {
    const textDate = timestampToMDY(endDate.getTime());
    return <MenuItem data-testid={`subscriptionPeriodStep-li-${index}`} key={textDate} value={textDate}>{textDate}</MenuItem>;
  });

  function handleSelection(event: unknown){
    const changeEvent = event as React.ChangeEvent;
    const inputValue = (changeEvent.target as HTMLInputElement).value;
    setSelectedEndDate(inputValue);
    setQuoteInput({ ...quoteInput, subscriptionPeriod: inputValue });
  }

  return (
    <>
      <FormControl component='fieldset'>
        <FormLabel data-testid='subscriptionPeriodStep-label-text' style={{ alignSelf: 'flex-start' }}>
          End Date
          <SVGIn url={svgIn.info.url}/>
        </FormLabel>
        <Select
          variant='outlined'
          style={{ width: '15em' }}
          data-testid='subscriptionPeriodStep-select-dropdown'
          value={selectedEndDate}
          onChange={handleSelection}>
            {menuItems}
        </Select>
      </FormControl>
      <Button
        data-testid="subscriptionPeriodStep-button-submit"
        onClick={() => {
          submitQuoteInfo();
          setLoading(true);
        }}
        disabled={!validators.isValueSet(quoteInput.subscriptionPeriod) || loading}
        style={{ marginTop: '70px', width: 260 }}
      >{loading ? 'Processing...' : 'Get My Quote'}</Button>
    </>
  );
};
