import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { contact, link } from '@classkick/pkg-constants';

import { buildEmailLink, buildLinkWithText } from 'shared/links';
import 'pages/Quote/QFooter/QFooter.scss';


export const QFooter: FC = () => {

  return (
    <Grid
      container
      spacing={2}
      direction='row'
      justifyContent="space-evenly"
      className="q-footer"
    >
      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6" data-testid="q-footer-terms-title">Terms & Privacy</Typography>
        <Typography variant="body1">
          {buildLinkWithText(link.termsOfService, 'Terms of Service', 'q-footer-terms-link')}<br />
          {buildLinkWithText(link.coppaPrivacy, 'COPPA Privacy', 'q-footer-coppa-link')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6" data-testid="q-footer-forms-title">Forms</Typography>
        <Typography variant="body1">
          {buildLinkWithText(link.w9download, 'W9', 'q-footer-w9-link')}<br />
          {buildLinkWithText(link.soleSourceLetter, 'Sole Source Letter', 'q-footer-sole-source-letter-link')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6" data-testid="q-footer-contact-title">Contact</Typography>
        <Typography variant="body1">{buildEmailLink(contact.proEmail, 'q-footer-proEmail-link')}</Typography>
        <Typography variant="body1">Fax {contact.salesFax}</Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Typography variant="h6" data-testid="q-footer-address-title">Mailing Address</Typography>
        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
          { contact.remittanceAddress.join('\n') }
        </Typography>
      </Grid>
    </Grid>
  );
};
