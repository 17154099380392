import { FC } from 'react';

import { SVGIn } from '@classkick/pkg-svgin';

// This component is intended for aligning icons in MUI Buttons for the 'startIcon' prop.
// https://mui.com/components/buttons/#buttons-with-icons-and-label

export type IconInButtonProps = {
  className?: string;
  color: string;
  size?: number
  top?: number;
  url: string;
  testId: string;
};

export const IconInButton: FC<IconInButtonProps> = ({
  className,
  color,
  size = 32,
  top,
  url,
  testId,
}) => {
  return (
    <div data-testid={testId} className={className} style={{ fontSize: `${size}px`, position: 'relative', top: `${top}px` }}>
      <SVGIn url={url} color={color}/>
    </div>
  );
};
