export function decimalToIntPercent(decimal: number): number {
  const invalids = [null, undefined, NaN];
  if (invalids.includes(decimal)) { return 0; }
  if (decimal <= 0) { return 0; }
  if (decimal >= 1) { return 100; }
  return Math.round(decimal * 100);
}

export function stringToInt(string: string): number {
  return parseInt(string.replace(/[^0-9]/g, ''));
}

export function cleanClamp(decimal: number, min: number, max: number): number {
  const invalids = [null, undefined, NaN];
  if (invalids.includes(decimal)) { return min; }
  return Math.min(max, Math.max(min, decimal));
}

export function hasDecimal(value: number): boolean {
  return !(value === Math.round(value));
}

export function formatDiscount(discount: number): string {
  const amount: string = formatDollars(discount);
  return `-${amount}`;
}

export function formatDollars(price: number): string {
  const places: number = hasDecimal(price) ? 2 : 0;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: places,
    maximumFractionDigits: places,
  });
  return formatter.format(price);
}

// Lifted from https://stackoverflow.com/a/54520829
// in sumObjects<T> below, it restricts the numericProperty parameter to be
// any key "K" of T where T[K] is a number
export type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

export function sumObjects<T>(objects: T[], numericProperty: KeysMatching<T, number>): number {
  const count: number = objects.reduce<number>((total, item) => {
    return total + (item[numericProperty] as unknown as number);
  }, 0);
  return count;
}
