import React, { FC } from 'react';

import { Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import 'components/CustomDialog/CustomDialog.scss';
import { SxProps } from '@mui/system';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';


export interface CustomDialogProps {
  dialogContent:  React.ReactNode;
  open: boolean;
  onClose: () => void;
  testId: string;
  title?: string;
  overrideStyle?: SxProps<Theme>;
}

export const CustomDialog:FC<CustomDialogProps> = (props) => {
  const { title, open, dialogContent, onClose, testId, overrideStyle } = props;

  return(
    <>
      <Dialog
        className='customized-dialog'
        data-testid={testId}
        open={open}
        onClose={onClose}
        PaperProps={ overrideStyle ? { sx: overrideStyle } : { sx:{ overflowY: 'hidden', maxWidth: 'fit-content' } }}
      >
        <DialogTitle className='dialog-title' data-testid={`${testId}-title`}>
          {title}
          <IconButton
            aria-label='close'
            data-testid={testId+'-close-button'}
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 4,
            }}
          >
            <SVGIn url={svgIn.close.url} color={colors.brandGreen} emWidth={0.75} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className='dialog-content'
          data-testid={`${testId}-content`}
        >
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  );
};
