import React, { FC } from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { colors } from '@classkick/pkg-constants';

import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { TwoUp } from 'components/TwoUp/TwoUp';
import { HeaderLinks } from 'pages/Quote/HeaderLinks/HeaderLinks';

import { QuoteData } from 'pages/Quote/QuoteData';
import { RequestQuoteExtension } from 'pages/Quote/RequestQuoteExtension/RequestQuoteExtension';
import { formatDateSlashes } from 'shared/dates';
import { useModal } from 'shared/useModal/useModal';

import 'pages/Quote/QHead/QHead.scss';


export type QHeadProps = {
  quoteData: QuoteData,
  reload: () => void,
};

export const QHead: FC<QHeadProps> = (props) => {
  const { quoteData, reload } = props;
  const { quote_num, public_id, status, created_at, expired_at, num_extension, max_extension, quote_type } = quoteData;
  const { isDialogOpen, openDialog, closeDialog } = useModal();

  const getExtensionButtonText = () => {
    if (num_extension === 0) {
      return 'Request Extension';
    } else if (num_extension === 1) {
      return 'Request 2nd Extension';
    } else if (num_extension === 2) {
      return 'Request 3rd Extension';
    }
    return 'Need another extension?';
  };

  return (
    <div className="q-head">
      <div className="q-head-content">
        <TwoUp
          left={<Typography variant="h1" mb={2} mt={14.75}>Quote #{quote_num}</Typography>}
          right={ <HeaderLinks quoteData={quoteData}/> }
        />
        {
          // If the quote status is expired or expiration date has passed, render only the red expired/generated dates text
          status === 'expired' || expired_at < new Date(Date.now())
          ? <Typography variant="body1" sx={{ color: colors.alertRed, marginBottom: '22px' }} data-testid="expired-header-copy">
            Quote expired {formatDateSlashes(expired_at)}. Generated {formatDateSlashes(created_at)}.
          </Typography>
          // If the quote has not expired (open or invoiced status before expiration date), render the gray generated/expires dates text
          : <>
            <Typography variant="body1" sx={{ color: colors.mediumGray }} data-testid="active-header-copy">Quote generated {formatDateSlashes(created_at)}. Expires {formatDateSlashes(expired_at)}.</Typography>
            {
              // Only if the status is open (not invoiced), render the button to request an extension via modal
              status === 'open' && <>
                <Button
                  data-testid="request-extension-button"
                  variant="text"
                  onClick={openDialog}
                  sx={{
                    height: '16px',
                    justifyContent: 'start',
                    lineHeight: '16px',
                    margin: '4px 0 26px',
                    padding: '0',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getExtensionButtonText()}
                </Button>
                <CustomDialog
                  open={isDialogOpen}
                  dialogContent={
                    <RequestQuoteExtension
                      closeDialog={closeDialog}
                      currentExtension={num_extension}
                      maxExtensions={max_extension}
                      publicId={public_id}
                      quoteType={quote_type}
                      reload={reload}
                    />
                  }
                  onClose={closeDialog}
                  testId='request-extension-dialog'
                  title={num_extension === max_extension - 1 ? 'Request Final Extension' : 'Request Extension'}
                />
              </>
            }
          </>
        }
      </div>
    </div>
  );
};
