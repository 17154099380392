import { FC, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { AxiosResponse } from 'axios';

import { order } from 'api/order';
import { OrderCreated } from 'api/order/create';
import { APIResponse } from 'api/response';
import { LineItem } from 'components/LineItem/LineItem';
import { TwoUp } from 'components/TwoUp/TwoUp';
import { QuoteStatus } from 'pages/Wizard/QuoteInput';
import { formatDollars } from 'shared/numbers';
import { useModal } from 'shared/useModal/useModal';
import { useQuote } from 'shared/useQuote/useQuote';
import { QuoteLineItem } from 'types/QuoteLineItem';

import 'pages/Renewal/Renewal.scss';


export const Renewal: FC = () => {
    const [confirmState, setConfirmState] = useState(false);
    const { quoteData } = useQuote();
    const [ inFlight, setInFlight ] = useState<boolean>(false);
    const { openDialog: openErrorDialog } = useModal();
    // We're not requiring any user input, so we'll add these as placeholders
    const checkoutData = {
        poNumber: 'Classkick',
        purchaser: {
            agentName: 'Classkick',
            agentEmail: 'pro@classkick.com',
            adminEmail: 'pro@classkick.com',
        },
    };

    const submitPurchaseOrder = () => {
        if (quoteData) {
            setInFlight(true);
            order.create(quoteData.quote_type, quoteData.public_id, checkoutData).then(
                (response: AxiosResponse<APIResponse<OrderCreated>>) => {
                    setConfirmState(true);
            }, (error) => {
                openErrorDialog();
            });
        }
    };

    let orgName, price, quoteLineItems: QuoteLineItem[], lineItems, numberOfSchools: number;
    let quoteStatus: QuoteStatus = 'open';
    if (quoteData) {
        orgName = quoteData.district?.institution_name || quoteData.schools[0].institution_name;
        price = formatDollars(quoteData.price/100);
        quoteLineItems = quoteData.quote_summary_items;
        numberOfSchools = quoteData.schools.length;
        lineItems = quoteLineItems.map((quoteLineItem, index) => {
            return <LineItem
            key={`item-${index}`}
            testId={`qSummary-lineItem-${index}`}
            numberOfSchools={numberOfSchools}
            quoteLineItem={quoteLineItem}
            quoteLineItems={quoteLineItems}
            />;
        });
        quoteStatus = quoteData.status;
    }

    if (confirmState || quoteStatus === 'invoiced') {
        return (
            <div className="renewal">
                <h1>Thank you!</h1>
                <p>Your teachers now have access to Classkick Pro. Thank you for helping teachers be awesome!</p>
            </div>
        );
    } else {
        return (
            <div className="renewal">
                <h1>Congratulations!</h1>
                <p>You are one step away from renewing Classkick Pro access for your teachers for the 22-23 school year. Please review the subscription pricing listed below and click “confirm” to turn on Classkick Pro for your teachers today!</p>
                <h5>{orgName}</h5>
                <Card className="q-summary" data-testid="quote-summary-card">
                <CardContent>
                    <TwoUp
                    left={
                        <>
                        <Typography variant="h4">Quote Summary</Typography>
                        <Typography variant="h1">{price}</Typography>
                        </>
                    }
                    right={<></>}
                    />
                    <Typography variant="body1" component="div" className="quote-line-items" data-testid='qSummary-twoUp-parent'>
                    {lineItems}
                    </Typography>
                    </CardContent>
                    </Card>
                    <div className="button-container">
                        <Button className="confirm-button"
                        onClick={() => submitPurchaseOrder()}
                        disabled={inFlight}>
                        { inFlight ? 'Processing' : 'Confirm' }
                        </Button>
                    </div>
                    <p className="fine-print">Your confirmation acknowledges the quoted price above and will initiate the invoicing process.</p>
            </div>
        );
    }
};