import { cancel } from 'api/quote/cancel';
import { create } from 'api/quote/create';
import { extend } from 'api/quote/extend';


export const quote = {
  cancel,
  create,
  extend,
};
