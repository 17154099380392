import React, { FC } from 'react';

import Grid from '@mui/material/Grid';


export type TwoUpProps = {
  left: React.ReactNode,
  right: React.ReactNode,
};

export const TwoUp: FC<TwoUpProps> = (props) => {
  const { left, right } = props;

  return (
    <Grid container justifyContent="space-between">
      <Grid item data-testid='twoUp-item-left'>{left}</Grid>
      <Grid item data-testid='twoUp-item-right'>{right}</Grid>
    </Grid>
  );
};
