import { FC } from 'react';

import { Typography } from '@mui/material';

import { contact } from '@classkick/pkg-constants';

import { buildEmailLink } from 'shared/links';


export const Congrats: FC = (props) => {

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h3" component="h1" sx={{ mt: 14 }}>Congrats, you've got Pro!</Typography>
      <Typography variant="body1">You've successfully purchased a Pro Subscription!</Typography>
      <Typography variant="body1">Please check the email of your Classkick Admin for onboarding instructions.</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>If you have any further questions, please reach out to {buildEmailLink(contact.proEmail, 'congrats-proEmail-link')}.</Typography>
    </div>
  );
};
