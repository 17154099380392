import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Typography } from '@mui/material';

import { AxiosResponse } from 'axios';

import { order } from 'api/order';
import { OrderCreated } from 'api/order/create';
import { APIResponse } from 'api/response';
import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { POCheckoutData } from 'pages/Checkout/Checkout';
import { CheckoutCard } from 'pages/Checkout/CheckoutCard/CheckoutCard';
import { InfoPO } from 'pages/Checkout/InfoPO/InfoPO';
import { NextStepsPO } from 'pages/Checkout/NextStepsPO/NextStepsPO';
import { PurchaseOrderError } from 'pages/Checkout/PurchaseOrder/PurchaseOrderError';
import { Purchaser } from 'pages/Checkout/Purchaser/Purchaser';
import { QuoteStatus } from 'pages/Checkout/QuoteStatus/QuoteStatus';
import { ShoppingCart } from 'pages/Checkout/ShoppingCart/ShoppingCart';
import { Summary } from 'pages/Checkout/Summary/Summary';
import { useModal } from 'shared/useModal/useModal';
import { useQuote } from 'shared/useQuote/useQuote';


export const PurchaseOrder: FC = (props) => {
  const { quoteData } = useQuote();
  const [ checkoutData, setCheckoutData ] = useState<POCheckoutData>({
    poNumber: '',
    purchaser: {
      agentName: '',
      agentEmail: '',
      adminEmail: '',
    },
  });
  const [ isFormReady, setIsFormReady ] = useState<boolean>(false);
  const [ inFlight, setInFlight ] = useState<boolean>(false);
  const { isDialogOpen: isErrorDialogOpen, openDialog: openErrorDialog, closeDialog: closeErrorDialog } = useModal();
  const navigate = useNavigate();

  const notifyPurchaserValidity = (valid: boolean): void => {
    setIsFormReady(valid);
  };

  const submitPurchaseOrder = () => {
    if (quoteData) {
      setInFlight(true);
      order.create(quoteData.quote_type, quoteData.public_id, checkoutData).then(
        (response: AxiosResponse<APIResponse<OrderCreated>>) => {
          navigate(`/quote/${quoteData.public_id}/congratulations`);
      }, (error) => {
        openErrorDialog();
      });
    }
  };

  if (quoteData) {
    if (quoteData.status === 'open') {
      return (
        <div className="sticky-page">

          <div className="sticky-content">
            <Typography variant="h2" mb={4} mt={3.375} data-testid="purchase-order-content">{`Checkout Quote #${quoteData.quote_num}`}</Typography>

            <CheckoutCard className="sticky-sticky" title="Order Summary" titleSpacing={0} testId="poCheckout-card-summaryCard">
              <Summary quote={quoteData} />
            </CheckoutCard>

            <CheckoutCard className="sticky-card" title="Shopping Cart" testId="poCheckout-card-shoppingCart">
              <ShoppingCart quote={quoteData} />
            </CheckoutCard>

            <CheckoutCard className="sticky-card" title="Purchaser" testId="poCheckout-card-purchaser">
              <Purchaser<POCheckoutData>
                checkoutData={checkoutData}
                setCheckoutData={setCheckoutData}
                notifyValid={notifyPurchaserValidity}
              />
            </CheckoutCard>

            <CheckoutCard className="sticky-card" title="PO Information" testId="poCheckout-card-poInfo">
              <InfoPO checkoutData={checkoutData} setCheckoutData={setCheckoutData} />
            </CheckoutCard>

            <CheckoutCard className="sticky-card" title="Next Steps" testId="poCheckout-card-nextSteps">
              <NextStepsPO quote={quoteData} />
            </CheckoutCard>

            <CheckoutCard
              className="sticky-card"
              title=""
              testId="poCheckout-card-submit"
              titleSpacing={0}
              justifyContent="flex-end">
              <Button
                disabled={!isFormReady || inFlight}
                data-testid="poCheckout-button-submit"
                onClick={() => submitPurchaseOrder()}>
                  { inFlight ? 'Processing' : 'Submit' }
              </Button>
            </CheckoutCard>
          </div>
          <CustomDialog
            open={isErrorDialogOpen}
            dialogContent={<PurchaseOrderError checkoutData={checkoutData} publicId={quoteData.public_id} quoteType={quoteData.quote_type} />}
            onClose={closeErrorDialog}
            testId='po-error-dialog'
            title='Checkout Error'
          />
        </div>
      );
    } else {
      return <QuoteStatus quoteData={quoteData} />;
    }
  }
  return (
    <LoadingSpinner testLocation="po" />
  );
};
