import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { sticker, svgArt } from '@classkick/pkg-constants';

import { BenefitsCard } from 'pages/Lander/Benefits/BenefitsCard/BenefitsCard';


export const Benefits: FC = () => {
  return (
    <section data-testid="landing-block-benefits">
      <Typography variant="h2" data-testid="landing-title-benefits" align="center" mb={12} mt={14.875}>Benefits of Pro</Typography>

      <Box sx={{
        margin: { xs: 0, lg: '0 auto' },
        maxWidth: { xs: '100%', lg: 1130 },
        padding: { xs: '0 20px', sm: '0 75px', lg: 0 },
      }}>
        <BenefitsCard
          title="Real time monitoring and feedback"
          text="See all your students working live and help those who need it."
          image={svgArt.realtime}
          sticker={sticker.trophy}
          testDescriptor="feedback" />

        <BenefitsCard
          title="Portfolios of all student work"
          text="Students get a dashboard of all of their assignments, no class codes required."
          image={svgArt.assignments}
          sticker={sticker.lightbulb}
          stickerPos="tl"
          reverseOrder
          testDescriptor="portfolios" />

        <BenefitsCard
          title="Unlimited interactive assignments"
          text="Teachers can easily upload PDFs, make their own assignments, and access templates from our library."
          image={svgArt.gato}
          sticker={sticker.rocketship}
          testDescriptor="unlimited" />

        <BenefitsCard
          title="Single sign on integration"
          text="One click sign on for students and teachers that work with all of your favorite platforms."
          image={svgArt.integrations}
          halfSize
          reverseOrder
          testDescriptor="sso" />
      </Box>
    </section>
  );
};
