import axios, { AxiosResponse } from 'axios';

import { buildEndpoint } from 'api/endpoints';
import { APIResponse } from 'api/response';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';


export interface QuoteCanceled {
  quote_id: string;
}

export const cancel = (quoteType: SubscriptionType, publicId: string): Promise<AxiosResponse<APIResponse<QuoteCanceled>>> => {
  const endpoint = buildEndpoint.cancelQuote(quoteType, publicId);
  return axios.put<APIResponse<QuoteCanceled>>(endpoint);
};
