/* istanbul ignore file */
import { createTheme } from '@mui/material/styles';

import { colors } from '@classkick/pkg-constants';
import type {} from '@mui/lab/themeAugmentation';


declare module '@mui/material/styles' {
  interface TypographyVariants {
    sbFinePrint: React.CSSProperties;
    sbFormDescription: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sbFinePrint?: React.CSSProperties;
    sbFormDescription?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    sbFinePrint: true;
    sbFormDescription: true;
  }
}

// Update the Button's available variants
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true;
    link: true;
    navigation: true;
    greenLink: true;
  }
}


export const theme = createTheme({
  palette: {
    primary: {
      main: colors.brandGreen,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.brandGray,
    },
  },

  typography: {
    fontFamily: [
      'Gotham Rounded',
      'Varela Round',
      'sans-serif',
    ].join(','),
    h1: {
      color: colors.charcoalGray,
      fontSize: '40px',
      fontWeight: 500,
      lineHeight: '48px',
    },
    h2: {
      color: colors.charcoalGray,
      fontSize: '32px',
      fontWeight: 500,
    },
    h3: {
      color: colors.charcoalGray,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '28.8px',
      marginTop: '32px',
      marginBottom: '32px',
    },
    h4: {
      color: colors.charcoalGray,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '30px',
      marginBottom: '16px',
      marginTop: 0,
    },
    h5: {
      color: colors.charcoalGray,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
      marginBottom: '8px',
      marginTop: '32px',
    },
    h6: {
      color: colors.charcoalGray,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '32px',
      marginBottom: '21px',
    },
    subtitle1: {
      color: colors.mediumGray,
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '32px',
    },
    subtitle2: {
      color: colors.charcoalGray,
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
      marginBottom: '8px',
    },
    body1: {
      color: colors.charcoalGray,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      color: colors.mediumGray,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      marginTop: '8px',
    },
    sbFinePrint: {
      color: colors.alertRed,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    sbFormDescription: {
      color: colors.charcoalGray,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: `1px solid ${colors.lightGray}`,
          borderWidth: '0 0 1px',
          margin: '0',
          '&$expanded': {
            margin: '0',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 40px 0 0',
        },
        content: {
          margin: '0 0 24px',
          '&$expanded': {
            margin: '0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides:{
        root: {
          padding: '0 60px 0 0',
          marginBottom: '32px',
        },
      },
    },
    MuiButton: {
      styleOverrides:{
        root: {
          borderRadius: '1.5em',
          weight: '500',
          lineHeight: '1em',
          padding: '1em 4em',
          width: '160px',
          height: '48px',
          fontSize: '16px',
        },
      },
      defaultProps: {
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'action' },
          style: {
            backgroundColor: colors.white,
            color: colors.brandGreen,
            fontSize: '16px',
            fontWeight: 400,
            textTransform: 'none',
            width: 'auto',
            padding: '0 16px',
            '&:hover': {
              backgroundColor: colors.hoverGray,
            },
            '& .svg-in': {
              top: '0',
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            backgroundColor: colors.white,
            color: colors.charcoalGray,
            fontSize: '16px',
            fontWeight: 500,
            textDecoration: 'underline',
            textTransform: 'none',
            width: 'auto',
            padding: '0 16px',
          },
        },
        {
          props: { variant: 'navigation' },
          style: {
            backgroundColor: colors.white,
            color: colors.charcoalGray,
            fontSize: '14px',
            fontWeight: 500,
            a: {
              color: colors.charcoalGray,
              underline: 'none',
              textColor: colors.charcoalGray,
            },
            textDecoration: 'none',
            textTransform: 'none',
            width: 'auto',
            padding: '0 16px',
          },
        },
        {
        props: { variant: 'greenLink' },
          style: {
            backgroundColor: 'transparent',
            color: colors.brandGreen,
            fontSize: '16px',
            fontWeight: 500,
            height: '24px',
            padding: '0',
            textTransform: 'none',
            width: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors.hoverGreen,
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiCardContent: {
      styleOverrides:{
        root: {
          padding: '24px',
          '&:last-child': {
            // MUI sets last-child padding by default,
            // and it overrides any specifics in the actual components.
            // To get the spacing we want, we zero it out here for CardContent,
            // and add an explicit padding-bottom on Card as needed.
            paddingBottom: 0,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiFormControlLabel: {
      styleOverrides:{
        label: {
          fontSize: '16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides:{
        root: {
          fontSize: '12px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides:{
        root: {
          fontSize: '12px',
          paddingBottom: '.5em',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: '32px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      defaultProps: {
        color: 'primary',
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.charcoalGray,
        },
       },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiRadio: {
      styleOverrides:{
        root: {
          color: colors.brandGray,
          '& .MuiSvgIcon-root': {
            height: '1.8em',
            width: '1.8em',
          },
        },
        colorSecondary: {
          '&$checked': {
            color: colors.brandGreen,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides:{
        root: {
          '& .MuiInputBase-input': {
            fontSize: '16px',
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          disableAnimation: true,
        },
      },
    },
    MuiTooltip: {
      styleOverrides:{
        tooltip: {
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          width: '290px',
          '& button': {
            backgroundColor: colors.white,
            border: 'none',
            borderRadius: 0,
            color: colors.charcoalGray,
            margin: 0,
            padding: '33px 0',
          },
          '& button.Mui-selected': {
            backgroundColor: colors.brandGreen,
            color: colors.white,
          },
          '& button.MuiTypography-root:disabled.PrivatePickersMonth-root ': {
            color: colors.lightGray,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          padding: 10,
          width: 330,
          '& .PrivatePickersFadeTransitionGroup-root': {
            color: colors.charcoalGray,
            fontWeight: 400,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: '0px 18px 18px',
          justifyContent: 'start',

          '& .PrivatePickersYear-yearButton': {
            borderRadius: 0,
            height: '4em',
            width: '8em',
            color: colors.charcoalGray,
          },
          '& .PrivatePickersYear-yearButton.Mui-selected': {
            color: colors.white,
          },
        },
      },
    },
  },
});
