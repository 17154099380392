import React, { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { LineItem } from 'components/LineItem/LineItem';
import { TwoUp } from 'components/TwoUp/TwoUp';
import { CancelQuote } from 'pages/Quote/CancelQuote/CancelQuote';
import { GenerateNewQuote } from 'pages/Quote/GenerateNewQuote/GenerateNewQuote';
import { DownloadPDFButton } from 'pages/Quote/PDF/DownloadPDFButton';
import { QuotePDF } from 'pages/Quote/PDF/QuotePDF';
import { PaymentDetail } from 'pages/Quote/Payment/PaymentDetail';
import { QuoteData } from 'pages/Quote/QuoteData';
import { QuoteStatus } from 'pages/Wizard/QuoteInput';
import { formatDiscount, formatDollars } from 'shared/numbers';
import { paymentURLFor } from 'shared/strings';
import { useFlag } from 'shared/useFlag/useFlag';
import { useModal } from 'shared/useModal/useModal';

import 'pages/Quote/QSummary/QSummary.scss';


export type QSummaryProps = {
  quote: QuoteData,
  reload: () => void,
  showPurchase: boolean,
};

export const QSummary: FC<QSummaryProps> = (props) => {
  const { quote, reload, showPurchase } = props;
  const { isDialogOpen: isPaymentDialogOpen, openDialog: openPaymentDialog, closeDialog: closePaymentDialog } = useModal();
  const { isDialogOpen: isCancelDialogOpen, openDialog: openCancelDialog, closeDialog: closeCancelDialog } = useModal();
  // TODO: Remove no-unused-vars exception as part of SD-432 (https://getclasskick.atlassian.net/browse/SD-432)
  // openNewQuoteDialog is unused because the code that ran it is commented out
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isDialogOpen: isNewQuoteDialogOpen, openDialog: openNewQuoteDialog, closeDialog: closeNewQuoteDialog } = useModal();
  const numberOfSchools = quote.schools.length;
  const status = quote.status;
  const expires = quote.expired_at;
  const [updatedStatus, setUpdatedStatus] = useState<QuoteStatus>(status);
  const isCCActive = useFlag('sb_checkout_cc');

  useEffect(() => {
    if (status === 'open') {
      if (expires < new Date(Date.now())) {
        setUpdatedStatus('expired');
      }
    }
  }, [status, expires]);

  const quoteLineItems = quote.quote_summary_items;
  const lineItems = quoteLineItems.map((quoteLineItem, index) => {
    return <LineItem
      key={`item-${index}`}
      testId={`qSummary-lineItem-${index}`}
      numberOfSchools={numberOfSchools}
      quoteLineItem={quoteLineItem}
      quoteLineItems={quoteLineItems}
    />;
  });

  let quoteDiscounts:unknown = [];
  if(quote.discounts?.length !== 0) {
   quoteDiscounts = quote.discounts.map(discount => {
     return (
      <div key={discount.name}>
        <hr />
        <TwoUp
          left={discount.name}
          right={formatDiscount(discount.amount)}
        />
        {discount.description &&
          <Typography variant='body2' className='discount-desc-line' sx={{ marginTop: 0 }}>
            {discount.description}
          </Typography>
        }
      </div>
     );
   });
  }

  let purchaseButton: React.ReactElement | null = isCCActive ?
    <Button
      className="purchase-button"
      data-testid='quote-button-openPayment'
      onClick={openPaymentDialog}
      sx={{ textTransform: 'none' }}
    >Purchase</Button>
    :
    <Button
      component={Link}
      to={paymentURLFor(quote, 'purchase-order')}
      className="purchase-button"
      data-testid='quote-button-poCheckout'
      sx={{ textTransform: 'none' }}
    >Purchase</Button>;

  if (!showPurchase) {
    purchaseButton = null;
  }

  return (
    <Card className="q-summary sticky-sticky" data-testid="quote-summary-card">
      <CardContent>
        <TwoUp
          left={
            <>
              <Typography variant="h4">Quote Summary</Typography>
              <Typography variant="h1">{formatDollars(quote.price/100)}</Typography>
            </>
          }
          right={<></>}
        />
        <Typography variant="body1" component="div" className="quote-line-items" data-testid='qSummary-twoUp-parent'>
          {lineItems}
          {quoteDiscounts}
        </Typography>
        {
          updatedStatus === 'open' && purchaseButton
        }
        {
          updatedStatus === 'expired' &&
          // TODO: Remove marginBottom as part of SD-432 (https://getclasskick.atlassian.net/browse/SD-432)
          <Button className="purchase-button" data-testid='quote-purchase-button-expired' disabled sx={{ marginBottom: '24px', textTransform: 'none' }}>Quote Expired</Button>
        }
      </CardContent>
      {
        updatedStatus === 'open' &&
        <CardActions>
          <TwoUp
          left={<DownloadPDFButton
            buttonText="Download PDF"
            testId="qSummary-downloadPDF-button"
            document={<QuotePDF quote={quote} />}
            filename={`Classkick_Quote_${quote.quote_num}.pdf`}
          />}
          right={<Button variant="action" data-testid='cancel-quote-button' onClick={openCancelDialog}>Cancel Quote</Button>}/>
        </CardActions>
      }
      {
        // TODO: Add back as part of SD-432 (https://getclasskick.atlassian.net/browse/SD-432)
        // updatedStatus === 'expired' &&
        // <div className="new-quote-button" data-testid='new-quote-button' onClick={openNewQuoteDialog}><Button variant="action">Generate New Quote</Button></div>
      }
      <CustomDialog
        open={isPaymentDialogOpen}
        dialogContent={<PaymentDetail
          location='payDialog'
          poPaymentURL={paymentURLFor(quote, 'purchase-order')}
          ccPaymentURL={paymentURLFor(quote, 'credit-card')}
        />}
        onClose={closePaymentDialog}
        testId='quote-dialog-payment'
      >
      </CustomDialog>
      <CustomDialog
        open={isCancelDialogOpen}
        dialogContent={<CancelQuote publicId={quote.public_id} quoteType={quote.quote_type} reload={reload} />}
        onClose={closeCancelDialog}
        testId='cancel-quote-dialog'
        title='Cancel Quote'
      >
      </CustomDialog>
      <CustomDialog
        open={isNewQuoteDialogOpen}
        dialogContent={<GenerateNewQuote quote={quote} />}
        onClose={closeNewQuoteDialog}
        testId='new-quote-dialog'
        title='Generate New Quote'
      >
      </CustomDialog>
    </Card>
  );
};
