/* istanbul ignore file */
// Test Coverage for this component is handled by cucumber, if that changes, remove ln 1.
import React, { FC } from 'react';

import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { Header } from 'components/Header/Header';
import { PostHog } from 'components/PostHog/PostHog';
import { routes } from 'config/routes';


export const App: FC<Record<string, never>> = () => {
  return(
    <Router>
      <PostHog/>
      <Routes>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <>
                  <Header rightSlot={route.headerSlot} />
                  <route.component
                  {...route.props}
                  />
                  </>
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
};
