export type StepName = 'creator' |
                       'subscriptionType'    |
                       'numberOfSchools' |
                       'contactUs'       |
                       'chooseDistrict'  |
                       'chooseIndySchool' |
                       'chooseSchools'   |
                       'startSubscription'   |
                       'subscriptionPeriod'  |
                       'submitError' |
                       'unknownSchool' |
                       'fakeStep';

export type Direction = 'forward' | 'back';

export const progress: Record<StepName, number> = {
  creator: 0,
  subscriptionType: 0.2,
  numberOfSchools: 0.3,
  contactUs: 1,
  chooseDistrict: 0.4,
  chooseIndySchool: 0.6,
  chooseSchools: 0.6,
  startSubscription: 0.8,
  subscriptionPeriod: 0.9,
  submitError: 0.9,
  unknownSchool: 1,
  fakeStep: 0.42,
};
