import { FC } from 'react';

import 'components/BackgroundCircle/BackgroundCircle.scss';


export type BackgroundCircleProps = {
  size?: number;
  bgColor?: string;
};

export const BackgroundCircle: FC<BackgroundCircleProps> = ({
  children,
  size = 125,
  bgColor = '#f8fbe7',
}) => {
  return <div
    className='background-circle'
    data-testid='background-circle-div'
    style={{
      backgroundColor: bgColor,
      fontSize: size * 0.664,
      height: size,
      width: size,
    }}
  >
    {children}
  </div>;
};
