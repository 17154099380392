import { FC } from 'react';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import { Organization } from 'types/Organization';

import 'pages/Wizard/Steps/SchoolInfoStep/SchoolInfoStep.scss';


export interface SchoolCardProps {
  onDelete: (school: Organization) => void;
  school: Organization;
}


export const SchoolCard: FC<SchoolCardProps> = (props) => {
  const { onDelete, school } = props;
  return (
    <div key={school.pid} className='school-card' data-testid={`schoolInfoStep-card-${school.pid}`}>
      <div className='school-info'>
        <div className='school-name'>{school.institution_name}</div>
        <div className='school-address'>{school.address}</div>
        <div className='school-address'>{school.city}, {school.state} {school.zipcode}, {school.country}</div>
      </div>
      <div className='delete-button' data-testid='delete-button' onClick={() => onDelete(school)}>
        <SVGIn url={svgIn.close.url} color={colors.brandGreen} />
      </div>
    </div>
  );
};
