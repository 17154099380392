import { ReactElement, useEffect } from 'react';

import { endpoints } from 'api/endpoints';
import { OrderValidatePayload, OrderValidateResponse } from 'api/order/validate';
import { Kickput } from 'components/Kickput/Kickput';
import { CheckoutData, PurchaserInfo } from 'pages/Checkout/Checkout';
import { validators } from 'pages/Wizard/Steps/Validators';
import { ErrorHook, useError } from 'shared/useError/useError';
import { isAdminValid } from 'shared/useExternalValidator/isAdminValid';
import { EVStatus, useExternalValidator } from 'shared/useExternalValidator/useExternalValidator';
import { useFlag } from 'shared/useFlag/useFlag';


export type PurchaserProps<T extends CheckoutData> = {
  checkoutData:T,
  setCheckoutData: (data: T) => void,
  notifyValid: (valid: boolean) => void,
};

export const statusMessage = (status: EVStatus, adminError: ErrorHook<string>, isValidationActive: boolean | null): string => {
  // email format error takes precedence
  if (adminError.error) {
    return adminError.message;
  }

  if (isValidationActive) {
    // validation flag is on : use status
    if (status.state === 'fresh') {
      return status.valid ?
        'Looks good!' :
        'There is no Classkick account associated with this email.';
    }
    if (status.state === 'stale') {
      return status.activity === 'idle' ?
        'Click out to check your entry.' :
        'Checking...';
    }
  } else {
    // validation flag is off : use adminError
    return adminError.message;
  }
  return '';
};

export function Purchaser<T extends CheckoutData> (props:PurchaserProps<T>):ReactElement {
  const { checkoutData, setCheckoutData, notifyValid } = props;
  const isValidationActive = useFlag('sb_checkout_validation');
  const externalValidation = useExternalValidator<OrderValidatePayload, OrderValidateResponse>(endpoints.orderValidate, isAdminValid);
  const nameError = useError<string>(checkoutData.purchaser.agentName, validators.isValueSet);
  const agentError = useError<string>(checkoutData.purchaser.agentEmail, validators.isEmailValid);
  const adminError = useError<string>(checkoutData.purchaser.adminEmail, validators.isEmailValid);

  useEffect(() => {
    // Using the verbose `=== false` and `=== true` because
    // values could be null (when the field is first initialized or being edited)
    notifyValid(
      nameError.error === false &&
      agentError.error === false &&
      adminError.error === false &&
      (externalValidation.status.valid === true || !isValidationActive));
  }, [nameError, agentError, adminError, externalValidation, isValidationActive, notifyValid]);

  const updateData = (value: string, dataKey: keyof PurchaserInfo) => {
    const updatedData: PurchaserInfo = checkoutData.purchaser;
    updatedData[dataKey] = value;
    if (dataKey === 'adminEmail') { externalValidation.notifyChange(); }
    setCheckoutData({
      ...checkoutData,
      purchaser: updatedData,
    });
  };

  const onNameBlur = () => {
    nameError.check(
      checkoutData.purchaser.agentName,
      'Pleased to meet you!',
      'Please give us your name.');
  };

  const onAgentBlur = () => {
    agentError.check(
      checkoutData.purchaser.agentEmail,
      'Looks good!',
      'Please enter a valid email.');
  };

  const onAdminBlur = (value: string) => {
    const isEmailFormatOK = adminError.check(
      value,
      'Looks good!',
      'Please enter a valid email.');

    if (isEmailFormatOK && isValidationActive) {
      externalValidation.seek({
        email: value,
      });
    }
  };

  return (
    <>
      <Kickput<PurchaserInfo>
        dataKey="agentName"
        label="Purchasing Agent Name"
        placeholder="John Doe"
        updateData={updateData}
        wholeData={checkoutData.purchaser}
        testId="checkout-input-agentName"
        onBlur={onNameBlur}
        error={nameError.error || false}
        helperText={nameError.message}
      />
      <Kickput<PurchaserInfo>
        dataKey="agentEmail"
        label="Purchasing Agent Email"
        placeholder="you@school.edu"
        updateData={updateData}
        wholeData={checkoutData.purchaser}
        testId="checkout-input-agentEmail"
        description='This person will receive the invoice for this purchase.'
        onBlur={onAgentBlur}
        error={agentError.error || false}
        helperText={agentError.message}
      />
      <Kickput<PurchaserInfo>
        dataKey="adminEmail"
        label="Classkick Admin Email"
        placeholder="you@school.edu"
        updateData={updateData}
        wholeData={checkoutData.purchaser}
        testId="checkout-input-adminEmail"
        description='This person will be your Classkick admin and must have a Classkick account.'
        onBlur={onAdminBlur}
        error={adminError.error || externalValidation.status.valid === false}
        helperText={statusMessage(externalValidation.status, adminError, isValidationActive)}
      />
    </>
  );
}
