export interface Feature {
  name: string;
  proDistrict: boolean;
  proDistrictDescription?: string;
  proSchool: boolean;
  proSchoolDescription?: string;
  proTeacher: boolean;
  proTeacherDescription?: string;
  basic: boolean;
  basicDescription?: string;
}

export const features: Feature[] = [
  {
    name: 'Multi-School Discount',
    proDistrict: true,
    proSchool: false,
    proTeacher: false,
    basic: false,
  },
  {
    name: 'Implementation Support (PD, Onboarding, etc.)',
    proDistrict: true,
    proSchool: false,
    proTeacher: false,
    basic: false,
  },
  {
    name: 'Dedicated District Account Manager',
    proDistrict: true,
    proSchool: false,
    proTeacher: false,
    basic: false,
  },
  {
    name: 'Standard Customer Support',
    proDistrict: true,
    proSchool: true,
    proTeacher: true,
    basic: true,
  },
  {
    name: 'Assignments per Teacher',
    proDistrict: true,
    proDistrictDescription: 'Unlimited',
    proSchool: true,
    proSchoolDescription: 'Unlimited',
    proTeacher: true,
    proTeacherDescription: 'Unlimited',
    basic: true,
    basicDescription: '20',
  },
  {
    name: 'Teacher Account Limit',
    proDistrict: true,
    proDistrictDescription: 'Unlimited',
    proSchool: true,
    proSchoolDescription: 'Unlimited',
    proTeacher: true,
    proTeacherDescription: '1',
    basic: true,
    basicDescription: '1',
  },
  {
    name: 'Student Account Limit',
    proDistrict: true,
    proDistrictDescription: 'Unlimited',
    proSchool: true,
    proSchoolDescription: 'Unlimited',
    proTeacher: true,
    proTeacherDescription: 'Up to 100',
    basic: true,
    basicDescription: 'Up to 100',
  },
  {
    name: 'Student Portfolios',
    proDistrict: true,
    proSchool: true,
    proTeacher: true,
    basic: false,
  },
  {
    name: 'Single Sign On Integration',
    proDistrict: true,
    proDistrictDescription: 'Additional Clever integrations may be available',
    proSchool: true,
    proTeacher: true,
    basic: false,
  },
  {
    name: 'Folders',
    proDistrict: true,
    proSchool: true,
    proTeacher: true,
    basic: false,
  },
  {
    name: 'Export Grades',
    proDistrict: true,
    proSchool: true,
    proTeacher: true,
    basic: false,
  },
  {
    name: 'Export Student Work to PDF',
    proDistrict: true,
    proSchool: true,
    proTeacher: true,
    basic: false,
  },
  {
    name: 'Fill-in-the-Blank',
    proDistrict: true,
    proSchool: true,
    proTeacher: true,
    basic: true,
  },
];
