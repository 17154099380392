import React, { FC } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { colors } from '@classkick/pkg-constants';

import { IconInCircle } from 'components/IconInCircle/IconInCircle';

import 'pages/Quote/QCard/QCard.scss';


export type QCardProps = {
  testId: string,
  description: string,
  iconURL: string,
  title: string,
};

export const QCard: FC<QCardProps> = (props) => {
  const { description, iconURL, title, testId } = props;
  return (
    <Card data-testid={testId} className="q-card sticky-card" sx={{ pb: 3 }}>
      <CardContent>
        <div className="q-card-icon">
          <IconInCircle url={iconURL} color={colors.brandGreen} size={56} testId={`${testId}-icon`}/>
        </div>
        <div className="q-card-content">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="subtitle1" data-testid={`${testId}-description`}>{description}</Typography>
          {props.children}
        </div>
      </CardContent>
    </Card>
  );
};
