import React, { FC, useState } from 'react';

import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StandardTextFieldProps, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';

import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';


export const StartSubscriptionStep: FC<BaseStepProps> = (props) => {
  const { quoteInput, setQuoteInput } = props;
  const [startSubscriptionDate, setStartSubscriptionDate] = useState<Date | null>(quoteInput.startSubscription);
  const [showDateHint, setShowDateHint] = useState<boolean>(false);
  const currentDate = new Date(Date.now());
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const getMinDate = ():Date => {
    return moment(currentDate) as unknown as Date;
  };

  const getMaxDate = ():Date => {
    // We want to allow users to select a date up to 12 months out, so the furthest date out would be the month prior to the current month of the following year
    // Set default to December of current year when current month is January (0-index)
    let maxMonth = 11;
    let maxYear = currentYear;
    // For February - December, set max month to previous month of next year
    if(currentMonth > 0){
      maxMonth = currentMonth - 1;
      maxYear = maxYear + 1;
    }
    return moment(new Date(maxYear, maxMonth, 1)) as unknown as Date;
  };

  function handleSelection(newValue: Date|null){
    // The if is only present to satisfy TypeScript.
    // There is no case in our usage of DatePicker where newValue will be null.
    // As such:
    /* istanbul ignore else */
    if(newValue){
      setShowDateHint(false);
      const newSubscriptionStartDate = moment(newValue).toDate();
      const newMonth = newSubscriptionStartDate.getMonth();
      const newYear = newSubscriptionStartDate.getFullYear();
      if (newMonth === currentMonth && newYear === currentYear) {
        // If current month was not the default (March - June) but is selected,
        // we need to set startSubscriptionDate to the actual current date vs the first of the month so the selection is valid in the picker
        setStartSubscriptionDate(currentDate);
      } else {
        setStartSubscriptionDate(newValue);
      }
      const startSubscription = new Date(newYear, newMonth);
      setQuoteInput({ ...quoteInput, startSubscription: startSubscription });
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          className='start-subscription-step-content'
          views={['year', 'month']}
          componentsProps={{
            switchViewButton: { 'data-testid': 'startSubscriptionStep-button-datepickerSwitchView' },
          }}
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          OpenPickerButtonProps={{ 'data-testid': 'startSubscriptionStep-button-openPicker' } as any }
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          value={startSubscriptionDate}
          onChange={handleSelection}
          renderInput={params =>
            <FormControl data-testid='startSubscriptionStep-input-control'>
              <FormLabel>Month and Year</FormLabel>
              <TextField
                {...params as StandardTextFieldProps}
                style={{ width:'320px' }}
                onChangeCapture={(event) => {
                  setShowDateHint(true);
                  event.stopPropagation();
                }}
                helperText={showDateHint ? 'Use the calendar button to pick a date.' : ''}
              />
            </FormControl>
          }
          PopperProps={{ placement: 'bottom-start', ...{ 'data-testid': 'startSubscriptionStep-datepicker-popper' } }}
        />
      </LocalizationProvider>
    </>
  );
};
