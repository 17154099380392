import { FC, useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AxiosResponse } from 'axios';

import { contact } from '@classkick/pkg-constants';

import { quote } from 'api/quote';
import { QuoteCanceled } from 'api/quote/cancel';
import { APIResponse } from 'api/response';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';

import 'pages/Quote/Quote.scss';


export interface CancelQuoteProps {
  publicId: string;
  quoteType: SubscriptionType;
  reload: () => void;
}

export const CancelQuote: FC<CancelQuoteProps> = (props) => {
  const { publicId, quoteType, reload } = props;
  const [ error, setError ] = useState<boolean>(false);

  const cancelQuote = () => {
    quote.cancel(quoteType, publicId).then(
      (response: AxiosResponse<APIResponse<QuoteCanceled>>) => {
        reload();
    }, (error) => {
      setError(true);
    });
  };

  return (
    <div className="quote-modal-content">
      {
        error
        ? <>
          <Typography variant="body1" mb={4} sx={{ maxWidth: '615px' }} data-testid="cancel-quote-error-message">
            We have encountered an error trying to process your request. Please try again later or contact us at
            <a
              className='contact-email'
              data-testid='quote-contact-email'
              target='_blank'
              rel='noreferrer'
              href={`mailto:${contact.proEmail}`}>
              <strong> {contact.proEmail}</strong>
            </a> if the issue continues.
          </Typography>
        </>
        : <>
          <Typography variant="body1" mb={4} sx={{ maxWidth: '615px' }} data-testid="cancel-quote-confirm-message">
            Are you sure you want to cancel this quote? If you cancel you will lose the current pricing options and will no longer be able to view this quote.
          </Typography>
          <Button
            className="quote-modal-button"
            data-testid='confirm-cancel-quote-button'
            onClick={() => cancelQuote()}
          >
            Confirm
          </Button>
        </>
      }
    </div>
  );
};
