import { FC, useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import axios from 'axios';

import { buildEndpoint } from 'api/endpoints';
import { CheckoutData } from 'pages/Checkout/Checkout';
import { CheckoutForm } from 'pages/Checkout/CheckoutForm/CheckoutForm';
import { QuoteData } from 'pages/Quote/QuoteData';


export type CardInfoProps = {
  quoteData: QuoteData,
  checkoutData: CheckoutData,
  onPaymentIntentError: () => void,
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

export const CardInfo: FC<CardInfoProps> = (props) => {
  const { checkoutData, quoteData, onPaymentIntentError } = props;
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (clientSecret === '') {
      axios.post(
        buildEndpoint.paymentIntent(quoteData.quote_type, quoteData.public_id),
        {
          quote_id: quoteData.public_id,
          purchasing_agent_name: checkoutData.purchaser.agentName,
          purchasing_agent_email: checkoutData.purchaser.agentEmail,
          classkick_admin_email: checkoutData.purchaser.adminEmail,
          org_type: quoteData.quote_type,
        },
      ).then(
        (response) => {
          setClientSecret(response.data.data.client_secret);
        },
        () => {
          // most likely error is trying to checkout a non-open quote
          // so we trigger a reload of the quoteData, which should show a status message
          onPaymentIntentError();
        });
    }
  }, [clientSecret, quoteData.quote_type, quoteData.public_id, checkoutData.purchaser, onPaymentIntentError]);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe' as ('stripe' | 'night' | 'flat' | 'none'),
    },
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm quoteData={quoteData} />
        </Elements>
      )}
    </div>
  );
};
