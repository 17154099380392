import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { QuoteData } from 'pages/Quote/QuoteData';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';
import { ItemDate, subdivideSubscriptions } from 'shared/dates';


export type ShoppingCartProps = {
  quote: QuoteData;
};

const prefixMap: Record<SubscriptionType, string> = {
  '': '',
  district: 'Classkick Pro District',
  school: 'Classkick Pro School',
  teacher: 'Classkick Pro Teacher',
  other: 'Classkick Pro Subscription',
};

export const formatItems = (quote: QuoteData, subdivisions: ItemDate[]): string[] => {
  const prefix = prefixMap[quote.quote_type];
  const suffix = quote.quote_type === 'district' ? ` x ${quote.schools.length} Schools` : '';

  const results = subdivisions.map((subdivision: ItemDate) => {
    return `${prefix} ${subdivision.start.getFullYear()}-${subdivision.end.getFullYear()}${suffix}`;
  });

  return results;
};

export const ShoppingCart: FC<ShoppingCartProps> = (props) => {
  const { quote } = props;

  const subdivisions = subdivideSubscriptions(quote.license_start, quote.license_end);
  const itemStrings = formatItems(quote, subdivisions);
  const itemElements = itemStrings.map((item, index) => <Typography variant="body1" key={index}>{item}</Typography>);

  return (
    <>
      {itemElements}
    </>
  );
};
