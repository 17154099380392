import React, { FC } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';
import { IFAQ } from 'pages/Quote/FAQ/IFAQ';

import 'pages/Quote/FAQ/FAQ.scss';


export interface FAQProps {
  faqs: IFAQ[];
  testLocation: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    '&::before': {
      opacity: 0,
    },
  },
}));

export const FAQ: FC<FAQProps> = (props) => {
  const { faqs, testLocation } = props;
  const classes = useStyles();

  const faqElements = faqs.map((faq, index) => {
    const id = `faq-${index}`;

    return (
      <div key={id}>
        <Accordion classes={{ root: classes.root }}>
          <AccordionSummary
            expandIcon={<div data-testid={`${id}-expandBtn`} style={{ transform: 'rotate(-90deg)', fontSize: '18px' }}>
              <SVGIn url={svgIn.back.url} color={colors.charcoalGray} />
            </div>}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
          >
            <Typography data-testid={`${id}-question`} variant="h5">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography data-testid={`${id}-answer`} variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });

  return (
    <div className="q-faq" data-testid={`${testLocation}-component-faq`}>
      <Typography
        variant="h3"
        style={{ textAlign: 'center' }}>
          Frequently Asked Questions
        </Typography>
      <div className="questions">
        {faqElements}
      </div>
    </div>
  );
};
