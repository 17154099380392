import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';

import { colors, svgIn } from '@classkick/pkg-constants';

import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { captureEvent } from 'components/PostHog/PostHog';
import { DiscountDetail } from 'pages/Quote/DiscountDetail/DiscountDetail';
import { DistrictDetail } from 'pages/Quote/DistrictDetail/DistrictDetail';
import { FAQ } from 'pages/Quote/FAQ/FAQ';
import { quoteFAQData } from 'pages/Quote/FAQ/faqData';
import { PaymentDetail } from 'pages/Quote/Payment/PaymentDetail';
import { QCard } from 'pages/Quote/QCard/QCard';
import { QFooter } from 'pages/Quote/QFooter/QFooter';
import { QHead } from 'pages/Quote/QHead/QHead';
import { QSummary } from 'pages/Quote/QSummary/QSummary';
import { onQuoteOnlyList } from 'pages/Quote/QuoteOnly/quote-only';
import { SchoolDetail } from 'pages/Quote/SchoolDetail/SchoolDetail';
import { StudentsDetail } from 'pages/Quote/StudentsDetail/StudentsDetail';
import { SubscriptionDetail } from 'pages/Quote/SubscriptionDetail/SubscriptionDetail';
import { formatDateSlashes } from 'shared/dates';
import { formatDollars } from 'shared/numbers';
import { paymentURLFor } from 'shared/strings';
import { useFlag } from 'shared/useFlag/useFlag';
import { useQuote } from 'shared/useQuote/useQuote';

import 'pages/Quote/Quote.scss';


export type QuoteParams = {
  id: string,
};

export const Quote: FC = (props) => {
  const { quoteData, reload } = useQuote();
  const isCCActive = useFlag('sb_checkout_cc');
  const shouldHideRenewals = useFlag('sb_hide_renewals');
  const quoteFAQS = quoteFAQData;

  let renewalsHidden = false;

  useEffect(() => {
    if (quoteData) {
      captureEvent('quotePage:quote_viewed', {
        quoteNum: quoteData.quote_num,
        quotePrice: formatDollars(quoteData.price/100),
        quotePublicId: quoteData.public_id,
        quoteStatus: quoteData.status,
        quoteType: quoteData.quote_type,
        quoteDistrict: quoteData.district?.institution_name,
        quoteSchools: quoteData.schools.map( (school) => { return school.institution_name; }),
        quoteCreatedAt: formatDateSlashes(quoteData.created_at),
      });
    }
  }, [quoteData]);

  if (quoteData && quoteData.status !== 'canceled') {
    renewalsHidden = shouldHideRenewals === true && onQuoteOnlyList(quoteData.public_id);

    return (
      <div className="sticky-page">
        {
          quoteData.status === 'invoiced' && quoteData.invoiced_at &&
          <Typography
            data-testid='invoiced-bar'
            variant="body1"
            sx={{
              backgroundColor: colors.brandGreen,
              color: colors.white,
              padding: '22px 0',
              textAlign: 'center',
            }}
          >Invoiced {formatDateSlashes(quoteData.invoiced_at)}. Thank You!</Typography>
        }
        <QHead quoteData={quoteData} reload={reload}/>
        <div className="sticky-content">
          <QSummary quote={quoteData} reload={reload} showPurchase={!renewalsHidden} />
          {
            quoteData.district ?
              <QCard
                testId='quote-district-card'
                title="District"
                description="This is the name of the organization your subscription will be listed under."
                iconURL={svgIn.district.url}
              >
                <DistrictDetail organization={quoteData.district}/>
              </QCard> : null
          }

          <QCard
            testId='quote-schools-card'
            title={quoteData.schools.length > 1 ? 'Schools' : 'School'}
            description={quoteData.schools.length > 1 ? 'Subscriptions include Classkick Pro access for the following schools:' : 'Subscriptions include Classkick Pro access for the following school:'}
            iconURL={svgIn.school.url}
          >
            <SchoolDetail schools={quoteData.schools}/>
          </QCard>

          <QCard
            testId='quote-students-card'
            title="Students"
            description="Your subscription includes this many portfolio student accounts for students across all school(s)."
            iconURL={svgIn.students.url}
          >
            <StudentsDetail students={quoteData.students_num}/>
          </QCard>

          <QCard
            testId='quote-subTerms-card'
            title="Subscription Terms"
            description="Your subscription's billing dates and free trial period (where applicable)."
            iconURL={svgIn.contract.url}
          >
            <SubscriptionDetail billingStart={quoteData.license_start} billingEnd={quoteData.license_end}/>
          </QCard>

          {quoteData.discounts?.length !== 0 && <QCard
            testId='quote-discounts-card'
            title="Discounts"
            description="Promotional discounts applied to your quote."
            iconURL={svgIn.discounts.url}
          >
            <DiscountDetail discounts={quoteData.discounts}/>
          </QCard>
          }
        </div>
        {
          isCCActive && !renewalsHidden && quoteData.status === 'open' ?
            <PaymentDetail
              location='quote'
              poPaymentURL={paymentURLFor(quoteData, 'purchase-order')}
              ccPaymentURL={paymentURLFor(quoteData, 'credit-card')}
            /> : null
        }
        <FAQ faqs={quoteFAQS} testLocation="quote" />
        <QFooter/>
      </div>
    );
  }

  if (quoteData && quoteData.status === 'canceled') {
    return (
      <div className="sticky-page canceled-page">
        <Typography
          data-testid='canceled-header'
          variant="h3"
          sx={{
            color: colors.charcoalGray,
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '28.8px',
            paddingBottom: '47px',
            paddingTop: '116px',
            textAlign: 'center',
          }}
        >This quote is no longer available.</Typography>
        <Typography
          data-testid='canceled-body'
          variant="body1"
          sx={{
            textAlign: 'center',
          }}
        >This quote has been canceled by the creator.</Typography>
      </div>
    );
  }

  return (
      <LoadingSpinner testLocation="quote" />
  );
};
