import React, { FC, useCallback, useEffect, useState } from 'react';

import { Autocomplete, Button, TextField, Typography } from '@mui/material';

import axios, { AxiosResponse } from 'axios';

import { buildEndpoint } from 'api/endpoints';
import { APIResponse } from 'api/response';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { location } from 'pages/Wizard/Steps/ChooseDistrictStep/ChooseDistrictStep';
import { DistrictSchoolChips } from 'pages/Wizard/Steps/SchoolInfoStep/DistrictSchoolChips';
import { SchoolCard } from 'pages/Wizard/Steps/SchoolInfoStep/SchoolCard';
import { Direction, StepName } from 'pages/Wizard/Steps/Steps';
import { alphabetizeObjectsByKey } from 'shared/dataStructures';
import { stringToInt } from 'shared/numbers';
import { Organization } from 'types/Organization';

import 'pages/Wizard/Steps/SchoolInfoStep/SchoolInfoStep.scss';


export interface SchoolInfoStepProps extends BaseStepProps {
  setStepName: (step: StepName) => void,
  setDirection: (direction: Direction) => void,
}

export const SchoolInfoStep: FC<SchoolInfoStepProps> = (props) => {

  const { quoteInput, setDirection, setQuoteInput, setStepName } = props;
  const currentSchools = quoteInput.schools;
  const districtId = quoteInput && quoteInput.organization ? quoteInput.organization.pid : null;
  const numberOfSchools = stringToInt(quoteInput.numberOfSchools);

  const [districtSchools, setDistrictSchools] = useState<Organization[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputValue, setInputValue] = React.useState('');
  const [selectedSchools, setSelectedSchools] = useState<Organization[]>(currentSchools);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState<Organization | null>(null);
  const [inputKey, setInputKey] = useState<number>(selectedSchools.length);

  useEffect(() => {
    if (districtId) {
      const endpoint = buildEndpoint.schoolsOfDistrict(districtId);

      axios.get<APIResponse<Organization[]>>(endpoint).then((response: AxiosResponse<APIResponse<Organization[]>>) => {
        let results: Organization[] = [];

        if (response.data.success && response.data.data) {
          results = [...response.data.data];
          // Alphabetize list of district schools
          const alphaResults = alphabetizeObjectsByKey(results, 'institution_name');
          setDistrictSchools(alphaResults);
        }
      }, (error) => {
        setDistrictSchools([]);
      });
    }
  }, [districtId]);

  useEffect(() => {
    // If current schools has been cleared by district selection step, reset the selected schools list
    if (currentSchools.length < selectedSchools.length) {
      setSelectedSchools(currentSchools);
    }
    // If the number of schools has been updated and is less than the current selected schools list, reset the list
    if (numberOfSchools && selectedSchools) {
      if (numberOfSchools < selectedSchools.length) {
        setSelectedSchools([]);
      }
    }
  }, [currentSchools, numberOfSchools, selectedSchools]);

  const addSchool = (newSchool: Organization | null, schools: Organization[]) => {
    if (newSchool) {
      // Check if the selected school already exists in the selected schools list
      const schoolIndex = schools.findIndex(school => school.pid === newSchool.pid);
      if (schoolIndex === -1) {
        // Add school to selected schools list for the quote
        const updatedSelectedSchools = [...selectedSchools];
        updatedSelectedSchools.push(newSchool);
        setSelectedSchools(updatedSelectedSchools);
        setQuoteInput({ ...quoteInput, schools: updatedSelectedSchools });
        setInputKey(updatedSelectedSchools.length);
      } else {
        // inputKey is being used to trigger a rerender of Autocomplete to clear the previous selection
        // If the user selects an already selected school, we need to update the key to clear the input (better user experience than manually clearing)
        inputKey === selectedSchools.length ? setInputKey(0) : setInputKey(selectedSchools.length);
      }
    }
  };

  const getOptionLabel = useCallback((value: Organization) => {
    return value.institution_name;
  }, []);

  const getPlaceholder = (): string => {
    let placeholder = '';
    if (numberOfSchools) {
      if (numberOfSchools > 1) {
        if (currentSchools.length < 1) {
          placeholder = 'Find your schools';
        } else {
          placeholder = 'Great! Find another school';
        }
      } else {
        placeholder = 'Find your school';
      }
    }
    return placeholder;
  };

  const removeSchool = (school: Organization) => {
    // Remove school from selected schools list for the quote
    const updatedSelectedSchools = [...selectedSchools];
    const schoolIndex = updatedSelectedSchools.findIndex(x => x.pid === school.pid);
    updatedSelectedSchools.splice(schoolIndex, 1);
    setSelectedSchools(updatedSelectedSchools);
    setQuoteInput({ ...quoteInput, schools: updatedSelectedSchools });
    setInputKey(updatedSelectedSchools.length);
  };

  const renderDistrictSchoolsSelected = () => {
    const numberOfSelectedSchools = currentSchools.length;
    return (
      <div
        className={numberOfSelectedSchools === numberOfSchools ? 'complete-number-selected-schools' : 'number-selected-schools'}
        data-testid='schoolInfoStep-div-numberSelectedSchools'
      >
        {`${numberOfSelectedSchools}/${numberOfSchools}`} schools selected.
      </div>
    );
  };

  const handleClick = () => {
    setDirection('forward');
    setStepName('unknownSchool');
  };

  return (
    <>
      { numberOfSchools === currentSchools.length
        ? null
        : <Autocomplete
            key={inputKey}
            data-testid='schoolInfoStep-autocomplete-selectSchool'
            value={value}
            options={ districtSchools.filter(school => !selectedSchools.includes(school)) }
            getOptionLabel={getOptionLabel}
            onChange={(event, newValue: Organization | null) => {
              addSchool(newValue, selectedSchools);
            }}
            onInputChange={(event, newInputValue: string) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                placeholder={getPlaceholder()}
                fullWidth />
            )}
            isOptionEqualToValue={(option, value) => { return option.pid === value.pid; } }
            renderOption={(props, org) => {
              return (<li {...props} data-testid={`schoolInfoStep-option-${org.pid}`} key={org.pid} style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
                <Typography variant='body1'>{org.institution_name}</Typography>
                <Typography variant='subtitle2'>{location(org)}</Typography>
              </li>);
            }}
            noOptionsText={'No options'}
            autoComplete
            selectOnFocus
            handleHomeEndKeys
            clearOnBlur
            clearOnEscape
            clearText='Clear'
            style={{ width: 400 }}
          />
      }
      { numberOfSchools === 1 && currentSchools.length === 1 && <SchoolCard onDelete={removeSchool} school={currentSchools[0]} /> }
      { numberOfSchools > 1 && renderDistrictSchoolsSelected() }
      { numberOfSchools > 1 && currentSchools.length > 0 && <DistrictSchoolChips testIdLocation='schoolInfoStep' onDelete={removeSchool} schools={currentSchools} /> }
      <Button
        data-testid="schoolInfoStep-button-selectNoSchool"
        variant="action"
        onClick={handleClick}
      >
        Don't see your school?
      </Button>
    </>
  );
};
