import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import 'pages/Lander/Hero/HeroCard/HeroCard.scss';
import { colors } from '@classkick/pkg-constants';
import { PNGAsset } from '@classkick/pkg-constants/dist/static-assets/png';
import { captureEvent } from 'components/PostHog/PostHog';


export interface HeroCardProps {
  buttonText: string;
  buttonTo: string;
  externalLink?: boolean;
  flagText?: string;
  flagImg?: PNGAsset;
  footnoteText?: string;
  hasFlag?: boolean;
  priceText: string;
  splitText: string;
  styledButton?: boolean;
  subTitleText: string;
  testId: string;
  title: string;
}

export const HeroCard: FC<HeroCardProps> = ({
  buttonText,
  buttonTo,
  externalLink,
  flagImg,
  flagText,
  footnoteText,
  hasFlag,
  priceText,
  splitText,
  styledButton,
  subTitleText,
  testId,
  title,
}) => {

  const handlePostHog = () => {
    captureEvent('landingPage:heroButton_clicked', { heroButtonType: title });
  };

  const renderFlag = () => {
    return (
      <Box
        className='card-graphic-container'
        data-testid='landing-graphic-districtFlag'
        style={{ backgroundColor: colors.brandGreen }}
      >
        <Typography
          className='flag-text'
          align='center'
        >
          {flagText}
        </Typography>
        <img
          className='flag'
          src={flagImg?.url}
          alt=''
        />
      </Box>
    );
  };

  return (
    <Card
      className='hero-card'
      data-testid={`landing-card-${testId}`}
      sx={
        hasFlag ?
          { minHeight: { xs: '478px', sm: '382px' },
            minWidth: { xs: '280px', sm: '390px' } }
          :
          { minHeight: { xs: '382px', sm: '382px' },
            minWidth: { xs: '280px', sm: '390px' } }
      }
    >
      {hasFlag ? renderFlag(): null}
      <CardContent
        className='hero-card-content'
        sx={{
          minWidth: { sm: '310px' },
          maxWidth: { xs: '275px', sm: '500px' },
          minHeight: '292px',
          padding: { xs: '28px', sm: '40px 40px 50px 40px', lg: '40px 40px 26px 40px' },
        }}
      >
        <Typography
          className='hero-card-title'
          component='h2'
          data-testid={`landing-title-${testId}`}
          variant='h2'
        >{title}</Typography>
        <Typography
          className='hero-card-sub'
          component='p'
          data-testid={`landing-text-${testId}Sub`}
          variant='body1'
        >{subTitleText}</Typography>
        <div className='hero-card-price-container'>
          <Typography
            className='hero-card-price'
            component='p'
            data-testid={`landing-text-${testId}Price`}
            variant='h1'
          >
            {priceText}
            <Typography component='span' className='hero-card-monthly'>
              /mo*
            </Typography>
          </Typography>
        </div>
        <Typography
          className='hero-card-split'
          component='p'
          data-testid={`landing-text-${testId}Split`}
          variant='body1'
        >{splitText}</Typography>
        <Typography
          className='hero-card-note'
          component='p'
          data-testid={`landing-text-${testId}Footnote`}
          variant='body2'
        >{footnoteText}</Typography>
      </CardContent>
      <Box className='card-button-box'
           sx={
             hasFlag ?
               { margin: { xs: '-10px auto 0', sm: '2px auto 0' } }
              :
               { margin: { xs: '2px auto 0' } }
           }
      >
        {externalLink ?
          <Button
            className='hero-card-button'
            data-testid={`landing-button-${testId}Quote`}
            href={buttonTo}
            sx={{ minWidth: { xs: '222px', sm: '296px' } }}
            variant={styledButton ? 'outlined' : undefined }
            onClick={handlePostHog}
          >
            {buttonText}
          </Button>
          :
          <Button
            className='hero-card-button'
            component={Link}
            data-testid={`landing-button-${testId}Quote`}
            to={buttonTo}
            sx={{ minWidth: { xs: '222px', sm: '296px' } }}
            variant={styledButton ? 'outlined' : undefined }
            onClick={handlePostHog}
          >
            {buttonText}
          </Button>
        }
      </Box>
    </Card>
  );
};
