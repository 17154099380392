import React, { FC, useCallback } from 'react';

import { Button, Typography } from '@mui/material';

import { endpoints } from 'api/endpoints';
import { KickAhead } from 'components/KickAhead/KickAhead';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { Direction, StepName } from 'pages/Wizard/Steps/Steps';
import { Organization } from 'types/Organization';


export const location = (org: Organization): string => {
  return `${org.city}, ${org.state} ${org.zipcode}, ${org.country}`;
};

export interface ChooseDistrictStepProps extends BaseStepProps {
  setStepName: (step: StepName) => void,
  setDirection: (direction: Direction) => void,
}

export const shouldShowNoDistrict = (subscriptionType: SubscriptionType): boolean => {
  const canSee: SubscriptionType[] = ['teacher', 'school'];
  return canSee.includes(subscriptionType);
};

export const ChooseDistrictStep: FC<ChooseDistrictStepProps> = (props) => {

  const { quoteInput, setDirection, setQuoteInput, setStepName } = props;

  const getOptionLabel = useCallback((value: Organization) => {
    return value.institution_name;
  }, []);

  const setOrganization = (org: Organization | null): void => {
    let schools = quoteInput.schools;
    // If the org selection is cleared or a new org is chosen, reset any previous school selections
    if (!org || !quoteInput.organization || org.pid !== quoteInput.organization.pid) {
      schools = [];
    }

    setQuoteInput({
      ...quoteInput,
      organization: org,
      schools: schools,
    });
  };

  return (
    <>
      <KickAhead<Organization>
        data-testid='chooseDistrictStep-autocomplete-selectDistrict'
        selection={quoteInput.organization}
        setSelection={setOrganization}
        endpoint={endpoints.organizationSearch}
        placeholder='Find your district...'
        getOptionLabel={getOptionLabel}
        extraParams={{
          institution_type: 'district',
        }}
        renderOption={(props, org) => {
          return (<li {...props} key={org.pid} data-testid={`districtOption-${org.institution_name}-wizard`} style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            <Typography variant='body1'>{org.institution_name}</Typography>
            <Typography variant='subtitle2'>{location(org)}</Typography>
          </li>);
        }}
        equalityKey='pid'
        style={{ width: 400 }}
      />
      <Typography variant='subtitle1' style={{
        alignSelf: 'flex-start',
        padding: '8px 0 0 16px',
      }}>
        {
          // '\u00A0' is a unicode empty space
          // used here to prevent layout jumps when there is otherwise no content
          quoteInput.organization ? location(quoteInput.organization) : '\u00A0'
        }
      </Typography>
      { shouldShowNoDistrict(quoteInput.subscriptionType) ?
          <Button
            data-testid="chooseDistrictStep-button-selectNoDistrict"
            disabled={quoteInput.organization !== null}
            variant="action"
            onClick={() => {
              setDirection('forward');
              setStepName('chooseIndySchool');
            }}>Don't see your district?</Button> : null
      }
    </>
  );
};
