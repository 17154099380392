import { FC } from 'react';

import { Typography } from '@mui/material';

import { contact } from '@classkick/pkg-constants';

import { QuoteData } from 'pages/Quote/QuoteData';


export type NextStepsPOProps = {
  quote: QuoteData;
};

export const NextStepsPO: FC<NextStepsPOProps> = (props) => {
  const { quote } = props;

  return (
    <>
      <Typography variant="body1" mb={4} data-testid='poCheckout-text-nextStepsInfo'>
        Please send your purchase order within <em>30 days</em> and check within <em>60 days</em>.
        Make sure to reference your <em>Quote Number (#{quote.quote_num})</em> on your Purchase Order.
      </Typography>
      <Typography variant="body1" mb={4} data-testid='poCheckout-text-emailToInfo'>
        <em>Email your purchase order to:</em><br/>
        {contact.purchaseOrdersEmail}
      </Typography>
      <Typography variant="body1" mb={4} data-testid='poCheckout-text-mailToInfo'>
        <em>Mail your check to:</em><br />
        {contact.remittanceAddress[0]}<br />
        {contact.remittanceAddress[1]}<br />
        Area Number: {quote.quote_num}<br />
        {contact.remittanceAddress[2]}
      </Typography>

      <Typography variant="sbFinePrint" data-testid='poCheckout-text-finePrintNextSteps'>
        Please Note: If you do not put your Quote Number, we will not be able to confirm your purchase.
        You will have 30 days to submit your purchase order from the date of this purchase.
        If you fail to submit, your teachers will lose access to Pro.
      </Typography>
    </>
  );
};
