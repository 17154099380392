import React, { FC, useState } from 'react';

import { Button } from '@mui/material';

import { contact } from '@classkick/pkg-constants';

import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';

import 'pages/Wizard/Steps/SubmitErrorStep/SubmitErrorStep.scss';


export interface SubmitErrorStepProps extends BaseStepProps {
  quoteError: boolean;
  submitQuoteInfo: () => void;
}

export const getButtonText = (loading: boolean, quoteError: boolean): string => {
  let output = 'Try Again';

  if (loading) {
    if (quoteError) {
      output = 'Error';
    } else {
      output = 'Processing...';
    }
  }
  return output;
};

export const SubmitErrorStep: FC<SubmitErrorStepProps> = (props: SubmitErrorStepProps) => {
  const { quoteError, submitQuoteInfo } = props;
  const [loading, setLoading] = useState(false);

  const buttonText = getButtonText(loading, quoteError);

  return (
    <>
      <p className='contact-us-details' data-testid='submitErrorStep-text-firstLine'>We are so sorry. Something went wrong on our end. Please try again.</p>
      <p className='contact-us-details' data-testid='submitErrorStep-text-secondLine'>
        If the error continues, please contact us at
        <a
          className='contact-email'
          data-testid='submitErrorStep-link-contactEmail'
          href={`mailto:${contact.proEmail}`}>
          <strong> {contact.proEmail} </strong>
        </a>
        or {contact.salesPhone}
      </p>
      <Button
        data-testid="submitErrorStep-button-tryAgain"
        onClick={() => {
          submitQuoteInfo();
          setLoading(true);
        }}
        disabled={loading}
        style={{ marginTop: '70px', width: 260 }}
      >{buttonText}</Button>
    </>
  );
};
