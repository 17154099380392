import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { send } from 'api/email/send';
import { InputWithEmailChips } from 'components/InputWithEmailChips/InputWithEmailChips';
import { Kickput } from 'components/Kickput/Kickput';
import { QuoteData } from 'pages/Quote/QuoteData';
import { validators } from 'pages/Wizard/Steps/Validators';
import { formatDateSlashes } from 'shared/dates';

import 'components/EmailQuoteForm/EmailQuoteForm.scss';


export interface EmailQuoteData {
  subject: string,
  recipients: string[],
  cc: string[],
  message: string,
}

export interface EmailQuoteFormProps {
  quoteData: QuoteData,
  quoteUrl: string,
}

export const EmailQuoteForm:FC<EmailQuoteFormProps> = (props) => {

  const { quoteData, quoteUrl } = props;

  const [emailQuoteData, setEmailQuoteData] = useState<EmailQuoteData>({
    subject: 'You\'ve Received a Custom Order for Classkick Pro',
    recipients: [],
    cc: [],
    message: `Hi there!
Your colleague wanted to share this custom quote for purchasing Classkick for your school with you.

This quote will be available to view until ${formatDateSlashes(quoteData.expired_at)}.
${quoteUrl}`,
  });


  const [requiredFieldError, setRequiredFieldError] = useState<boolean>(false);
  const [messageInvalid, setMessageInvalid] = useState<boolean>(false);
  const [subjectInvalid, setSubjectInvalid] = useState<boolean>(false);
  const [ccInvalid, setCcInvalid] = useState<boolean>(false);

  const resetFieldOnUserInput = (dataKey: keyof EmailQuoteData) => {
    switch (dataKey){
      case 'subject':
        setSubjectInvalid(false);
        break;
      case 'message':
        setMessageInvalid(false);
        break;
    }
  };


  //this is used by subject and message input updates
  const updateData = (value:string, dataKey: keyof EmailQuoteData) => {
    resetFieldOnUserInput(dataKey);
    setEmailQuoteData({ ...emailQuoteData, [dataKey]: value });
  };

  const updateRecipients = (updatedArray: string[]) => {
    setEmailQuoteData({ ...emailQuoteData, recipients: updatedArray });
  };

  const updateCC = (updatedArray: string[]) => {
    setEmailQuoteData({ ...emailQuoteData, cc: updatedArray });
  };

  const validateSubject = () => {
    const isSubjectValid = validators.isValueSet(emailQuoteData.subject);
    setSubjectInvalid(!isSubjectValid);
  };

  const validateMessage = () => {
    const isMessageValid =
      validators.isValueSet(emailQuoteData.message);
    setMessageInvalid(!isMessageValid);
  };

  const isButtonDisabled = () => {
    const hasSubject = emailQuoteData.subject.length > 0;
    const hasMessage = emailQuoteData.message.length > 0;

    if (hasSubject && hasMessage) {
      return !(!ccInvalid && !requiredFieldError && emailQuoteData.recipients.length >= 1);
    }
    return true;
  };

  const sendEmail = () => {
    send(
      emailQuoteData.subject,
      emailQuoteData.message,
      '',
      emailQuoteData.cc,
      emailQuoteData.recipients,
    );
  };

  return(
    <form className={'email-quote-form'}>
      <Kickput<EmailQuoteData>
        dataKey='subject'
        error={subjectInvalid}
        helperText={subjectInvalid ? 'Please enter a subject.' : undefined }
        label='Subject *'
        onBlur={validateSubject}
        placeholder="You've Received a Custom Quote for Classkick Pro"
        testId='emailQuoteForm-subject-input'
        updateData={updateData}
        wholeData={emailQuoteData}
      />
      <InputWithEmailChips
        label="Recipient(s) *"
        setFieldError={setRequiredFieldError}
        fieldError={requiredFieldError}
        isChipFieldRequired={true}
        updateList={updateRecipients}
        placeholder="coordinator@school.edu, principal@school.edu"
        testId='emailQuoteForm-inputWithChips-recipientInput'
        testIdLocation='emailQuoteForm'
      />
      <InputWithEmailChips
        label="CC"
        updateList={updateCC}
        placeholder="you@school.edu"
        testId='emailQuoteForm-inputWithChips-ccInput'
        testIdLocation='emailQuoteForm'
        setFieldError={setCcInvalid}
      />
      <Kickput<EmailQuoteData>
        dataKey='message'
        error={messageInvalid}
        helperText={messageInvalid ? 'Please enter a message.' : undefined }
        label='Message *'
        minRows={6}
        multiline={true}
        onBlur={validateMessage}
        placeholder={emailQuoteData.message}
        testId='emailQuoteForm-message-input'
        updateData={updateData}
        wholeData={emailQuoteData}
      />
      <Box
        className='email-quote-form-button'
        component='div'
      >
        <Button
          data-testid='emailQuoteForm-send-button'
          disabled={isButtonDisabled()}
          onClick={sendEmail}
        >Send</Button>
      </Box>
    </form>
  );
};
