import { FC } from 'react';

import { Benefits } from 'pages/Lander/Benefits/Benefits';
import { LanderFAQ } from 'pages/Lander/FAQ/LanderFAQ';
import { Features } from 'pages/Lander/Features/Features';
import { Footer } from 'pages/Lander/Footer/Footer';
import { Hero } from 'pages/Lander/Hero/Hero';
import { Steps } from 'pages/Lander/Steps/Steps';


export const Landing: FC = () => {
  return(
    <>
      <Hero/>
      <Steps/>
      <Features/>
      <Benefits/>
      <LanderFAQ/>
      <Footer/>
    </>
  );
};
