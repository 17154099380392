import axios, { AxiosResponse } from 'axios';

import { buildEndpoint } from 'api/endpoints';
import { APIResponse } from 'api/response';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';


export interface QuoteExtended {
  quote_id: string;
}

export const extend = (quoteType: SubscriptionType, publicId: string): Promise<AxiosResponse<APIResponse<QuoteExtended>>> => {
  const endpoint = buildEndpoint.extendQuote(quoteType, publicId);
  return axios.put<APIResponse<QuoteExtended>>(endpoint);
};
