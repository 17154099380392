import { useRef, useState } from 'react';

import axios, { CancelTokenSource } from 'axios';
import useAxios from 'axios-hooks';

import { APIResponse } from 'api/response';


export type EVState = 'unknown' | 'stale' | 'fresh';
export type EVActivity = 'idle' | 'loading' | 'loaded';
export type EVValidity = null | true | false;
export interface EVStatus {
  state: EVState;
  activity: EVActivity;
  valid: EVValidity;
}

export interface ExternalValidation<P, R> {
  notifyChange: () => void;
  seek: (payload: P) => void;
  status: EVStatus;
  response: undefined | APIResponse<R>;
}

// <P, R>
// Where P describes the Payload to be sent to endpoint
// Where R describes the Response that endpoint will return
export const useExternalValidator = <P, R>(endpoint: string, isValid: (response: APIResponse<R>) => boolean): ExternalValidation<P, R> => {
  const [status, setStatus] = useState<EVStatus>({
    state: 'unknown',
    activity: 'idle',
    valid: null,
  });

  const cancelToken = useRef<CancelTokenSource | null>(null);

  const [{ data }, execute] =  useAxios<APIResponse<R>>(endpoint, { manual: true });

  const notifyChange = () => {
    cancelToken.current?.cancel();
    cancelToken.current = null;
    setStatus({
      state: status.state !== 'unknown' ? 'stale' : 'unknown',
      activity: 'idle',
      valid: null,
    });
  };

  const seek = (payload: P) => {
    if (status.state !== 'fresh') {
      cancelToken.current?.cancel();
      cancelToken.current = axios.CancelToken.source();
      setStatus({
        state: 'stale',
        activity: 'loading',
        valid: null,
      });
      execute({
        url: endpoint,
        method: 'POST',
        data: payload,
        cancelToken: cancelToken.current.token,
      }).then((response) => {
        setStatus({
          state: 'fresh',
          activity: 'loaded',
          valid: isValid(response.data),
        });
      });
    }
  };

  return {
    notifyChange, // call notifyChange whenever the field changes
    seek, // call seek when you want to check with the backend
    status, // use this to display messages to the user
    response: data, // has the raw response from the backend
  };
};
