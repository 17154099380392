import { FC } from 'react';

import { FormControlLabel, Radio, Stack } from '@mui/material';

import { IconInCircle } from 'components/IconInCircle/IconInCircle';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';


export interface ChoiceProps {
  url: string;
  label: string;
  value: SubscriptionType;
  setValue: (subscriptionType: SubscriptionType) => void;
  color?: string;
  disabled?: boolean;
  emWidth?: number;
}

export const Choice: FC<ChoiceProps> = ({
  url,
  label,
  value,
  setValue,
  color,
  disabled = false,
  emWidth,
}) => {
  return <>
  <Stack
    direction='column'
  >
    <IconInCircle
      url={url}
      color={color}
      clickFn={disabled ? undefined : () => { setValue(value); }}
    />
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{ marginTop: 3 }}
          id={`${value}-radio-btn`}
          data-testid={`subscriptionTypeStep-radio-${value}`}
        />
      }
      disabled={disabled}
      label={label}
      labelPlacement='bottom'
    />
  </Stack>
  </>;
};
