import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

import { TwoUp } from 'components/TwoUp/TwoUp';
import { QuoteLineItem } from 'types/QuoteLineItem';


export interface LineItemProps {
  numberOfSchools: number;
  quoteLineItem: QuoteLineItem;
  quoteLineItems: QuoteLineItem[];
  testId: string;
}

export const LineItem: FC<LineItemProps> = (props) => {

  const { numberOfSchools, quoteLineItem, quoteLineItems, testId } = props;

  //get start and end years
  const startYear = quoteLineItem.start.getFullYear();
  const endYear = quoteLineItem.end.getFullYear();

  const startMonth = quoteLineItem.start.toLocaleString('default', { month: 'short' });
  const endMonth = 'June';

  const descriptionOfCharge = <>
    {`${startYear}-${endYear} School Subscription x ${numberOfSchools}`}
    {quoteLineItem.prorated ?
      <Typography variant='body2' className='prorated-line' sx={{ marginTop: 0 }}>
        {`${startMonth} - ${endMonth}`}
      </Typography>
      :
      null
    }
  </>;

  const lineBreak = quoteLineItems[quoteLineItems.length - 1] !== quoteLineItem ? <hr/> : null;

  return(
    <div key={`${quoteLineItem.start.getMonth()}-${startYear}`} data-testid={`${testId}`}>
      <TwoUp
        left={descriptionOfCharge}
        right={' '}
      />
      {lineBreak}
    </div>
  );
};
