import React, { FC, useCallback } from 'react';

import { Button, Typography } from '@mui/material';

import { endpoints } from 'api/endpoints';
import { KickAhead } from 'components/KickAhead/KickAhead';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { location } from 'pages/Wizard/Steps/ChooseDistrictStep/ChooseDistrictStep';
import { Direction, StepName } from 'pages/Wizard/Steps/Steps';
import { Organization } from 'types/Organization';


export interface IndySchoolStepProps extends BaseStepProps {
  setStepName: (step: StepName) => void,
  setDirection: (direction: Direction) => void,
}

export const IndySchoolStep: FC<IndySchoolStepProps> = (props) => {
  const { quoteInput, setDirection, setQuoteInput, setStepName } = props;

  const getOptionLabel = useCallback((value: Organization) => {
    return value.institution_name;
  }, []);

  const setIndySchool = (school: Organization | null): void => {
    // schools should be [] if no school is given
    let schools = school === null ? [] : [school];
    // schools should be [] if the quote has an organization
    schools = quoteInput.organization === null ? schools : [];

    setQuoteInput({
      ...quoteInput,
      schools,
    });
  };

  return (
    <>
      <KickAhead<Organization>
        selection={quoteInput.schools[0] || null}
        setSelection={setIndySchool}
        endpoint={endpoints.organizationSearch}
        placeholder='Find your school...'
        getOptionLabel={getOptionLabel}
        extraParams={{
          institution_type: 'school',
          only_independent: true,
        }}
        renderOption={(props, org) => {
          return (<li {...props} key={org.pid} style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            <Typography variant='body1'>{org.institution_name}</Typography>
            <Typography variant='subtitle2'>{location(org)}</Typography>
          </li>);
        }}
        equalityKey='pid'
        style={{ width: 400 }}
      />
      <Typography variant='subtitle1' style={{
        alignSelf: 'flex-start',
        padding: '8px 0 0 16px',
      }}>
        {quoteInput.schools[0] ? location(quoteInput.schools[0]) : null}
      </Typography>
      <Button
        data-testid="indySchool-button-noSchool"
        variant="action"
        onClick={() => {
          setDirection('forward');
          setStepName('unknownSchool');
        }}>Don't see your school?</Button>
    </>
  );
};
