import { FC } from 'react';

import { HeroBanner } from 'pages/Lander/Hero/HeroBanner/HeroBanner';

import { HeroTip } from 'pages/Lander/Hero/HeroTip/HeroTip';


export const Hero: FC = () => {
  return (
    <section data-testid='landing-block-hero'>
      <HeroBanner/>
      <HeroTip/>
    </section>
  );
};
