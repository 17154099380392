import axios, { AxiosResponse } from 'axios';

import { endpoints } from 'api/endpoints';
import { APIResponse } from 'api/response';
import { Creator, QuoteInput } from 'pages/Wizard/QuoteInput';
import { MDYtoTimedISO } from 'shared/dates';
import { PricingInfo } from 'types/PricingInfo';


export interface QuoteCreatePayload {
  license_start: string;
  license_end: string;
  creator: Creator;
}

export interface QuoteCreateSchoolPayload extends QuoteCreatePayload {
  school: number;
}

export interface QuoteCreateDistrictPayload extends QuoteCreatePayload {
  district: number | undefined;
  schools: number[];
}

export interface QuoteCreated {
  quote_id: string;
}

export const create = (quoteInput: QuoteInput, pricing: PricingInfo): Promise<AxiosResponse<APIResponse<QuoteCreated>>> => {
  switch (quoteInput.subscriptionType) {
    case 'school':
      return createSchool(quoteInput, pricing);
    case 'district':
      return createDistrict(quoteInput, pricing);
    default:
      throw new Error('Unimplemented Subscription Type for api/quote/create.ts:create');
  }
};

export const createSchool = (quoteInput: QuoteInput, pricing: PricingInfo): Promise<AxiosResponse<APIResponse<QuoteCreated>>> => {
  if (quoteInput.schools.length !== 1) {
    throw new Error('There should only ever be one school on a school quote.');
  }
  const payload: QuoteCreateSchoolPayload = {
    school: quoteInput.schools.map((school) => school.pid)[0],
    license_start: quoteInput.startSubscription.toISOString(),
    license_end: MDYtoTimedISO(quoteInput.subscriptionPeriod, 23, 59, 59),
    creator: quoteInput.creator,
  };

  return axios.post<APIResponse<QuoteCreated>>(endpoints.quoteCreateSchool, payload);
};

export const createDistrict = (quoteInput: QuoteInput, pricing: PricingInfo): Promise<AxiosResponse<APIResponse<QuoteCreated>>> => {
  const payload: QuoteCreateDistrictPayload = {
    district: quoteInput.organization?.pid,
    schools: quoteInput.schools.map((school) => school.pid),
    license_start: quoteInput.startSubscription.toISOString(),
    license_end: MDYtoTimedISO(quoteInput.subscriptionPeriod, 23, 59, 59),
    creator: quoteInput.creator,
  };
  return axios.post<APIResponse<QuoteCreated>>(endpoints.quoteCreateDistrict, payload);
};
