import { FC } from 'react';

import { colors } from '@classkick/pkg-constants';

import { FAQ } from 'pages/Quote/FAQ/FAQ';
import { landerFAQData } from 'pages/Quote/FAQ/faqData';


export const LanderFAQ: FC = () => {
  const landerFAQS = landerFAQData;

  return (
    <section
      data-testid="landing-block-faq"
      style={{
        backgroundColor: colors.hoverGray,
      }}
    >
      <FAQ faqs={landerFAQS} testLocation="landing" />
    </section>
  );
};
