import { FC, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';

import 'pages/Lander/NavLinks/NavLinks.scss';
import 'styles/_variables.scss';
import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';


export type LinkDataItem = {
  name: string,
  title: string,
  href: string,
};

export const NavLinks: FC = () => {

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const helpCenter:LinkDataItem =  { name: 'helpCenter', title: 'HELP CENTER', href: 'https://classkick.zendesk.com/hc/en-us' };
  const pricing:LinkDataItem =  { name: 'pricing', title: 'PRICING', href: '/' };
  const logIn:LinkDataItem =  { name: 'login', title: 'LOG IN', href: 'https://app.classkick.com/#/account/login' };

  const linkData: LinkDataItem[] = [ helpCenter, pricing, logIn ];

  return(
    <AppBar
      className='landingpage-header-links'
      color='transparent'
      elevation={0}
      position='static'
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' } }}>
            <IconButton
              className='landingpage-menu-icon'
              size="small"
              onClick={handleOpenNavMenu}
            >
              <SVGIn url={svgIn.hamburger.url} color={colors.charcoalGray} emWidth={0.75} />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              className='landingpage-links-menu'
              keepMounted
              PaperProps={{ sx: { height:{ xs: '525px' }, width: { xs: '100%' }, top: '0' } }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { sm: 'block', md: 'none' },
                height: { xs: '525px' },
                width:{ xs: '100%', sm: '300px' },
              }}
            >
              <ListItem>
                <IconButton
                  aria-label='close'
                  onClick={handleCloseNavMenu}
                  sx={{
                    paddingBottom: 2.5,
                    paddingRight: 0,
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  <SVGIn url={svgIn.close.url} color={colors.charcoalGray} emWidth={0.75} />
                </IconButton>
              </ListItem>
              {linkData.map((linkDataItem) => {
                return (
                  <MenuItem
                    key={`header-link-${linkDataItem.name}`}
                  >
                    <Link
                      data-testid={`header-link-${linkDataItem.name}`}
                      href={linkDataItem.href}
                      style={{ fontWeight: 500 }}
                      target={ linkDataItem.name === 'pricing' ? '_self' : '_blank' }
                      underline='hover'
                      variant='body1'>
                        {linkDataItem.title}
                    </Link>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {linkData.map((linkDataItem) => {
              return (
                <Button
                  component={Link}
                  data-testid={`headerDropdown-link-${linkDataItem.name}`}
                  href={linkDataItem.href}
                  key={`headerDropdown-link-${linkDataItem.name}`}
                  target={ linkDataItem.name === 'pricing' ? '_self' : '_blank' }
                  variant='navigation'>
                    {linkDataItem.title}
                </Button>);
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
