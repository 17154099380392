import { FC } from 'react';

import Typography from '@mui/material/Typography';


export type StudentsDetailProps = {
  students: number;
};

export const StudentsDetail: FC<StudentsDetailProps> = (props) => {
  const { students } = props;
  return (
    <>
      <Typography variant="subtitle2" data-testid='quote-studentsCard-contentTitle'>Total Students Per District</Typography>
      <Typography variant="body1">
        {students}
      </Typography>
    </>
  );
};
