import { FC } from 'react';

import Typography from '@mui/material/Typography';

import { TwoUp } from 'components/TwoUp/TwoUp';
import { formatTimestampSlashes } from 'shared/dates';
import { formatDiscount } from 'shared/numbers';
import { Discount } from 'types/Discounts';

import 'pages/Quote/DiscountDetail/DiscountDetail.scss';


export type DiscountDetailProps = {
  discounts: Discount[];
};

export const DiscountDetail: FC<DiscountDetailProps> = (props) => {
  const { discounts } = props;

  const discountElements = discounts.map((discount) => {
    return <div key={discount.name}>
      <Typography variant="body1" className="name-line" component="div">
        <TwoUp
          left={discount.name}
          right={<>{formatDiscount(discount.amount)}</>} />
      </Typography>
      <Typography variant="body2">{discount.description} Valid until {formatTimestampSlashes(discount.expiration)}.</Typography>
    </div>;
  });

  return (
    <div className="discounts">
      {discountElements}
    </div>
  );
};
