/* istanbul ignore file */
import * as React  from 'react';
import * as ReactDOM  from 'react-dom';

import { ThemeProvider } from '@mui/material/styles';

import { App } from 'App';
import { theme } from 'styles/theme';
// import { reportWebVitals } from './reportWebVitals';

import 'index.scss';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme = {theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
