import { FC, ReactElement } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors, sticker, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import { features } from 'pages/Lander/Features/FeaturesData';

import 'pages/Lander/Features/Features.scss';


const checkmark: ReactElement = <div data-testid='checkmark' className='check'><SVGIn url={svgIn.check.url} color={colors.brandGreen} /></div>;

const DetailsTableCell = styled(TableCell)({
  borderBottom: `1px solid ${colors.lightGray}`,
  color: colors.charcoalGray,
  fontSize: '14px',
  paddingBottom: '25px',
  paddingTop: '25px',
  textAlign: 'center',
  width: '186px',
});

const HighlightsTableCell = styled(TableCell)({
  borderBottom: `1px solid ${colors.lightGray}`,
  color: colors.mediumGray,
  fontSize: '14px',
  fontWeight: 400,
  paddingBottom: '22px',
  paddingTop: '8px',
  textAlign: 'center',
  verticalAlign: 'top',
});

export const Features: FC = () => {

  const subscriptionTypes = ['Pro District', 'Pro School', 'Pro Teacher', 'Basic'];

  return (
    <section data-testid="landing-block-features" className="features">
      <Typography variant="h2" data-testid="landing-title-features" align="center" sx={{ display: { xs: 'none', sm: 'block' }, mb: 9.75, mt: 15 }}>Compare Features</Typography>
      <Table data-testid="landing-table-features" sx={{ display: { xs: 'none', sm: 'block' }, margin: '78px auto 0', maxWidth: '1192px', padding: { sm: '0 20px', md: '0 50px', lg: 0 } }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 0 }}/>
            {subscriptionTypes.map((subscription, index) => {
              return (
                <TableCell
                  data-testid={`landing-thead-col${index + 1}`}
                  key={index}
                  sx={{ border: 0, color: colors.charcoalGray, fontSize: '18px', pb: 0, pt: 0, textAlign: 'center' }}
                >
                  {subscription}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell
              data-testid='landing-thead-col0'
              sx={{ borderBottom: `1px solid ${colors.lightGray}`, color: colors.charcoalGray, fontSize: '18px', width: '448px', pb: '25px', pl: 0, pt: '8px', verticalAlign: 'bottom' }}
            >
              Services and Features
            </TableCell>
            <HighlightsTableCell
              data-testid='landing-thead-col1-highlight'
              sx={{ color: colors.brandGreen }}
            >
              <div>
              Best Value
              </div>
              <img
                data-testid='landing-thead-col1-star'
                src={sticker.goldStar.url}
                className='gold-star'
                alt=""
              />
            </HighlightsTableCell>
            <HighlightsTableCell/>
            <HighlightsTableCell/>
            <HighlightsTableCell data-testid='landing-thead-col4-highlight'>Free</HighlightsTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature, index) => {
            return (
              <TableRow key={index}>
                <TableCell
                  data-testid={`landing-tcell-row${index}col0`}
                  sx={{ borderBottom: `1px solid ${colors.lightGray}`, color: colors.charcoalGray, fontSize: '16px', maxWidth: '448px', py: '25px' }}
                >
                  {feature.name}
                </TableCell>
                <DetailsTableCell data-testid={`landing-tcell-row${index}col1`}>
                  {feature.proDistrict && feature.proDistrictDescription && `${feature.proDistrictDescription}`}
                  {feature.proDistrict && !feature.proDistrictDescription && checkmark}
                </DetailsTableCell>
                <DetailsTableCell data-testid={`landing-tcell-row${index}col2`} sx={{ backgroundColor: colors.hoverGray }}>
                  {feature.proSchool && feature.proSchoolDescription && `${feature.proSchoolDescription}`}
                  {feature.proSchool && !feature.proSchoolDescription && checkmark}
                </DetailsTableCell>
                <DetailsTableCell data-testid={`landing-tcell-row${index}col3`}>
                  {feature.proTeacher && feature.proTeacherDescription && `${feature.proTeacherDescription}`}
                  {feature.proTeacher && !feature.proTeacherDescription && checkmark}
                </DetailsTableCell>
                <DetailsTableCell data-testid={`landing-tcell-row${index}col4`} sx={{ backgroundColor: colors.hoverGray }}>
                  {feature.basic && feature.basicDescription && `${feature.basicDescription}`}
                  {feature.basic && !feature.basicDescription && checkmark}
                </DetailsTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};
