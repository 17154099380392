import { FC } from 'react';

import { Icon } from '@mui/material';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import { CustomChip } from 'components/CustomChip/CustomChip';


export interface EmailChipsProps {
  onDelete: (email: string) => void;
  emails: string[];
  testIdLocation: string;
}


export const EmailChips: FC<EmailChipsProps> = ({
    onDelete,
    emails,
    testIdLocation,
  }) => {

  const renderEmailChips = () => {
    return emails.map((email: string, index) => {
      return (
        <CustomChip
          deleteIcon={
            <Icon data-testid={`chipDeleteIcon-${index}`}>
            <SVGIn
              url={svgIn.close.url}
              color={colors.brandGreen}
              emWidth={0.75}
            />
            </Icon>}
          key={`email-${index}`}
          label={email}
          onDelete={() => onDelete(email)}
          testId={`${index}`}
          testIdLocation={testIdLocation}
        />
        );
    });
  };

  return (
    <div className='chips-stack' data-testid='chips-stack'>
      {renderEmailChips()}
    </div>
  );
};
