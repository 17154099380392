import { useEffect, useRef, useState } from 'react';

import { Validator } from 'pages/Wizard/Steps/Validators';


export type CheckFn<T> = (value: T, messageValid: string, messageInvalid: string) => boolean;

export interface ErrorHook<T> {
  check: CheckFn<T>;
  error: boolean | null;
  message: string;
}

export const useError = <T>(value: T, validator: Validator): ErrorHook<T> => {
  const [error, setError] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string>('');
  const valueRef = useRef<T>(value);

  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
      setError(null);
      setMessage('Click out to check your entry.');
    }
  }, [value]);

  const check: CheckFn<T> = (value: T, messageValid: string, messageInvalid: string) => {
    const valid = validator(value);
    setError(!valid);
    setMessage(valid ? messageValid : messageInvalid);
    return valid;
  };

  return {
    check,
    error,
    message,
  };
};
