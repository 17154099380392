import { FC } from 'react';

import { Icon } from '@mui/material';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import { CustomChip } from 'components/CustomChip/CustomChip';
import { Organization } from 'types/Organization';

import 'pages/Wizard/Steps/SchoolInfoStep/SchoolInfoStep.scss';


export interface DistrictSchoolChipsProps {
  onDelete: (school: Organization) => void;
  schools: Organization[];
  testIdLocation: string;
}


export const DistrictSchoolChips: FC<DistrictSchoolChipsProps> = (props) => {
  const { onDelete, schools, testIdLocation } = props;
  return (
    <div className='chips-stack' data-testid='chips-stack'>
      {schools.map((school: Organization) => {
        return (
          <CustomChip
            deleteIcon={<Icon data-testid={'delete-icon'}><SVGIn url={svgIn.close.url} color={colors.brandGreen} emWidth={0.75} /></Icon>}
            key={school.pid}
            label={school.institution_name}
            onDelete={() => onDelete(school)}
            testId={`school${school.pid.toString()}`}
            testIdLocation={testIdLocation}
          />
        );
      })}
    </div>
  );
};
