import React, { ReactElement } from 'react';

import { Autocomplete, AutocompleteRenderOptionState, TextField } from '@mui/material';

import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash.debounce';

import { LoadState, performTypeAhead } from 'components/KickAhead/TypeAhead';
import { noOp } from 'shared/no-op';


export interface KickAheadProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  selection: T | null; // the selected item as a full object
  setSelection: (selection: T | null) => void; // fn for communicating a selection to the parent
  endpoint: string; // which API endpoint to hit when searching
  placeholder: string; // text to display when no search string is entered
  getOptionLabel: (option: T) => string; // converts T into a string for display in the entry box
  renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => React.ReactNode; // option card
  equalityKey: keyof T; // which property of T to use to compare text to full object
  extraParams?: Record<string, unknown>; // any additional params to send along with the search query
  queryKey?: string; // what name to use for the search query endpoint?queryKey=search+term
}

export function getUserPrompt(loadState: LoadState, inputValue: string): string {
  switch (loadState) {
    case 'idle':
      if (inputValue.length === 0) { return 'Type to search.'; }
      if (inputValue.length < 3) { return 'A few more letters, please.'; }
  }
  return 'No options';
}

export function KickAhead<T>({
  selection,
  setSelection,
  endpoint,
  placeholder,
  getOptionLabel,
  renderOption,
  equalityKey,
  extraParams,
  queryKey = 'name',
  style,
}: KickAheadProps<T>): ReactElement | null {

  const [value, setValue] = React.useState<T | null>(selection);
  const [loadState, setLoadState] = React.useState<LoadState>('idle');
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly T[]>([]);
  const cancelToken = React.useRef<CancelTokenSource | null>(null);
  const inputArg = React.useRef(inputValue);
  const fetchDistricts = React.useRef(noOp);
  const prevSelection = React.useRef<T | null>();

  React.useEffect(() => {
    // when the selection changes to null from the parent component
    // (ie, the selection wasn't null before, but it's about to become null)
    if (prevSelection.current !== null && selection === null) {
      // clear the values / input value
      setValue(null);
      setInputValue('');
    }
    prevSelection.current = selection;
  }, [selection]);

  React.useEffect(() => {
    // inside useEffect so this setup only happens once

    // fetchDistricts.current can be called many times in rapid succession
    // but we'll only execute the callback once for each burst of calls
    // (and then, only after 500ms has elapsed)
    fetchDistricts.current = debounce(() => {
      // cancel any active calls
      cancelToken.current?.cancel();

      // setup the next call to be cancellable
      cancelToken.current = axios.CancelToken.source();

      // execute the async query
      performTypeAhead<T>(
        endpoint,
        {
          [queryKey]: inputArg.current,
          ...extraParams,
        },
        cancelToken.current.token,
        setOptions,
        setLoadState,
      );
    }, 500);

    return () => {
      // cleanup any in-flight requests
      cancelToken.current?.cancel();
      // cleanup any pending debounces
      // types for lodash.debounce don't specify cancel(), but it's totally there
      // https://github.com/lodash/lodash/blob/master/debounce.js#L168-L174 and
      // https://github.com/lodash/lodash/blob/master/debounce.js#L207
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (fetchDistricts.current as any)?.cancel();
    };

  }, [endpoint, queryKey, extraParams]);

  React.useEffect(() => {
    // don't search if the input is empty
    if (inputValue === '') {
      // but set a default value if it exists
      setOptions(value ? [value] : []);
      return undefined;
    }

    // don't search if there is a selected option that matches the input
    if (value && inputValue === getOptionLabel(value)) {
      return undefined;
    }

    // don't search if the input string is too short
    if (inputValue.length < 3) {
      return undefined;
    }

    // go ahead and search
    setLoadState('loading');
    inputArg.current = inputValue;
    fetchDistricts.current();

  }, [value, inputValue, getOptionLabel]);

  // There are a lot of props here. Check the docs!
  // https://mui.com/components/autocomplete/
  // https://mui.com/api/autocomplete/
  return (
    <Autocomplete
      filterOptions={(x) => x}
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(event, newValue: T | null) => {
        setSelection(newValue);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          fullWidth />
      )}
      isOptionEqualToValue={(option, value) => { return option[equalityKey] === value[equalityKey]; } }
      renderOption={renderOption}
      noOptionsText={getUserPrompt(loadState, inputValue)}
      selectOnFocus
      handleHomeEndKeys
      clearOnEscape
      loading={loadState === 'loading'}
      style={{ ...style }}
    />
  );
}
