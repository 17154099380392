import React from 'react';

import { contact, link } from '@classkick/pkg-constants';

import { IFAQ } from 'pages/Quote/FAQ/IFAQ';
import { buildEmailLink, buildLink } from 'shared/links';


export type QuestionName =
  'expiration' |
  'submitPO' |
  'subscriptionStart' |
  'subscriptionEnd' |
  'w9' |
  'privacyTerms' |
  'soleSource' |
  'refund' |
  'numTeachers' |
  'numStudents' |
  'contactUs' |
  'activated' |
  'proTeacherPO';

export const faqData: Record<QuestionName, IFAQ> = {
  expiration: {
    question: 'Can I change the expiration date on my quote?',
    answer:
      <>
        Yes! Quotes are valid for 60 days.
        You can request an expiration date extension directly through your quote link.
        An extension request will extend your quote for an additional 30 days.
        You are allowed up to 3 expiration date extension requests.
        If you need additional time please reach out to {buildEmailLink(contact.proEmail, 'faq0-link-proEmail')}
      </>,
  },
  submitPO: {
    question: 'I have my Purchase Order, now what?',
    answer:
      <>
        Using the link in your quote, click “Submit Purchase Order” or “Purchase”.
        <br /><br />
        During the checkout, you'll be asked for additional information about the Purchasing Agent and Classkick Admin.
        The Purchasing Agent is the person who should receive the invoice.
        The Classkick Admin is the person who should receive the instructions for onboarding.
        <br /><br />
        The Classkick Admin must have a Classkick account in order to take control of the Pro account.
      </>,
  },
  subscriptionStart: {
    question: 'When does my subscription start?',
    answer:
      <>
        Your subscription starts either the day you purchase, or
        the future date listed on the quote once the quote has been purchased.
      </>,
  },
  subscriptionEnd: {
    question: 'When does my subscription end?',
    answer:
      <>
        Your subscription will end on the date listed on your quote.
        If you are purchasing a school or district subscription for the 2021-22 School Year,
        your subscription will end on June 30 2022. Our subscriptions are active through the end of the school year.
        Your subscription will be prorated for the remainder of the school year.
        <br /><br />
        If you are purchasing a Pro Teacher subscription,
        your subscription will activate when a Purchase Order is submitted and remain active for 365 days.
      </>,
  },
  w9: {
    question: 'How can I get a copy of the W-9?',
    answer: <>You can find our W-9 here: {buildLink(link.w9view, 'faq4-link-w9View')}</>,
  },
  privacyTerms: {
    question: 'What is the privacy policy and terms of service?',
    answer:
      <>
        You can find our terms of service here: {buildLink(link.termsOfService, 'faq5-link-termsOfService')}.
        <br /><br />
        You can find our privacy policy here: {buildLink(link.privacyPolicy, 'faq5-link-privacyPolicy')}.
      </>,
  },
  soleSource: {
    question: 'Do you have a sole source letter?',
    answer: <>Yes, you can find our sole source letter here: {buildLink(link.soleSourceLetter, 'faq6-link-soleSource')}</>,
  },
  refund: {
    question: 'Can teachers who had a subscription get a refund after purchasing a Pro School subscription?',
    answer:
      <>
        Where we offer a month-by-month subscription-based service,
        we will charge your account at the beginning of the term and at the beginning of each renewal term thereafter until you cancel.
        You must cancel your subscription before it renews to avoid the billing of the next term’s fees to your account.
        You will not receive a refund for any partial-term cancellation.
        <br /><br />
        You may cancel a prepaid ANNUAL account within 30 days of beginning your subscription for a complete refund.
        <br /><br />
        If you purchased an annual membership, and your school later upgraded to a Pro School membership,
        you may cancel for a prorated refund of up to $50.
        To do so, please email {buildEmailLink(contact.proEmail, 'faq7-link-proEmail')} and provide the complete account and school information.
      </>,
  },
  numTeachers: {
    question: 'How many teachers can use a Pro School subscription?',
    answer: <>Pro School subscriptions allow for unlimited teachers.</>,
  },
  numStudents: {
    question: 'How many students are included in a Pro School subscription?',
    answer:
      <>
        A Pro School subscription covers all students at your school.
        Pricing for Pro School varies based on the size of your school.
      </>,
  },
  contactUs: {
    question: 'How can I contact Classkick?',
    answer:
      <>
        You can contact Classkick by emailing {buildEmailLink(contact.proEmail, 'faq10-link-proEmail')} with your questions or booking a
        phone call with us here: {buildLink(link.calendlySalesOps, 'faq10-link-calendlySalesOps')}
      </>,
  },
  activated: {
    question: 'I sent my Purchase Order, why hasn’t my Pro subscription been activated?',
    answer:
      <>
        The quickest way to activate your subscription is to submit your Purchase Order information
        through your quote link. You can also email {buildEmailLink(contact.proEmail, 'faq11-link-proEmail')} and we will process your
        Purchase Order as soon as we can.
      </>,
  },
  proTeacherPO: {
    question: 'Why does the Pro Teacher subscription have different prices for Purchase Orders and credit cards?',
    answer:
      <>
        Paying by Purchase Order is a more manual process for our team and thus has a higher processing fee.
        The most cost effective way to purchase Pro Teacher is by upgrading directly through your Classkick account
        with a credit card.
        <br /><br />
        More detailed instructions can be found here: {buildLink(link.helpUpgradePro, 'faq12-helpUpgradePro-link')}
      </>,
  },
};

export const quoteFAQData: IFAQ[] = [
  faqData.expiration,
  faqData.submitPO,
  faqData.subscriptionStart,
  faqData.subscriptionEnd,
  faqData.w9,
  faqData.privacyTerms,
  faqData.soleSource,
  faqData.refund,
  faqData.numTeachers,
  faqData.numStudents,
  faqData.contactUs,
  faqData.activated,
  faqData.proTeacherPO,
];

export const landerFAQData: IFAQ[] = [
  faqData.expiration,
  faqData.numStudents,
  faqData.w9,
  faqData.privacyTerms,
  faqData.refund,
  faqData.contactUs,
];
