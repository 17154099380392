import { FC } from 'react';

import { colors, contact, svgIn } from '@classkick/pkg-constants';

import { IconInCircle } from 'components/IconInCircle/IconInCircle';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';

import 'pages/Wizard/Steps/ContactUsStep/ContactUsStep.scss';


export const ContactUsStep: FC<BaseStepProps> = () => {

  return (
    <>
      <p className='contact-us-details'>We offer custom promotions, quotes and set-up for groups of schools over 10. </p>
      <p className='contact-us-details'>
        Contact us at
        <a
          className='contact-email'
          data-testid='wizard-contact-email'
          href={`mailto:${contact.salesEmail}`}>
          <strong> {contact.salesEmail} </strong>
        </a>
      </p>
      <IconInCircle
        url={svgIn.school.url}
        color={colors.brandGreen}
      />
    </>
  );
};
