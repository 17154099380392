import { FC, ReactElement } from 'react';

import Chip from '@mui/material/Chip';

import { colors } from '@classkick/pkg-constants';


export interface CustomChipProps {
  color?: string;
  deleteIcon?: ReactElement;
  label: string;
  onDelete?: () => void;
  pid?: number;
  testId: string;
  testIdLocation: string;
}

export const CustomChip: FC<CustomChipProps> = (props) => {
  return <>
    <Chip
      data-testid={`${props.testIdLocation}-customChip-${props.testId}`}
      deleteIcon={props.deleteIcon}
      label={props.label}
      onDelete={props.onDelete}
      variant='outlined'
      sx={{
        borderColor: colors.white,
        borderRadius: '8px',
        boxShadow: '0px 0px 6px 2px rgba(64, 64, 65, 0.08)',
        color: props.color,
        fontSize: '16px',
        fontWeight: 400,
        justifyContent: 'flex-start',
        lineHeight: '19px',
        margin: '8px',
        padding: '20px 19px 20px 12px',
        textTransform: 'none',

        '& .MuiChip-deleteIcon': {
          marginLeft: '5px',
          marginTop: '-6px',
        },
      }}
    />
  </>;
};
