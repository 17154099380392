import { FC } from 'react';

import Typography from '@mui/material/Typography';

import { svgIn } from '@classkick/pkg-constants';

import { PaymentOption } from 'pages/Quote/Payment/PaymentOption';

import 'pages/Quote/Payment/PaymentDetail.scss';


export interface PaymentDetailProps {
  location: string;
  poPaymentURL: string;
  ccPaymentURL?: string;
}

export type PaymentFragment = 'credit-card' | 'purchase-order';

export const PaymentDetail: FC<PaymentDetailProps> = ({
  location,
  poPaymentURL,
  ccPaymentURL = '',
}) => {

  return (
    <div className="payment-detail" data-testid="quote-payment-detail">
      <Typography variant="h3">Pay Now or Later</Typography>
      <PaymentOption testId={`${location}-button-creditCard`} paymentURL={ccPaymentURL} iconURL={svgIn.creditcard.url} text="Credit Card" />
      <PaymentOption testId={`${location}-button-purchaseOrder`} paymentURL={poPaymentURL} iconURL={svgIn.purchaseorder.url} text="Submit Purchase Order" />
    </div>
  );
};
