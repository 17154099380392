import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { PNGAsset } from '@classkick/pkg-constants/dist/static-assets/png';
import { SVGArtAsset } from '@classkick/pkg-constants/dist/static-assets/svg-art';

import 'pages/Lander/Benefits/BenefitsCard/BenefitsCard.scss';


export interface BenefitsCardProps {
  image: SVGArtAsset;
  testDescriptor: string;
  text: string;
  title: string;
  halfSize?: boolean; // use when the artwork is narrower (integrations)
  reverseOrder?: boolean; // use when the artwork is on the left
  sticker?: PNGAsset; // use when the card has a sticker
  stickerPos?: stickerPosition; // use when the sticker needs to be elsewhere
}

export type stickerPosition = 'tl' | 'br';

export const BenefitsCard: FC<BenefitsCardProps> = ({
  image,
  testDescriptor,
  text,
  title,
  halfSize = false,
  reverseOrder = false,
  sticker = undefined,
  stickerPos = 'br',
}) => {
  return (
    <Grid
      className="benefit"
      container
      columns={{ xs: 6, md: 12 }}
      alignItems="center"
      justifyContent={{ xs: 'center', md: 'normal' }}
      direction={ reverseOrder ? 'row-reverse' : 'row' }
      mb={{ xs: 18, md: 22 }}
    >
      <Grid
        item
        xs={6}
        px={{ xs: 0, md: 6, lg: 8 }}
        mb={{ xs: 8, md: 0 }}
      >
        <Typography
          variant="h3"
          data-testid={`landing-title-${testDescriptor}`}
          mt={0}
          mb={2}
        >{title}</Typography>
        <Typography
          variant="body1"
          data-testid={`landing-text-${testDescriptor}`}
        >{text}</Typography>
      </Grid>

      <Grid item xs={halfSize ? 3 : 6}>
        <div
          className="art"
          style={{
            backgroundImage: `url(${image.url })`,
            // aspect ratio boxes : https://css-tricks.com/aspect-ratio-boxes/
            paddingBottom: `${image.height / image.width * 100}%`,
          }}>
          {
            sticker ?
              <img
                className={`art-sticker pos-${stickerPos}`}
                src={sticker.url}
                alt=""
              /> : null
          }
        </div>
      </Grid>
    </Grid>
  );
};
