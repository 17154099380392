import React, { FC, useEffect } from 'react';

import { MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { NumberOfSchools } from 'pages/Wizard/QuoteInput';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';
import { Direction, StepName } from 'pages/Wizard/Steps/Steps';
import { stringToInt } from 'shared/numbers';


export interface NumberOfSchoolsProps extends BaseStepProps {
  setStepName: (step: StepName) => void,
  setDirection: (direction: Direction) => void,
}

export const NumberOfSchoolsStep: FC<NumberOfSchoolsProps> = (props) => {
  const { quoteInput, setQuoteInput, setStepName, setDirection } = props;
  const [numberOfSchools, setNumberOfSchools] = React.useState<NumberOfSchools>('');

  useEffect(() => {
    quoteInput.numberOfSchools === '1' ? setNumberOfSchools('') : setNumberOfSchools(quoteInput.numberOfSchools);
  }, [quoteInput.numberOfSchools]);

  function handleSelection(event: unknown){
    const changeEvent = event as React.ChangeEvent;
    const numberOfSchools = (changeEvent.target as HTMLInputElement).value as NumberOfSchools;
    let schools = quoteInput.schools;
    // If the user is selecting a smaller number of schools than previously selected, reset schools on quoteInput
    if (stringToInt(numberOfSchools) < stringToInt(quoteInput.numberOfSchools) && schools.length > 0) {
      schools = [];
    }
    setQuoteInput({ ...quoteInput, numberOfSchools, schools });
    setNumberOfSchools(numberOfSchools);
  }

  return (
    <>
      <FormControl component='fieldset'>
        <Select
          data-testid='numSchoolsStep-select-schoolNum'
          value={numberOfSchools}
          variant='standard'
          style={{ width: '100px' }}
          onChange={handleSelection}
        >
            <MenuItem data-testid='numSchoolStep-option-2' value={'2'}>2</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-3' value={'3'}>3</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-4' value={'4'}>4</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-5' value={'5'}>5</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-6' value={'6'}>6</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-7' value={'7'}>7</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-8' value={'8'}>8</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-9' value={'9'}>9</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-10' value={'10'}>10</MenuItem>
            <MenuItem data-testid='numSchoolStep-option-11' value={'11+'}>11+</MenuItem>
        </Select>

      </FormControl>
      <div>
        <Button
          data-testid='numSchoolsStep-button-next'
          onClick={() => {
            setDirection('forward');
            const stepName = numberOfSchools === '11+' ? 'contactUs' : 'chooseDistrict';
            setStepName(stepName);
          }}
          style={{ marginTop: '70px' }}
          disabled={numberOfSchools === ''}
        >
          Next
        </Button>
      </div>
    </>
  );
};
