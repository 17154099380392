import React, { FC } from 'react';

import { Button } from '@mui/material';

import { colors, svgIn } from '@classkick/pkg-constants';

import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { IconInButton } from 'components/IconInButton/IconInButton';
import { QContactUsForm } from 'pages/Quote/QContactUsForm/QContactUsForm';
import { useFlag } from 'shared/useFlag/useFlag';
import { useModal } from 'shared/useModal/useModal';
import { useQuote } from 'shared/useQuote/useQuote';


export const QContactUsButton: FC = (props) => {
  const { isDialogOpen, openDialog, closeDialog } = useModal();
  const { quoteData } = useQuote();
  const shouldShowButton = useFlag('sb_contact_us');

  return shouldShowButton ?
    <>
      <Button
        className='header-contact'
        variant='text'
        data-testid='header-button-contactUs'
        onClick={openDialog}
        startIcon={
          <IconInButton
            testId={'header-contact-button-envelope-icon'}
            url={svgIn.envelope.url}
            color={colors.brandGreen}
            className='header-contact-logo'
            top={-5} /> }
      >
        Contact Us
      </Button>
      { quoteData ?
        <CustomDialog
          dialogContent={ <QContactUsForm quoteData={quoteData}  /> }
          open={isDialogOpen}
          onClose={closeDialog}

          // According to the figma mocks, all our modals should have this pixel width.
          // Right now, this is the only modal that has this pixel width.
          // Not sure if this is the best way to do this, but do we want to width to be consistent across all modals?
          // Do we want our modals to match the figma width?

          overrideStyle={{ width: 770, overflowY: 'hidden', maxWidth: 'unset' }}
          testId='header-dialog-contactUs'
          title='Contact Us'
        /> : null
      }
    </> : null;
};
