import React from 'react';


export const useModal = ():{isDialogOpen:boolean, openDialog:() => void, closeDialog:() => void} => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return { isDialogOpen, openDialog, closeDialog };
};
