export type PubSubHandler<K> = (argumentObject: K) => void;

export class PubSub<T> {
  handlers: PubSubHandler<T>[];

  constructor() {
    this.handlers = [];
  }

  addListener(handler: PubSubHandler<T>): () => void {
    const index = this.handlers.indexOf(handler);
    if (index === -1) {
      this.handlers.push(handler);
    }
    return () => {
      this.removeListener(handler);
    };
  }

  callAllHandlers(argumentObject: T): void {
    for (let i = 0; i < this.handlers.length; i++) {
      this.handlers[i](argumentObject);
    }
  }

  removeListener(handler: PubSubHandler<T>): void {
    const index = this.handlers.indexOf(handler);
    if (index !== -1) {
      this.handlers.splice(index, 1);
    }
  }
}
