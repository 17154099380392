import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGInAsset } from '@classkick/pkg-constants/dist/static-assets/svg-in';

import { IconInCircle } from 'components/IconInCircle/IconInCircle';
import { useFlag } from 'shared/useFlag/useFlag';


export interface EasyStep {
  title: string;
  text: string;
  icon: SVGInAsset;
}

const steps: EasyStep[] = [
  {
    title: 'Step 1',
    text: 'Create your quote',
    icon: svgIn.contract,
  },
  {
    title: 'Step 2',
    text: 'Purchase by credit card or purchase order',
    icon: svgIn.creditcard,
  },
  {
    title: 'Step 3',
    text: 'Finalize checkout by designating the account administrator',
    icon: svgIn.userSettings,
  },
];

export const Steps: FC = () => {
  const isCCActive = useFlag('sb_checkout_cc');

  return (
    <Box
      data-testid="landing-block-steps"
      component="section"
      sx={{
        backgroundColor: colors.hoverGray,
        padding: { xs: '100px' },
      }}
    >
      <Typography variant="h2" data-testid="landing-title-steps" align="center" mb={7} mt={0}>Purchase in 3 Easy Steps</Typography>

      <Grid container justifyContent="center">
        {
          steps.map((step, index) => {
            let realStep: EasyStep = step;

            if (index === 1 && !isCCActive) {
              realStep = {
                ...step,
                text: 'Purchase via purchase order',
                icon: svgIn.purchaseorder,
              };
            }

            return (
              <Grid item xs={12} md={4} lg={3} key={`steps-item-${index}`}>
                <Typography
                  variant="h3"
                  data-testid={`landing-title-step${index}`}
                  align="center"
                  mb="20px"
                  mt={{ xs: 10, md: 4 }}
                >{realStep.title}</Typography>
                <IconInCircle
                  url={realStep.icon.url}
                  bgColor={colors.white}
                  color={colors.brandGreen}
                  size={88}
                />
                <Typography
                  variant="body1"
                  data-testid={`landing-text-step${index}`}
                  align="center"
                  maxWidth="286px"
                  margin="20px auto 0"
                >{realStep.text}</Typography>
              </Grid>
            );
          })
        }
      </Grid>
    </Box>
  );
};
