import { FC } from 'react';

import { Typography } from '@mui/material';

import { contact } from '@classkick/pkg-constants';

import { buildEmailLink } from 'shared/links';


export const Error: FC = (props) => {

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h3" component="h1" sx={{ mt: 14 }}>We're Sorry!</Typography>
      <Typography variant="body1">We encountered a problem processing your order.</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>Please reach out to {buildEmailLink(contact.proEmail, 'error-proEmail-link')}.</Typography>
    </div>
  );
};
