import { stringToInt } from 'shared/numbers';
import { Organization } from 'types/Organization';


export type Validator = (input: unknown) => boolean;

const isEmailValid: Validator = (input: unknown) => {
  if (typeof input !== 'string') {
    return false;
  }

  const atIndex = input.indexOf('@');
  const dotIndex = input.indexOf('.', atIndex);
  return atIndex > 0 &&
         dotIndex > atIndex + 1 &&
         dotIndex < input.length - 1;
};

const isValidSelectionOfSchools = (numberOfSchools: string, schools: Organization[]): boolean => {
  const numberOfSchoolsInt = stringToInt(numberOfSchools);
  return schools.length === numberOfSchoolsInt;
};

const isValueSet: Validator = (input: unknown) => {
  const invalids: unknown[] = [undefined,null,''];
  return !invalids.includes(input);
};

export const validators = {
  isEmailValid,
  isValidSelectionOfSchools,
  isValueSet,
};
