export const quoteOnlyList: string[] = [
  // sourced from https://docs.google.com/spreadsheets/d/1O3D5XPDnvr4S6ZnMfIbZbrfzTo-Nt5ZomoaTFc0Nzi8/edit#gid=1960490832
  '1ecb20ab7b27664fad1fcb2ce6f3b8c2',
  '1ecb20b9b28e676197c2906c5f81ac9a',
  '1ecb214890d1608fb87d15f22139d025',
  '1ecb2149ab26682c803768f2d6081264',
  '1ecb214a3db768a383682d63ed36f9d6',
  '1ecb214ac044668bb52c4d3ac7fe90c5',
  '1ecb214c07016223a3dc4937573ec077',
  '1ecb214cd9c06cc2bcf43acdac997957',
  '1ecb214f8fe562d38203fc10632813e2',
  '1ecb215142026563aaf837340dbac9fb',
  '1ecb2153cdc160cfbbd69323b898a693',
  '1ecb21555e1066df949e191ab221e3a4',
  '1ecb2155738161ea96f1556d65f4c5d7',
  '1ecb2159b1c9695eaa7c847a1b8c959c',
  '1ecb2159e96f6c048502d599aab8251c',
  '1ecb215ac17663dda6dd0fe12731d27b',
  '1ecb215d9afb6c2ab95b9596ca5e08bb',
  '1ecb2161092766479e4a931d02f594ee',
  '1ecb2162459466d09000e0fa9007b9c1',
  '1ecb21629e5e64018b5d03b4d13a2732',
  '1ecb2162fe1f6a6b87462f2b90df8830',
  '1ecb2163dffa6c7c965c00312a25f376',
  '1ecb2164b35b601c86ebfafba5be4e11',
  '1ecb2164d13f616bbc3816753a4d0fb3',
  '1ecb216dedca6542810ddef900e82a53',
  '1ecb216f7aaf653f8b85434f642104b4',
  '1ecb216f939468a08ea546fb1d13a04b',
  '1ecb217185306f1c9e1c96cd4e9061a4',
  '1ecb2171d6526669af7fed6f6b2e0169',
  '1ecb217224f6627f9824d61ad1414fe9',
  '1ecb217264d862d588f4c48cd1946cf3',
  '1ecb2173da576ae4886f6e9d91911270',
  '1ecb2174f5eb60679603b1a07d2b18f9',
  '1ecb2175146e6d3082ec584773107dd0',
  '1ecb21781a2c69d8b1042b56536ce34e',
  '1ecb2178af99612a85d64d228609715f',
  '1ecb217a461264828342a16485c5d3cd',
  '1ecb217b6f3460068ab1e30e464b3d70',
  '1ecb217bc7396b13a0998d42d530ca8f',
  '1ecb217f50946298be1b3fb132d73f11',
  '1ecb217fb9216a2e8871c34803f6ce7b',
  '1ecb2181c5e065ef9046d3a1a6e80fa7',
  '1ecb2181f1446003a42738114f72012f',
  '1ecb21826fdf6a1e87fbc62b1535770f',
  '1ecb21829ce26144bb07045a91894d77',
  '1ecb21832a936b8b9bd7c76b44af3f0a',
  '1ecb2183ceb760648c5a00b2fbbb6d31',
  '1ecb2184561661a181d6247fec4958db',
  '1ecb218918d964f08af109ef35ee808a',
  '1ecb218b5e0a601789fc6c6a7e51d5dc',
  '1ecb218d403069db999e66a9cf85dbac',
  '1ecb218d87e564eda06b80133c5487cd',
  '1ecb218d918363568bff2df6113901f2',
  '1ecb218de3c26342ac6ad2c82e47a916',
  '1ecb218fc0056f04946a91d4078307be',
  '1ecb218fe3bf633b928d12142822de8b',
  '1ecb219077e0644ab77ac6e3268fb9de',
  '1ecb2191c1056db1b0dd03eb477c0b0b',
  '1ecb21952cd1661baadc47f8f8617378',
  '1ecb2195884f6004b9e33f6896265d5c',
  '1ecb2197cd8e6445a7da4c067b6431ad',
  '1ecb2197d5ab6247affae1d116d55392',
  '1ecb219802c865a29087171afdce1f60',
  '1ecb2198606b60dd9bbe3c354c51b574',
  '1ecb2198c96d6cda9bb88f833007647a',
  '1ecb2198dbdc6135bc6c5743e968f222',
  '1ecb21994ea66455930cad591ea0e922',
  '1ecb2199859f6b769ca0c8ac4f7c23b4',
  '1ecb2199951864a286ded7cad376ada3',
  '1ecb2199db0269fbb71afd20c561f65e',
  '1ecb219ac39467989a48a4fcc88dc879',
  '1ecb219ad9446e1289e8cbda58d875ec',
  '1ecb219b0695652cb37f5307d50a9edb',
  '1ecb219b16f76665991a7dcbf215c7fe',
  '1ecb219b1f8c6e6da4db175ac511e8c7',
  '1ecb219b3cf56ee3bba60513dc4aa502',
  'fakerenewal',
];

export const onQuoteOnlyList = (id: string): boolean => {
  const dashless = id.replace(/-/g, '');
  return quoteOnlyList.indexOf(dashless) > -1;
};
