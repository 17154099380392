/* istanbul ignore file */
import React from 'react';
import { Navigate } from 'react-router-dom';

import { Congrats } from 'pages/Checkout/Congrats/Congrats';
import { CreditCard } from 'pages/Checkout/CreditCard/CreditCard';
import { Error } from 'pages/Checkout/Error/Error';
import { IRoute } from 'interfaces/route';
import { Landing } from 'pages/Lander/Landing';
import { PurchaseOrder } from 'pages/Checkout/PurchaseOrder/PurchaseOrder';
import { Quote } from 'pages/Quote/Quote';
import { Wizard } from 'pages/Wizard/Wizard';
import { Renewal } from 'pages/Renewal/Renewal';
import { QContactUsButton } from '../pages/Quote/QContactUsButton/QContactUsButton';
import { NavLinks } from 'pages/Lander/NavLinks/NavLinks';


export const routes: IRoute[] = [
  {
    path: '/',
    name: 'Landing Page',
    component: Landing,
    headerSlot: NavLinks,
  },
  {
    path: '/order',
    name: 'Quote Wizard Page',
    component: Wizard,
  },
  {
    path: '/quote/:quote_id/purchase-order',
    name: 'Checkout : Purchase Order',
    component: PurchaseOrder,
    headerSlot: QContactUsButton,
  },
  {
    path: '/quote/:quote_id/credit-card',
    name: 'Checkout : Credit Card Payment',
    component: CreditCard,
    headerSlot: QContactUsButton,
  },
  {
    path: '/quote/:quote_id/congratulations',
    name: 'Checkout : Congratulations',
    component: Congrats,
    headerSlot: QContactUsButton,
  },
  {
    path: '/quote/:quote_id/error',
    name: 'Checkout : Error',
    component: Error,
    headerSlot: QContactUsButton,
  },
  {
    path: '/quote/:quote_id',
    name: 'Quote Page',
    component: Quote,
    headerSlot: QContactUsButton,
  },
  {
    path: '/renew/:quote_id',
    name: 'Renewal',
    component: Renewal,
  },
  {
    path: '*',
    name: 'Unknown',
    component: () => <Navigate to='/'/>,
  }
]
