import { FC } from 'react';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import 'components/LoadingSpinner/LoadingSpinner.scss';


export type LoadingSpinnerProps = {
  testLocation: string;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  const { testLocation } = props;

  return (
    <div className='spinner' data-testid={`${testLocation}-spinner-loading`}>
      <SVGIn url={svgIn.hand.url} color={colors.brandGreen} />
    </div>
  );
};
