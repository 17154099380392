import { FC } from 'react';

import { colors, contact, svgIn } from '@classkick/pkg-constants';

import { IconInCircle } from 'components/IconInCircle/IconInCircle';
import { BaseStepProps } from 'pages/Wizard/Steps/BaseStepProps';

import 'pages/Wizard/Steps/UnknownSchoolStep/UnknownSchoolStep.scss';


export const UnknownSchoolStep: FC<BaseStepProps> = () => {

  return (
    <>
      <p className='contact-us-details'>We are sorry your school isn't on our list. Let us create a quote for you!</p>
      <p className='contact-us-details'>
        Please contact us at
        <a
          className='contact-email'
          data-testid='wizard-contact-email'
          href={`mailto:${contact.proEmail}`}>
          <strong> {contact.proEmail} </strong>
        </a>
      </p>
      <IconInCircle
        url={svgIn.school.url}
        color={colors.brandGreen}
      />
    </>
  );
};
