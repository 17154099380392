import { FC, ReactElement, useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { AxiosResponse } from 'axios';

import { contact } from '@classkick/pkg-constants';

import { quote } from 'api/quote';
import { QuoteExtended } from 'api/quote/extend';
import { APIResponse } from 'api/response';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';

import 'pages/Quote/Quote.scss';


export interface QuoteExtensionProps {
  closeDialog: () => void;
  currentExtension: number;
  maxExtensions: number
  publicId: string;
  quoteType: SubscriptionType;
  reload: () => void;
}

export const RequestQuoteExtension: FC<QuoteExtensionProps> = (props) => {
  const { closeDialog, currentExtension, maxExtensions, publicId, quoteType, reload } = props;
  const [ error, setError ] = useState<boolean>(false);

  const extensionDialogCopy = (): ReactElement=> {
    if (error) {
      return (
        <div data-testid="extension-request-error">
          We have encountered an error trying to process your request. Please try again later or contact us at
          <a
            className='contact-email'
            data-testid='quote-contact-email'
            target='_blank'
            rel='noreferrer'
            href={`mailto:${contact.proEmail}`}>
            <strong> {contact.proEmail}</strong>
          </a> if the issue continues.
        </div>
      );
    }
    if (currentExtension === maxExtensions) {
      return (
        <div data-testid="max-extensions-request">
          You have reached your extension request limit. If you need additional support please reach out to
          <a
            className='contact-email'
            data-testid='quote-contact-email'
            target='_blank'
            rel='noreferrer'
            href={`mailto:${contact.proEmail}`}>
            <strong> {contact.proEmail}</strong>
          </a>.
        </div>
      );
    } else if (currentExtension === maxExtensions - 1) {
      return (
        <div data-testid="final-extension-request">
          Quotes are limited to {maxExtensions} expiration extension requests. This will be your final quote expiration extension. If you need additional support please reach out to
          <a
            className='contact-email'
            data-testid='quote-contact-email'
            target='_blank'
            rel='noreferrer'
            href={`mailto:${contact.proEmail}`}>
            <strong> {contact.proEmail}</strong>
          </a>.
        </div>
      );
    } else {
      return (
        <div data-testid="extension-request">This will extend your quote for 30 days. You may only do this {maxExtensions} times.</div>
      );
    }
  };

  const submitQuoteExtension = () => {
    quote.extend(quoteType, publicId).then(
      (response: AxiosResponse<APIResponse<QuoteExtended>>) => {
        closeDialog();
        reload();
    }, (error) => {
      setError(true);
    });
  };

  return (
    <div className="quote-modal-content">
      <Typography variant="body1" mb={4} sx={{ maxWidth: '615px' }} component="div">{extensionDialogCopy()}</Typography>
      {
        currentExtension < maxExtensions && !error &&
        <Button
          className="quote-modal-button"
          data-testid='submit-quote-extension-button'
          onClick={() => submitQuoteExtension()}
        >
          Extend
        </Button>
      }
    </div>
  );
};
