import { FC } from 'react';

import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { QuoteData } from 'pages/Quote/QuoteData';
import { formatDateSlashes } from 'shared/dates';

import 'pages/Checkout/QuoteStatus/QuoteStatus.scss';


export type QuoteStatusProps = {
  quoteData: QuoteData;
};

export const computeMessage = (quoteData: QuoteData): string => {
  let message = 'An unknown error has occurred.';

  switch (quoteData.status) {
    case 'invoiced':
      message = `It was already invoiced on ${formatDateSlashes(quoteData.invoiced_at as Date)}.`;
      break;
    case 'expired':
      message = `It expired on ${formatDateSlashes(quoteData.expired_at as Date)}.`;
      break;
    case 'canceled':
      message = 'It was canceled.';
      break;
    default:
      break;
  }

  return message;
};

export const QuoteStatus: FC<QuoteStatusProps> = (props) => {

  const { quoteData } = props;

  const message = computeMessage(quoteData);

  return (
    <Box className="quote-status" sx={{ p: 4, pt: 8, textAlign: 'center' }}>
      <Typography
        data-testid="quoteStatus-text-title"
        variant="h4"
        component="h1"
      >Sorry! You cannot continue to checkout with this quote.</Typography>
      <Typography
        data-testid="quoteStatus-text-reason"
        variant="body1"
      >{message}</Typography>
      <div className="links">
        <Link
          data-testid="quoteStatus-link-quote"
          to={`/quote/${quoteData.public_id}`}>View Quote #{quoteData.quote_num}</Link>
        <Link
          data-testid="quoteStatus-link-pro"
          to="/">Classkick Pro</Link>
        <Link
          data-testid="quoteStatus-link-order"
          to="/order">Create a New Quote</Link>
        <a
          data-testid="quoteStatus-link-home"
          href="https://classkick.com">Home</a>
      </div>
    </Box>
  );
};
