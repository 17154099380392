import { FC } from 'react';

import { colors, svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import 'components/LogoType/LogoType.scss';


export type LogoTypeProps = {
  size?: number
};

export const LogoType: FC<LogoTypeProps> = ({
  size = 32,
}) => {

  return (
    <div
      className="logo-type"
      data-testid="logoType-classkickLogo-image"
      style={{ fontSize: `${size}px`, position: 'absolute' }}
    >
      <SVGIn url={svgIn.hand.url} color={colors.brandGreen}/>
      <span className="type">classkick</span>
    </div>
  );
};
