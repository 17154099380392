import { FC } from 'react';

import Typography from '@mui/material/Typography';

import { timestampToMDY } from 'shared/dates';


export type SubscriptionDetailProps = {
  billingStart: Date,
  billingEnd: Date,
};

export const SubscriptionDetail: FC<SubscriptionDetailProps> = (props) => {
  const { billingStart, billingEnd } = props;


  return (
    <>
      <Typography variant="subtitle2" data-testid="quote-subTermsCard-contentTitle">
        Billing Start Date - End Date
      </Typography>
      <Typography variant="body1">
        {timestampToMDY(billingStart.getTime())} - {timestampToMDY(billingEnd.getTime())}
      </Typography>
    </>
  );
};
