import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { sticker } from '@classkick/pkg-constants';

import { HeroCard } from 'pages/Lander/Hero/HeroCard/HeroCard';


export const HeroBanner: FC = () => {
  return (
    <Box sx={{
      backgroundColor: 'black',
      marginBottom: { xs: '48px', lg: '391px' },
      maxHeight: {
        lg: '379px',
      },
    }}>
      <Typography
        align='center'
        color='white'
        variant='h1'
        data-testid='landing-title-hero'
        sx={{
          fontSize: { xs: '28px', md: '32px', lg: '40px' },
          padding: { xs: '60px 0 32px', md: '60px 0 80px', lg: '156px 0 84px', xl: '153px 0 104px' },
        }}
      >
        Which Pro is right for you?
      </Typography>
      <Stack
        alignItems='center'
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent='center'
        spacing={4}
        sx={{ position: { lg: 'relative' }, paddingBottom: { xs: '32px', sm: '40px' }  }}
      >
        <HeroCard
          buttonText='Create Quote'
          buttonTo='/order?type=district'
          flagImg={sticker.goldStar}
          flagText='Best Value'
          footnoteText='*average pricing–varies based on school enrollment'
          hasFlag={true}
          priceText='$265'
          splitText='per school or $3,180/yr'
          subTitleText='For 2+ schools in a district'
          testId='district'
          title='District'
        />
        <HeroCard
          buttonText='Create Quote'
          buttonTo='/order?type=school'
          footnoteText='*average pricing–varies based on school enrollment'
          priceText='$275'
          splitText='per school or $3,300/yr'
          subTitleText='For a single school'
          testId='school'
          title='School'
        />
        <HeroCard
          buttonText='Upgrade Now'
          buttonTo='https://app.classkick.com/#/account/upgrade'
          externalLink={true}
          footnoteText='*purchase orders include an extra processing fee'
          priceText='$17.99'
          splitText='$156/yr, billed monthly on a credit card'
          styledButton={true}
          subTitleText='For a single teacher'
          testId='teacher'
          title='Teacher'
        />
      </Stack>
    </Box>
  );
};
