import React from 'react';

/**
 * HEAVILY inspired by https://css-tricks.com/using-requestanimationframe-with-react-hooks/
 * Hook that repeatedly calls the provided callback.
 * @param callback - function to execute every frame
 */
export const useAnimationFrame = (callback: () => void): void => {
  const raf_id = React.useRef<number>(-1);

  const animate = () => {
    callback();
    raf_id.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    raf_id.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(raf_id.current);
  });
};
