import { FC } from 'react';

import { Kickput } from 'components/Kickput/Kickput';
import { POCheckoutData } from 'pages/Checkout/Checkout';


export type InfoPOProps = {
  checkoutData: POCheckoutData,
  setCheckoutData: (data: POCheckoutData) => void,
};

export const InfoPO: FC<InfoPOProps> = (props) => {
  const { checkoutData, setCheckoutData } = props;

  const updateData = (value: string, dataKey: keyof POCheckoutData) => {
    setCheckoutData({
      ...checkoutData,
      [dataKey]: value,
    });
  };

  return (
    <>
      <Kickput<POCheckoutData>
        dataKey="poNumber"
        label="Purchase Order Number"
        placeholder="123456789"
        updateData={updateData}
        wholeData={checkoutData}
        maxLength={50}
        testId="poCheckout-input-poNumber"
      />
    </>
  );
};
