import axios, { AxiosResponse } from 'axios';

import { buildEndpoint } from 'api/endpoints';
import { APIResponse } from 'api/response';
import { POCheckoutData } from 'pages/Checkout/Checkout';
import { SubscriptionType } from 'pages/Wizard/QuoteInput';


export interface OrderCreatePayload {
  classkick_admin_email: string;
  po_number: string;
  purchasing_agent_name: string;
  purchasing_agent_email: string;
}

export interface OrderCreated {
  quote_id: string;
}

export const create = (quoteType: SubscriptionType, publicId: string, orderData: POCheckoutData): Promise<AxiosResponse<APIResponse<OrderCreated>>> => {
  const payload: OrderCreatePayload = {
    classkick_admin_email: orderData.purchaser.adminEmail,
    po_number: orderData.poNumber,
    purchasing_agent_name: orderData.purchaser.agentName,
    purchasing_agent_email: orderData.purchaser.agentEmail,
  };
  const endpoint = buildEndpoint.createOrder(quoteType, publicId);
  return axios.post<APIResponse<OrderCreated>>(endpoint, payload);
};
