import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { svgIn } from '@classkick/pkg-constants';
import { SVGIn } from '@classkick/pkg-svgin';

import { Direction, StepName } from 'pages/Wizard/Steps/Steps';
import 'pages/Wizard/StepFrame/StepFrame.scss';


export type StepFrameProps = {
  title: string,
  currentStep: StepName,
  myStep: StepName,
  nextStep: StepName | null,
  prevStep: StepName | null,
  isValid: boolean,
  setStepName: (step: StepName) => void,
  setDirection: (direction: Direction) => void,
  testId?: string,
};

export const StepFrame: FC<StepFrameProps> = ({
  title,
  children,
  currentStep,
  myStep,
  nextStep,
  prevStep,
  isValid,
  setDirection,
  setStepName,
  testId }) => {
  const nodeRef = React.useRef(null);


  const handleClick = () => {
    setDirection('forward');
    setStepName(nextStep as StepName);
  };

  return (
    <CSSTransition
      key={myStep}
      nodeRef={nodeRef}
      classNames='slide'
      timeout={300}
      in={currentStep === myStep}
      appear={true}
    >
      <Stack
        className="slide-target"
        ref={nodeRef}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        data-testid={`${testId}-stepFrame-root`}
      >
        <div className="step-title">
          <IconButton
            className={`btn-back ${ !prevStep ? 'hidden' : '' }`}
            data-testid={`${testId}-button-back`}
            onClick={() => { setDirection('back'); setStepName(prevStep as StepName); }}
            disabled={!prevStep}
            color="primary"
            size="large"
          >
            <SVGIn url={svgIn.back.url}/>
          </IconButton>
          <Typography variant="h2" data-testid={`${testId}-title-text`}>
            {title}
          </Typography>
        </div>
        <Stack direction='column' alignItems='center'
        >
          {children}
        </Stack>
        { nextStep !== null &&
          <Button
            data-testid={`${testId}-button-next`}
            onClick={handleClick}
            disabled={!nextStep || !isValid}
            style={{ marginTop: '70px' }}
          >Next</Button>
        }
      </Stack>
    </CSSTransition>
  );
};
