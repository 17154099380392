import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import { Typography } from '@mui/material';

import useAxios from 'axios-hooks';

import { endpoints } from 'api/endpoints';
import { quote } from 'api/quote';
import { APIResponse } from 'api/response';
import { EventName, captureEvent } from 'components/PostHog/PostHog';
import { Progress } from 'components/Progress/Progress';
import { QuoteInput } from 'pages/Wizard/QuoteInput';

import { StepFrame } from 'pages/Wizard/StepFrame/StepFrame';
import { ChooseDistrictStep } from 'pages/Wizard/Steps/ChooseDistrictStep/ChooseDistrictStep';
import { ContactUsStep } from 'pages/Wizard/Steps/ContactUsStep/ContactUsStep';
import { CreatorStep } from 'pages/Wizard/Steps/CreatorStep/CreatorStep';
import { IndySchoolStep } from 'pages/Wizard/Steps/IndySchoolStep/IndySchoolStep';
import { NumberOfSchoolsStep } from 'pages/Wizard/Steps/NumberOfSchoolsStep/NumberOfSchoolsStep';
import { SchoolInfoStep } from 'pages/Wizard/Steps/SchoolInfoStep/SchoolInfoStep';
import { StartSubscriptionStep } from 'pages/Wizard/Steps/StartSubscriptionStep/StartSubscriptionStep';
import { Direction, StepName, progress } from 'pages/Wizard/Steps/Steps';
import { SubmitErrorStep } from 'pages/Wizard/Steps/SubmitErrorStep/SubmitErrorStep';
import { SubscriptionPeriodStep } from 'pages/Wizard/Steps/SubscriptionPeriodStep/SubscriptionPeriodStep';
import { SubscriptionTypeStep } from 'pages/Wizard/Steps/SubscriptionTypeStep/SubscriptionTypeStep';
import { UnknownSchoolStep } from 'pages/Wizard/Steps/UnknownSchoolStep/UnknownSchoolStep';
import { validators } from 'pages/Wizard/Steps/Validators';
import { getDefaultSubscriptionStartDate } from 'shared/dates';
import { PricingInfo } from 'types/PricingInfo';

import 'pages/Wizard/SlideTransition.scss';
import 'pages/Wizard/Wizard.scss';


function renderSteps(
  creatorValid: boolean,
  quoteError: boolean,
  currentStep: StepName,
  quoteInput: QuoteInput,
  setCreatorValid: (valid: boolean) => void,
  setStepName: (step: StepName) => void,
  setDirection: (direction: Direction) => void,
  setQuoteInput: (quoteInput: QuoteInput) => void,
  submitQuoteInfo: () => void): ReactNode {
  // Coverage Note:
  // Some conditional nextStep and prevStep props below are ignored from coverage.
  // These cases are well-covered with e2e tests.
  return <>
    <StepFrame
      title="What's your name?"
      currentStep={currentStep}
      myStep='creator'
      nextStep='subscriptionType'
      prevStep={null}
      isValid={creatorValid}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="creatorStep"
    >
      <CreatorStep
        quoteInput={quoteInput}
        setCreatorValid={setCreatorValid}
        setQuoteInput={setQuoteInput}
      />
    </StepFrame>

    <StepFrame
      title="I'm interested in Pro for a:"
      currentStep={currentStep}
      myStep='subscriptionType'
      nextStep={
        // See "Coverage Note" at top of method.
        /* istanbul ignore next */
        quoteInput.subscriptionType === 'school' ? 'chooseDistrict': 'numberOfSchools'
      }
      prevStep='creator'
      isValid={validators.isValueSet(quoteInput.subscriptionType)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="subscriptionTypeStep"
    >
      <SubscriptionTypeStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
      />
    </StepFrame>

    <StepFrame
      title="How many schools will be covered?"
      currentStep={currentStep}
      myStep='numberOfSchools'
      nextStep={null}
      prevStep='subscriptionType'
      isValid={validators.isValueSet(quoteInput.numberOfSchools)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="numberOfSchoolsStep"
    >
      <NumberOfSchoolsStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
        setStepName={setStepName}
        setDirection={setDirection}/>
    </StepFrame>
    <StepFrame
      title="What's your district?"
      currentStep={currentStep}
      myStep='chooseDistrict'
      nextStep='chooseSchools'
      prevStep={
        // See "Coverage Note" at top of method.
        /* istanbul ignore next */
        quoteInput.subscriptionType === 'school' ? 'subscriptionType' : 'numberOfSchools'
      }
      isValid={validators.isValueSet(quoteInput.organization)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="chooseDistrictStep"
    >
      <ChooseDistrictStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
        setStepName={setStepName}
        setDirection={setDirection}
      />
    </StepFrame>
    <StepFrame
      title="What's your school?"
      currentStep={currentStep}
      myStep='chooseIndySchool'
      nextStep='startSubscription'
      prevStep={'chooseDistrict'}
      isValid={validators.isValidSelectionOfSchools(quoteInput.numberOfSchools, quoteInput.schools)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="chooseIndySchool"
    >
      <IndySchoolStep
        quoteInput={quoteInput}
        setDirection={setDirection}
        setQuoteInput={setQuoteInput}
        setStepName={setStepName}
      />
    </StepFrame>
    <StepFrame
      title={
        // See "Coverage Note" at top of method.
        /* istanbul ignore next */
        quoteInput.numberOfSchools === '1' ? 'What\'s your school?' : 'What are your schools?'}
      currentStep={currentStep}
      myStep='chooseSchools'
      nextStep='startSubscription'
      prevStep='chooseDistrict'
      isValid={validators.isValidSelectionOfSchools(quoteInput.numberOfSchools, quoteInput.schools)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId='schoolInfoStep'
    >
      <SchoolInfoStep
        quoteInput={quoteInput}
        setDirection={setDirection}
        setQuoteInput={setQuoteInput}
        setStepName={setStepName}
      />
    </StepFrame>

    <StepFrame
      title="Contact Us for a Quote"
      currentStep={currentStep}
      myStep='unknownSchool'
      nextStep={null}
      prevStep={
        // See "Coverage Note" at top of method.
        /* istanbul ignore next */
        quoteInput.organization === null ? 'chooseIndySchool' : 'chooseSchools'
      }
      isValid={true}
      setStepName={setStepName}
      setDirection={setDirection}
    >
      <UnknownSchoolStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
        />
    </StepFrame>

    <StepFrame
      title="Contact Us for a Quote"
      currentStep={currentStep}
      myStep='contactUs'
      nextStep={null}
      prevStep='numberOfSchools'
      isValid={true}
      setStepName={setStepName}
      setDirection={setDirection}
    >
      <ContactUsStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
        />
    </StepFrame>

    <StepFrame
      title="When should your subscription start?"
      currentStep={currentStep}
      myStep='startSubscription'
      nextStep='subscriptionPeriod'
      prevStep={
        // See "Coverage Note" at top of method.
        /* istanbul ignore next */
        quoteInput.organization === null ? 'chooseIndySchool' : 'chooseSchools'
      }
      isValid={validators.isValueSet(quoteInput.startSubscription)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="startSubscriptionStep"
    >
      <StartSubscriptionStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
      />
    </StepFrame>

    <StepFrame
      title="When should your subscription end?"
      currentStep={currentStep}
      myStep='subscriptionPeriod'
      nextStep={null}
      prevStep='startSubscription'
      isValid={validators.isValueSet(quoteInput.subscriptionPeriod)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="subscriptionPeriodStep"
    >
      <SubscriptionPeriodStep
        quoteInput={quoteInput}
        setQuoteInput={setQuoteInput}
        submitQuoteInfo={submitQuoteInfo}
      />
    </StepFrame>

    <StepFrame
      title="Something went wrong..."
      currentStep={currentStep}
      myStep='submitError'
      nextStep={null}
      prevStep={null}
      isValid={validators.isValueSet(quoteInput.subscriptionPeriod)}
      setStepName={setStepName}
      setDirection={setDirection}
      testId="submitErrorStep"
    >
      <SubmitErrorStep
        quoteInput={quoteInput}
        quoteError={quoteError}
        setQuoteInput={setQuoteInput}
        submitQuoteInfo={submitQuoteInfo}
      />
    </StepFrame>
  </>;
}

const deadEndSteps: StepName[] = ['contactUs', 'unknownSchool', 'submitError'];

export const Wizard: FC = () => {
  const [stepName, setStepName] = useState<StepName>('creator');
  const [direction, setDirection] = useState<Direction>('forward');
  const [creatorValid, setCreatorValid] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [quoteError, setQuoteError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const previousStep = useRef<null | StepName>(null );

  useEffect(() => {
    if (previousStep.current && direction === 'forward') {
      captureEvent(`${previousStep.current}:wizardStep_completed` as EventName, {});
    }

    if (deadEndSteps.includes(stepName)) {
      captureEvent(`${stepName}:wizardStep_completed` as EventName, {});
    }

    previousStep.current = stepName;
  }, [complete, direction, stepName]);

  const [quoteInput, setQuoteInput] = useState<QuoteInput>({
    subscriptionType: '',
    creator: {
      name: '',
      title: '',
      phone: '',
      email: '',
    },
    numberOfSchools: '',
    organization: null,
    schools: [],
    startSubscription: getDefaultSubscriptionStartDate(),
    subscriptionPeriod: '',
  });

  const [{ data: pricing, error }] = useAxios<APIResponse<PricingInfo>>(endpoints.pricingCurrent);

  useEffect(() => {
    const subType = searchParams.get('type');
    // This behavior is better covered in e2e (lander.feature)
    /* istanbul ignore next */
    if (quoteInput.subscriptionType === '' && subType !== null) {
      if (subType === 'school') {
        setQuoteInput({
          ...quoteInput,
          numberOfSchools: '1',
          subscriptionType: 'school',
        });
      }
      if (subType === 'district') {
        setQuoteInput({
          ...quoteInput,
          subscriptionType: 'district',
        });
      }
    }
  }, [searchParams, quoteInput]);

  const handleSubmitError = () => {
    setDirection('forward');
    setStepName('submitError');
    setQuoteError(true);
  };

  /* istanbul ignore next */
  const submitQuoteInfo = () => {
    if (pricing?.data) {
      setComplete(true);
      setQuoteError(false);
      try {
        quote.create(quoteInput, pricing.data).then(
          (response) => { // success
            const quote_id = response.data.data?.quote_id;
            navigate(`/quote/${quote_id}`);
          },
          (error) => { // error
            handleSubmitError();
          },
        );
      } catch (e) {
        handleSubmitError();
      }
    }
  };

  return(
    <>
      <div className="wizard">
        <Progress
            completion={complete ? 1 : progress[stepName]}
          />
          {
            !error
            ?
            <TransitionGroup
            component='div'
            className={`slides direction-${direction}`}
            >
              {
                renderSteps(creatorValid, quoteError, stepName, quoteInput, setCreatorValid, setStepName, setDirection, setQuoteInput, submitQuoteInfo)
              }
            </TransitionGroup>
            :
            <div className="wizard-error" data-testid="quote-page-error">
              <Typography
                className="error-message"
                variant="h4"
              >
                There was an error. Please refresh the page or try again later.
              </Typography>
            </div>
          }
      </div>
    </>
  );
};
