import { useEffect, useState } from 'react';

import posthog from 'posthog-js';

import { PostHogPubSubArgObj, postHogPubSub } from 'components/PostHog/PostHog';


export type FlagName =
  'sb_checkout_cc' |
  'sb_checkout_validation' |
  'sb_contact_us' |
  'sb_flags_loaded' |
  'sb_hide_renewals' |
  'sb_landing_page' |
  'sb_wizard_type_teacher' |
  'sb_wizard_type_other';

export const useFlag = (flagName: FlagName): boolean | null => {
  const [isActive, setIsActive] = useState<boolean | null>(null);

  function handleFlags(argumentObject: PostHogPubSubArgObj) {
    setIsActive(posthog.isFeatureEnabled(flagName, { send_event: false }));
  }

  useEffect(() => {
    const removeListener = postHogPubSub.addListener(handleFlags);
    return () => {
      removeListener();
    };
  });

  useEffect(() => {
    let active: boolean | null = null;
    try {
      // - this throws an error if posthog isn't yet initialized
      // - send_event is false, so we don't log an event every time the hook runs
      //   (see https://posthog.com/docs/integrate/client/js#feature-flags )
      active = posthog.isFeatureEnabled(flagName, { send_event: false });
    } catch (e) {
      active = null;
    }
    setIsActive(active);
  }, [flagName]);

  return isActive;
};
